.Offcanvastalktous {
    z-index: 999999 !important;
    width: 48% !important;
}

.Offcanvastalktous .contact-form-title {
    font-size: 35px;
    font-weight: 500;
    color: #2E2E2E;
    text-transform: capitalize;
    margin-bottom: 3%;
    margin-top: 3%;
}

.Offcanvastalktous .form-group {
    margin-bottom: 20px;
}

.Offcanvastalktous .form-control {
    border-right: 0;
    border-left: 0;
    border-top: 0;
    border-radius: 0;
    font-size: 13px;
}

.Offcanvastalktous .form-check-label {
    font-weight: normal;
    margin-left: 8px;
    font-size: 14px;
}

.Offcanvastalktous .react-tel-input .form-control {
    position: relative;
    font-size: 12px;
    letter-spacing: .01rem;
    border-right: 1px solid #ccc;
    border-left: 0;
    border-top: 0;
    border-radius: 0px;
    line-height: 25px;
    height: 32px;
    padding-left: 39px;
}

.Offcanvastalktous .form-check {
    margin-bottom: 10px;
}

.Offcanvastalktous .optional-textarea {
    height: 37px;
    font-size: 13px;
}

.Offcanvastalktous .consent-checkbox {
    margin-top: 20px;
    margin-bottom: 30px;
}

.Offcanvastalktous .submit-button {
    display: inline-block;
    margin-right: 15px;
    background-color: #000;
    color: #fff;
    width: 150px;
    border: 0;
    height: 34px;
    font-size: 14px;
}



.Offcanvastalktous .request-callback {
    vertical-align: middle;
    color: #2E2E2E !important;
    font-size: 14px;
    font-weight: 600;
}

.Offcanvastalktous .request-callback:hover {
    color: #234C9E !important;

}

.Offcanvastalktous .radio-options {
    margin-bottom: 20px;
}

.Offcanvastalktous .radio-options .form-label {
    font-size: 14px;
    font-weight: 600;
    margin-top: 4%;
}

.Offcanvastalktous .country-code {
    max-width: 93px;
    padding-right: 0;
}

.Offcanvastalktous .crossicon {
    cursor: pointer;
    font-size: 20px;
    margin-top: 10px;
}

.Offcanvastalktous .contact-number {
    padding-left: 0;
}

.Offcanvastalktous .submit-section {
    text-align: center;
    display: flex;
    justify-content: space-between;
}


@media screen and (min-width: 280px) and (max-width: 768px) {
    .Offcanvastalktous {
        width: 100% !important;
        z-index: 999999 !important;
    }



    .Offcanvastalktous .optional-textarea {
        height: auto;
        font-size: 13px;
    }

    .Offcanvastalktous .react-tel-input .form-control {
        height: 34px;
    }

    .Offcanvastalktous .contact-form-title {
        font: normal normal 300 25px/38px Gotham;
        margin-bottom: 9%;
    }
}