.ReviewModalvideo .videoModal {
    width: 100%;
    aspect-ratio: 1/1;
}

.ReviewModalvideo .modal-body {
    padding: 0;
}

.ReviewModalvideo .modal-content {
    background-color: transparent;
    border: 0;
}