.footer {
    background: #EFEFEF 0% 0% no-repeat padding-box;
    padding: 40px 0px;
    padding-bottom: 1px;
}

.footer .Heading-holder h2 {
    margin-bottom: 30px;
}

.footer .Heading-holder p {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin-bottom: 30px;
}

.footer .Heading-holder p span{
    text-transform: lowercase;
}

.removefooterline {
    text-decoration: none;
}

.addfooterline {
    text-decoration: underline;
}

.footer .social-linksss .social-icon {
    background-color: #FFF;
    border-radius: 25px;
    /* padding: 6px; */
    margin-right: 10px;
    width: 53px;
    height: 53px;
}

.footer .social-linksss .social-icon .fa-logo {
    margin-top: 14px;
    color: #000;
    font-size: 23px;
}

.footer .social-linksss .social-icon .x-logo {
    margin-top: 14px;
    color: #000;
    width: 26px;
    height: 27px;
}

.footer .news-letter-box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 10px;
}

.footer .form-control {
    border-radius: 0%;
    padding: 0px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #333333;
    font-size: 15px;
    color: #000;
    background-color: transparent;
    padding-left: 10px;
}

.footer .form-control::placeholder {
    font-size: 15px;
    color: #878787;
}

.footer .form-control:focus {
    box-shadow: none;
}

.footer .input-group-text {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #333333;
    background-color: transparent;
    padding-right: 0px;
    border-radius: 0%;
}

.footer .subscribe-btn {
    background-color: transparent;
    color: #000;
    border: 0;
    font-size: 16px;
    font-weight: 600;
}

.footer .copyright-holder .para1 {
    color: #000;
    font-size: 13px;
    margin: 25px 0px 0px 25px;
}

.footer .copyright-holder .para2 {
    color: #000;
    font-size: 13px;
    margin: 3px 0px 23px 25px;
}

.footer .copyright-holder span {
    color: #FF7700;
    font-size: 13px;
}

@media(min-width:0px) and (max-width:767px) {
    .footer .Heading-holder p {
        font-size: 11px !important;
    }
}

@media(min-width:0px) and (max-width:992px) {
    .footer .social-linksss .social-icon {
        margin-right: 10px;
        width: 45px;
        height: 45px;
    }

    .footer .social-linksss .social-icon .fa-logo {
        margin-top: 14px;
        color: #000;
        font-size: 19px;
    }

    .footer .social-linksss .social-icon .x-logo {
        margin-top: 14px;
        color: #000;
        width: 19px;
        height: 18px;
    }

    .footer .Heading-holder p {
        font-size: 13px;
    }

    .footer .Heading-holder h2 {
        margin-bottom: 21px;
    }
}