.blogDetails {
    margin: 3%;
}

.blogDetails .BlogdetailsMain {
    background: #E3EDFF 0% 0% no-repeat padding-box;
    margin-bottom: 4%;
}

.blogDetails .blogddd p{
    font-family: 'Gothamregular' !important;
    color: #000 !important;
    font-size: 14px !important;
}


.blogDetails .blogddd ul li,.blogDetails .blogddd ol li{
    font-family: 'Gothamregular' !important;
    color: #000 !important;
    font-size: 14px !important;
}

.blogDetails .blogddd ol li strong{
    font-family: 'GothamBold';
}

.blogDetails .blogddd p strong,.blogDetails .blogddd p span{
    font-family: 'GothamBold';
    font-size: 16px !important;
}

.blogDetails .BlogdetailsMain .leftSidetxt {
    padding: 15px;
}

.blogDetails .BlogdetailsMain .leftSidehead {
    margin-top: 17%;
    margin-left: 8%;
}

.blogDetails .BlogdetailsMain .leftSidehead p {
    font-size: 13px;
}

.blogDetails .BlogdetailsMain .leftSidehead p p span{
    font-size: 15px !important;
}

.blogDetails .BlogdetailsMain .leftSidetxt p:nth-of-type(2) {
    font-size: 13px;
    margin-bottom: 0;
}

.blogDetails .BlogdetailsMain .leftSidehead h3 {
    font-size: 25px;
    margin-bottom: 12%;
    font-weight: bold;
    font-family: 'GothamBold' !important;
}

.blogDetails .BlogdetailsMain .detailsImgg {
    width: 100%;
    height: 100%;
    aspect-ratio: 4/2;
}


.blogDetails .blogddd .headconinfo h3 {
    font-size: 15px;
    font-weight: 600;
}

.blogDetails  .BlogInfomain p {
    font-size: 14px;
}

.blogDetails .blogddd .headconinfo p {
    font-size: 14px;
}


.blogDetails .articleshead h3 span:nth-of-type(2) {
    font: normal normal 900 45px/50px Gotham;
}

.blogDetails .articleshead h3 span:nth-of-type(1) {
    font-size: 45px;
    letter-spacing: 0px;
    color: #222222;
    text-transform: capitalize;
}

.blogDetails .articleshead h3 {
    margin-bottom: 3%;
}



.blogDetails .articles2 img {
    width: 100%;
    height: 100%;
    margin-bottom: 18px;
    aspect-ratio: 4/2;
}

.blogDetails .articlesImg2 img {
    width: 100%;
    height: 265px;
    margin-bottom: 18px;
}

.blogDetails .articles2 img {
    width: 100%;
    height: 141px;
    margin-bottom: 12px;
}

.blogDetails .articlesImg1,
.blogDetails .articlesImg2,
.blogDetails .articles2 {
    position: relative;
}

.blogDetails .imageinfo3 {
    /* position: absolute; */
    bottom: 15px;
    left: 13px;
    right: 13px;
    margin-top: 0px;
    margin-bottom: 25px;
}

.blogDetails .imageinfo3 h3 {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    -webkit-line-clamp: 2 !important;
    font-family: 'Gothamregular' !important;
}




.blogDetails .imageinfo3 p {
    font-size: 12px;
    line-height: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 5;
    text-align: justify;
}

.blogDetails .overlay-color {
    /* position: absolute;
    background: transparent linear-gradient(0deg, #EFEFEF 0%, #F7F9FF82 44%, #FFFFFF00 100%) 0% 0% no-repeat padding-box; */
    top: 0;
    bottom: 18px;
    left: 0;
    right: 0;
}

.blogDetails .recentUpload .recentuploadHead h3 {
    font-size: 17px;
    text-align: center;
    font-weight: 600;
}

.blogDetails .recentUpload {
    background: #F2F6FF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 15px;
    overflow-y: auto;
    height: 700px;
    margin-bottom: 10%;
}

.blogDetails .blogBanner {
    position: relative;
}

.blogDetails .blogBanner .blogbanimg {
    width: 100%;
    margin-bottom: 3%;
    /* height: 143px; */
    height: 259px;
}

.blogDetails .blogBanner .blogrecentbanimg {
    width: 100%;
    margin-bottom: 7%;
}

.blogDetails .blogBanner .bannertxt {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(0%, -50%);
    color: #fff;
}

.blogDetails .blogBanner .bannertxt p:nth-of-type(1) {
    font: normal normal normal 24px/27px Times New Roman;
    margin-bottom: 0;
}

.blogDetails .blogBanner .bannertxt h3 {
    font: normal normal bold 40px/60px Poppins;
    margin-bottom: 0;
}

.blogDetails .blogBanner .bannertxt p:nth-of-type(2) {
    font: normal normal normal 24px/27px Times New Roman;
    margin-bottom: 0;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .blogDetails .BlogdetailsMain .detailsImgg {
        width: 100%;
        height: 100%;
    }

    .blogDetails .BlogdetailsMain .leftSidehead {
        margin-top: 8%;
        margin-left: 4%;
    }

    .blogDetails .BlogdetailsMain .leftSidehead h3 {
        font-size: 18px;
        margin-bottom: 5%;
    }

    .blogDetails .BlogInfomain p {
        font-size: 13px;
        text-align: justify;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .blogDetails .BlogdetailsMain .detailsImgg {
        width: 100%;
        height: 100%;
    }

    .blogDetails .BlogdetailsMain .leftSidehead {
        margin-top: 8%;
        margin-left: 4%;
    }

    .blogDetails .BlogdetailsMain .leftSidehead h3 {
        font-size: 18px;
        margin-bottom: 5%;
    }
}