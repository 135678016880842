.RewardDetails {
    margin: 3%;
}

.RewardDetails .rewardMaintabs .nav-pills .nav-link {
    font-size: 14px;
}

.RewardDetails .rewardMaintabs .nav-pills .nav-link.active {
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    color: #fff !important;
}

.RewardDetails .rewardMaintabs .rewardNavvv {
    border-bottom: 1px solid #ccc;
    justify-content: center;
    margin-bottom: 25px;
}

.RewardDetails .savebtn button {
    background-color: #234C9E;
    border: 0;
    font-size: 14px;
    padding: 6px 21px;
}

.RewardDetails .validtxt {
    color: #007808;
}

.RewardDetails .Invalidtxt {
    color: #ED3237;
}

.RewardDetails .rewardMaintabs .tabhead {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 12px;
    margin-top: 30px;
}

.RewardDetails .rewardMaintabs .spendPoints p {
    font-size: 14px;
    margin-bottom: 3px;
}

.RewardDetails .rewardMaintabs .spendPoints h3 {
    font-size: 14px;
    font-weight: bold;
}

.RewardDetails .rewardMaintabs .spendList {
    font-size: 13px;
}

.RewardDetails .rewardMaintabs .spendList li::marker {
    color: red;
}

.RewardDetails .rewardMaintabs .RewardInfo h3 {
    font-size: 16px;
    font-weight: bold;
}

.RewardDetails .rewardMaintabs .RewardInfo p {
    font-size: 14px;
}

.RewardDetails .rewardMaintabs .circleStar {
    height: 19px;
    object-fit: contain;
    margin-right: 5px;
}

.RewardDetails .rewardMaintabs .iconImg {
    object-fit: contain;
    margin-right: 5px;
    height: 14px;
}

.RewardDetails .rewardMaintabs .Tab-third .headddd {
    border-bottom: 1px solid #ccc;
    margin-bottom: 25px;
}

.RewardDetails .rewardMaintabs .Tab-third .pointsubscribe h3 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
}

.RewardDetails .rewardMaintabs tr th {
    font-size: 14px;
    text-wrap: nowrap;
}

.RewardDetails .rewardMaintabs tr td {
    font-size: 14px;
}

.RewardDetails .bankInput .form-control{
    border: 1px solid #707070;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .RewardDetails .rewardMaintabs .rewardNavvv {
        overflow: auto;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }

    .RewardDetails .rewardMaintabs .nav-pills .nav-link {
        text-wrap: nowrap;
    }

    .RewardDetails .rewardMaintabs .tabhead {
        margin-bottom: 16px;
    }

    .RewardDetails .rewardMaintabs .tabhead {
        margin-top: 10px;
    }
}