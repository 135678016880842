 .Filteroffcanvas .sidebar {
     width: 100%;
     padding: 15px;
     border-right: 1px solid #ddd;
     background: #F2F2F2 0% 0% no-repeat padding-box;
 }

 .Filteroffcanvas .quantityform {
     position: relative;
 }

 .Filteroffcanvas .quantityform .onfieldtxt {
     position: absolute;
     top: 5px;
     margin-bottom: 0;
     right: 5px;
     font-size: 13px;
 }

 .Filteroffcanvas .price-range {
     margin: 20px 0px 40px 0px;
 }

 .Filteroffcanvas .price-range label {
     display: block;
     margin-bottom: 10px;
 }

 .Filteroffcanvas .form-control {
     border-left: 0;
     border-top: 0;
     border-right: 0;
     border-radius: 0;
     background-color: transparent;
     margin-bottom: 15px;
     font-size: 14px;
 }

 .Filteroffcanvas .price-range .d-flex {
     font-size: 12px;
 }

 .Filteroffcanvas label {
     font-size: 14px;
 }

 .Filteroffcanvas .categoryItem {
     display: flex;
 }

 .Filteroffcanvas .lessthanicon {
     margin-left: auto;
 }

 .Filteroffcanvas .categoryItemssec .categoryItem p {
     margin-bottom: 5px;
     font-size: 14px;
 }

 .Filteroffcanvas .productsBycatHead {
     font-size: 15px;
     font-weight: 600;
     margin: 16px 0px;
 }

 .Filteroffcanvas .accordion-button {
     font-size: 14px;
     padding: 10px 15px;
 }

 .Filteroffcanvas .sortingsec p {
     font-size: 12px;
     margin-bottom: 0;
     cursor: pointer;
 }



 .Filteroffcanvas .sortingsec .barsImage {
     width: 14px;
     height: 9px;
     object-fit: contain;
 }

 .Filteroffcanvas .dropdown-toggle::after {
     display: none;
 }

 .Filteroffcanvas .form-check-input {
     border: 1px solid #000;
     border-radius: 0 !IMPORTANT;
 }

 .Filteroffcanvas .accordion-button:not(.collapsed) {
     color: #000;
     background-color: transparent;
 }

 .Filteroffcanvas .tabsbsb {
     margin-top: 15px;
 }

 .Filteroffcanvas .tabsbsb .nav-pills .nav-link {
     background: #FFFFFF 0% 0% no-repeat padding-box;
     border: 1px solid #707070;
     border-radius: 22px;
     padding: 3px 7px;
     font-size: 13px;
     margin-right: 7px;
     position: relative;
     width: 69px;
     margin-bottom: 5px;
 }

 .Filteroffcanvas .tabsbsb .nav-pills .nav-link span {
     position: absolute;
     right: 5px;
     top: 4px;
 }

 .Filteroffcanvas .tabsbsb .x-mark {
     background: #fff;
     padding: 3px;
     border-radius: 50%;
 }

 .Filteroffcanvas .tabsbsb .nav-pills .nav-link.active {
     background: #DCE8FF 0% 0% no-repeat padding-box;
     border: 1px solid #234C9E;
     border-radius: 22px;
 }

 .Filteroffcanvas .accordBodycontent p {
     margin-bottom: 7px;
     font-weight: 500;
     font-size: 13px;
 }

 .Filteroffcanvas .accordBodycontent p.active {
   font-weight: 700;
}

 .Filteroffcanvas .dropdown-menu {
     background-color: #FFF;
     border-radius: 0%;
     padding-top: 0%;
     padding-bottom: 0%;
     border: none;
     box-shadow: 0px 3px 6px #00000029;
     position: absolute !important;
     inset: 0px auto auto 0px !important;
     transform: translate3d(0px, 20px, 0px) !important;
     right: 0 !important;
     width: fit-content !important;
 }

 .Filteroffcanvas .dropdown-item:hover {
     background: #2E2E2E 0% 0% no-repeat padding-box;
     color: #fff !important;
     font-size: 15px;
     padding: 10px;
 }

 .Filteroffcanvas .accordion-item {
     border: 0;
 }

 .Filteroffcanvas .dropdown-item:active {
     background: #2E2E2E 0% 0% no-repeat padding-box;
     color: #fff;
     font-size: 15px;
     padding: 10px;
 }

 .Filteroffcanvas .sortingsec {
     width: fit-content;
     float: right;
 }

 .Filteroffcanvas .dropdown-item {
     color: #000;
     font-size: 15px;
     padding: 10px;
 }

 .Filteroffcanvas .MuiStack-root {
     justify-content: center;
     display: flex;
     align-items: center;
     margin-top: 3%;
 }

 .Filteroffcanvas .filtericonmain {
     display: none;
 }