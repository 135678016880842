.Offers {
    margin: 3%;
}

.Offers .offerImg {
    width: 100%;
    height: 100%;
    aspect-ratio: 2/1;
    object-fit: contain;
    border-radius: 20px;
}

.Offers .swiper-slide {
    margin-bottom: 3%;
}

.Offers .swiperoffer{
    margin-top: 3%;
}

@media screen and (min-width: 0px) and (max-width: 320px) {
    .Offers .swiper-slide {
        margin-bottom: 15%;
    }
}

@media screen and (min-width: 321px) and (max-width: 485px) {
    .Offers .swiper-slide {
        margin-bottom: 9%;
    }
}

@media screen and (min-width: 486px) and (max-width: 768px) {
    .Offers .swiper-slide {
        margin-bottom: 9%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .Offers .swiper-slide {
        margin-bottom: 9%;
    }
}