.Blogs {
  margin: 3%;
}

.Blogs .articleshead h3 span:nth-of-type(2) {
  font: normal normal 900 45px/50px Gotham;
}

.Blogs .articleshead h3 span:nth-of-type(1) {
  font-size: 45px;
  letter-spacing: 0px;
  color: #222222;
  text-transform: capitalize;
}

.Blogs .articleshead h3 {
  margin-bottom: 3%;
}

.Blogs .articlesImg1 {
  margin-bottom: 18px;
}

.Blogs .articlesImg1 img {
  width: 100%;
  height: 100%;
  aspect-ratio: 4/2;
}

.Blogs .articlesImg2 {
  margin-bottom: 18px;
}
.Blogs .articlesImg2 img {
  width: 100%;
  height: 265px;
}

.Blogs .articles2 {
  margin-bottom: 18px;
}

.Blogs .articles2 img {
  width: 100%;
  height: 100%;
  aspect-ratio: 4/2;
}

.Blogs .articlesImg1,
.Blogs .articlesImg2,
.Blogs .articles2 {
  position: relative;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.Blogs .articlesImg1:hover img  {
  scale: 1.1;
  transition: all 0.3s ease-in-out;
}

.Blogs .imageinfo1 h3 {
  font: normal normal bold 18px/20px Gotham;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.Blogs .imageinfo1 p {
  font-size: 12px !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  -webkit-line-clamp: 2 !important;
  line-height: 1.4 !important; /* Adjust this based on your design */
  max-height: calc(1.4em * 2) !important;  /* Limits to two lines */
  margin-bottom: 7px !important;
  word-wrap: break-word !important; /* Ensures long words break */
}

.Blogs .imageinfo1 p  span{
  font-size: 12px !important;
  font-family: 'Gothamregular' !important;
}

.Blogs .imageinfo1 {
  /* position: absolute; */
  bottom: 12px;
  left: 31px;
  padding-right: 15px;
  width: 98%;
  margin-top: 15px;
}

.Blogs .imageinfo3 {
  /* position: absolute; */
  bottom: 15px;
  left: 31px;
  margin-top: 12px;
}

.Blogs .imageinfo2 {
  position: absolute;
  bottom: 22px;
  left: 31px;
}

.Blogs .imageinfo2 h3 {
  font-size: 21px;
  font-family: 'GothamBold';
}

.Blogs .imageinfo3 h3 {
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-bottom: 5px !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  -webkit-line-clamp: 1 !important;
  font-family: 'Gothamregular' !important;
}

.Blogs .imageinfo2 p {
  font: normal normal 16px/18px;
}

.Blogs .imageinfo3 p {
  font-size: 12px !important;
  line-height: 15px !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  -webkit-line-clamp: 2 !important;
  font-family: 'Gothamregular' !important;
  margin-bottom: 8px;
}

.Blogs .imageinfo3 p span{
  font-size: 12px !important;
  font-family: 'Gothamregular' !important;
}

.Blogs .overlay-color {
  /* position: absolute; */
  /* background: transparent
    linear-gradient(0deg, #efefef 0%, #f7f9ff82 44%, #ffffff00 100%) 0% 0%
    no-repeat padding-box; */
  top: 0;
  bottom: 0px;
  left: 0;
  right: 0;
}

.Blogs .recentUpload .recentuploadHead h3 {
  font-size: 17px;
  text-align: center;
  font-weight: 600;
}

.Blogs .recentUpload {
  background: #f2f6ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 15px;
  overflow-y: auto;
  height: 700px;
  margin-bottom: 10%;
}

.Blogs .blogBanner_holder {
  height: 500px;
  overflow: hidden;
  overflow-y: scroll;
}

.Blogs .blogBanner {
  position: relative;
}

.Blogs .blogBanner .blogbanimg {
  width: 100%;
  margin-bottom: 3%;
  /* height: 143px; */
  height: 100%;
  aspect-ratio: 4/1.2;
}

.Blogs .blogBanner .blogrecentbanimg {
  width: 100%;
  height: 100%;
  margin-bottom: 7%;
  aspect-ratio: 4/2;
}

.Blogs .blogBanner .bannertxt {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translate(0%, -50%);
  color: #fff;
}

.Blogs .blogBanner .bannertxt p:nth-of-type(1) {
  font: normal normal normal 24px/27px Times New Roman;
  margin-bottom: 0;
}

.Blogs .blogBanner .bannertxt h3 {
  font: normal normal bold 40px/60px Poppins;
  margin-bottom: 0;
}

.Blogs .blogBanner .bannertxt p:nth-of-type(2) {
  font: normal normal normal 24px/27px Times New Roman;
  margin-bottom: 0;
}



.Blogs .pagination {
  justify-content: center;
}

.Blogs .pagination .disabled>.page-link, .Blogs .pagination  .page-link.disabled {
  color: #ccc;
  pointer-events: none;
  background: none;
  border-color: var(--bs-pagination-disabled-border-color);
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}


.Blogs .pagination .custom-pagination-prev  .page-link:hover {
  background: #AB755C;
  border-radius: 50%;
  padding: 0px 7px;
}

.Blogs .pagination .custom-pagination-prev  .page-link:hover {
  color: #fff !important;
 }
 


.Blogs .pagination .custom-pagination-next  .page-link:hover{
  background: #AB755C;
  border-radius: 50%;
  padding: 0px 7px;
}




.Blogs .pagination .custom-pagination-next  .page-link:hover {
  color: #fff !important;
 }

 .Blogs .pagination .custom-pagination-prev .page-link {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}


 .Blogs .pagination .custom-pagination-next .page-link {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}



.Blogs li.page-item.disabled:hover .page-link{
  background: none;
  border-radius: 50%;
  padding: 0px 7px;
}

.Blogs .page-item:last-child:hover a{
 color: #fff !important;
}


.Blogs li.page-item.disabled:hover .page-link {
  background:none;
  border-radius: 50%;
  padding: 0px 7px;
}

.Blogs .page-item:first-child:hover a{
  color: #fff !important;
}




.Blogs .page-item:last-child:hover .page-link {
  background: #AB755C;
  border-radius: 50%;
  padding: 0px 7px;
}

.Blogs .page-item:last-child:hover a{
 color: #fff !important;
}


.Blogs .page-item:first-child:hover .page-link {
  background: #AB755C;
  border-radius: 50%;
  padding: 0px 7px;
}

.Blogs .page-item:first-child:hover a{
  color: #fff !important;
}

.Blogs .page-item:last-child .page-link {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.Blogs .page-item:first-child .page-link {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.Blogs .active > .page-link,
.Blogs .page-link.active {
  z-index: 3;
  color: #222222;
  background-color: transparent;
  font-weight: 600;
  border-bottom: 1px solid #234c9e !important;
}



.Blogs .page-link {
    border: 0 !important;
    padding: 0px 7px;
    margin: 8px;
  
}

.Blogs .posteddate {
  font-weight: 600;
  text-align: end;
  margin-right: 15px;
  font-size: 10px !important;
}

.Blogs .recentedPostedDate {
  font-size: 10px !important;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .Blogs .articleshead h3 span:nth-of-type(2) {
    font: normal normal 600 23px/30px Gotham;
  }

  .Blogs .articleshead h3 span:nth-of-type(1) {
    font-size: 23px;
  }

  .Blogs .articleshead p {
    font-size: 15px;
  }

  .Blogs .imageinfo1 {
    bottom: 5px;
    left: 18px;
  }

  .Blogs .imageinfo2 {
    bottom: 5px;
    left: 18px;
  }

  .Blogs .imageinfo1 h3 {
    font: normal normal bold 15px/29px Gotham;
    margin-bottom: 0px;
  }

  .Blogs .imageinfo1 p {
    font-size: 11px;
    margin-bottom: 0px;
  }



  .Blogs .imageinfo2 p {
    font-size: 11px;
    margin-bottom: 0px;
  }

  .Blogs .imageinfo1 .readmoresecmain p {
    font-size: 10px;
    margin-bottom: 0px;
}

  .Blogs .blogBanner .bannertxt p:nth-of-type(1) {
    font: normal normal normal 18px/8px Times New Roman;
  }

  .Blogs .blogBanner .bannertxt h3 {
    font: normal normal bold 26px/48px Poppins;
  }

  .Blogs .blogBanner .bannertxt p:nth-of-type(2) {
    font: normal normal normal 18px/13px Times New Roman;
  }
}
