/* OrderDetails.css */

.viewtrackmodalmain .order-details-container {
  border-radius: 8px;
  background-color: #fff;
}

.viewtrackmodalmain .order-details-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.viewtrackmodalmain .product-image {
  width: 100px;
  height: auto;
  margin-top: 10px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
}

.viewtrackmodalmain .order-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.viewtrackmodalmain .status-step {
  text-align: start;
  position: relative;
  flex: 1;
}

.viewtrackmodalmain .blueee-borderrr {
  border-bottom: 6px solid #234c9e;
  width: 100%;
  margin-bottom: auto;
  margin-top: auto;
}

.viewtrackmodalmain .status-step:not(:last-child)::after {
  content: "";
  height: 2px;
  background-color: #234c9e;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.viewtrackmodalmain .status-icon {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #234c9e;
}

.viewtrackmodalmain .status-step.completed .status-icon {
  background-color: #234c9e;
}

.viewtrackmodalmain .status-step p:nth-of-type(1) {
  margin: 5px 0;
  font-size: 13px;
  font-weight: 600;
}

.viewtrackmodalmain .paymentsec {
  border-left: 1px solid #000;
  padding-left: 45px;
}

.viewtrackmodalmain .status-step p:nth-of-type(2) {
  margin: 5px 0;
  font-size: 13px;
}

.viewtrackmodalmain .return-button-container {
  text-align: start;
}

.viewtrackmodalmain .orderstatushead h5 {
  font-size: 15px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 20px;
}

.viewtrackmodalmain .return-button {
  background-color: #6c757d;
  border: none;
  padding: 5px 18px;
}

.viewtrackmodalmain .purchasedSec h4 {
  font-size: 16px;
  font-weight: 600;
}

.viewtrackmodalmain .purchasedSec p {
  font-size: 14px;
  margin-bottom: 2px;
}

.viewtrackmodalmain .paymentsec p {
  font-size: 14px;
  margin-bottom: 2px;
}

.viewtrackmodalmain .crossIcon {
  font-size: 20px;
  cursor: pointer;
}

.stepper-div .RSPBprogressBar {
  height: 5px;
  width: 60%;
}

.stepper-div .RSPBprogressBar .indexedStep.null {
  width: 15px;
  height: 15px;
}

.stepper-div .indexedStep.accomplished {
  background-color: #234c9e;
  width: 15px;
  height: 15px;
}

.stepper-div .indexedStep {
  position: relative;
  color: #000;
  width: 15px;
  height: 15px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepper-div .range-text-div {
  /* margin: 0px 95px 0px 0px; */
  position: absolute;
  top: 27px;
  left: 0;
  right: 0;
  width: 100px;
  text-align: left;
}

.stepper-div .range-text-div .placed {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  text-align: left;
  color: #000000;
}

.stepper-div .range-text-div .date {
  font-size: 12px;
  margin-bottom: 0px;
  font-weight: 400;
}

.viewtrackmodalmain .orderstatushead {
  margin-bottom: 90px;
  margin-top: 30px;
}

@media (max-width: 319px) {
  .stepper-div .range-text-div .placed {
    font-size: 10px;
  }

  .stepper-div .range-text-div {
    top: 66px;
    left: -44px;
    transform: rotateZ(90deg);
  }

  .stepper-div .RSPBprogressBar {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 484px) {
  .stepper-div .range-text-div .placed {
    font-size: 10px;
  }

  .stepper-div .range-text-div {
    top: 66px;
    left: -44px;
    transform: rotateZ(90deg);
  }

  .stepper-div .RSPBprogressBar {
    width: 90%;
  }
}

@media (min-width: 485px) and (max-width: 574px) {
  .stepper-div .range-text-div .placed {
    font-size: 12px;
  }

  .stepper-div .RSPBprogressBar {
    width: 90%;
  }
}

@media (min-width: 575px) and (max-width: 767px) {
  .stepper-div .RSPBprogressBar {
    width: 90%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .stepper-div .RSPBprogressBar {
    width: 90%;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .stepper-div .range-text-div {
    margin: 0px 50px 0px 0px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .stepper-div .range-text-div {
    margin: 0px 50px 0px 0px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .stepper-div .range-text-div {
    margin: 0px 70px 0px 0px;
  }
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .viewtrackmodalmain .paymentsec {
    border-left: 0;
    padding-left: 0;
  }

  .viewtrackmodalmain .blueee-borderrr {
    border-bottom: 4px solid #234c9e;
  }

  .viewtrackmodalmain .order-status {
    justify-content: start;
    align-items: normal;
  }

  .viewtrackmodalmain .purchasedSec h4 {
    font-size: 15px;
  }

  .viewtrackmodalmain .paymentsec p {
    font-size: 13px;
  }
}
