.Headeraccord .secondMobiledrop p {
    font-size: 13px;
    margin-bottom: 11px;
}

.Headeraccord .secondMobiledrop {
    background-color: #FFF;
    border-radius: 0%;
    width: 100%;
    z-index: 1;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    padding: 11px 0px 2px 11px;
}

.Headeraccord .secondMobiledrop .accordion-body {
    padding: 8px 12px;
}

.Headeraccord .accordion-item {
    border: 0;
}



.Customise-link-accordion-button .accordion-button::after {
   display: none;
}

.Headeraccord .accordion-button {
    font-size: 15px;
}
.mobloggoo{
    height: 40px;
    width: auto;
}
.mobheadoffcanvas .accordion-button {
    padding: 10px 10px;
}
.mobheadoffcanvas .accordion-body {
    padding: 7px 2px;
}
.mobheadoffcanvas .accordion-body .subaccmenu{
    margin-bottom: 10px;
}
.mobheadoffcanvas .accordion-body .subaccmenu a{
    font-size: 13px;
}
.mobheadoffcanvas .accordion-button:not(.collapsed) {
    color: #000;
    background-color: #fcf7ea;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}