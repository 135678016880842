.Privacypolicy {
    margin: 3%;
}

.Privacypolicy .PrivacyHead h3 {
    margin-bottom: 15px;
    font-family: "Gothammedium";
    font-weight: 300;
    color: #1e1e1e;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: 71px;
    letter-spacing: -1.32px;
    padding-bottom: 25px;
}
.Privacypolicy .PrivacyHead p {
    font-size: 16px;
    text-align: justify;
    font-family: 'Gothamregular' ;
    color: #2E2E2E;
}

.Privacypolicy .Privacyquestion h3 {
    font-size: 20px;
    font-family: 'Gothammedium';

}

.Privacypolicy .Privacyquestion p {
    font-size: 14px;
    text-align: justify;
    font-family: 'Gothamregular' ;
    color: #2E2E2E;
}

.Privacypolicy .Privacyquestion {
    margin-bottom: 5%;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .Privacypolicy .PrivacyHead h3 {
        font-size: 30px;
    }

    .Privacypolicy .Privacyquestion h3 {
        font-size: 16px;
    }
    .Privacypolicy .Privacyquestion p {
        font-size: 12px;
    }
    .Privacypolicy .PrivacyHead p {
        font-size: 12px;
    }
    
}

@media screen and (min-width: 769px) and (max-width:1200px) {
    .Privacypolicy .PrivacyHead h3 {
        font-size: 40px;
    }

    .Privacypolicy .Privacyquestion h3 {
        font-size: 18px;
    }
    .Privacypolicy .Privacyquestion p {
        font-size: 13px;
    }
    .Privacypolicy .PrivacyHead p {
        font-size: 12px;
    }
    
}