.Wishlist h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-wrap: nowrap;
    margin-right: 10px;
}

.Wishlist .order-status {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.Wishlist .wishlistHead {
    font-size: 40px;
    margin-top: 3%;
}

.Wishlist .wishlistMainrow {
    margin-bottom: 5%;
}

.Wishlist .ButtonMain li.breadcrumb-item {
    font-size: 12px !important;
    margin-top: 22px;
}

.Wishlist .order-status span {
    font-size: 0.9rem;
    color: #6c757d;
    margin-right: 18px;
    text-wrap: nowrap;
    margin-left: 10px;
}


.Wishlist .order-card .Orderdate {
    color: #6c757d;
    font-size: 13px;
}


.Wishlist .orderedstatusMain {
    justify-content: flex-end;
    display: flex;
}

.Wishlist .order-card .orderedstatusMain .orderedstatus {
    background-color: #007bff;
    color: #fff;
    font-size: 0.8rem;
    width: 65px;
    text-align: center;
}

.Wishlist .Wishlisttitle {
    display: flex;
    justify-content: space-between;
}

.Wishlist .divider {
    border: 0;
    height: 1px;
    margin: 12px 0;
    border-bottom: 1px solid #000;
    width: 100%;
}


@media screen and (min-width: 0px) and (max-width: 768px) {
    .Wishlist .order-status {
        overflow-x: auto;
        padding-bottom: 10px;

    }

    .Wishlist .Wishlisttitle {
        display: block;
    }
}