.Addnewaddress .form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    font-size: 14px !important;
}

.Addnewaddress .modal-body {
    padding: 5%;
}

.Addnewaddress .form-label {
    font-size: 14px;
}

.Addnewaddress .crossicon {
    font-size: 20px;
}

.Addnewaddress .formflex {
    display: flex;
}

.Addnewaddress .btnsec {
    text-align: center;
}

.Addnewaddress .btnsec button {
    background: #234C9E 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: #fff;
    border: 0;
    font-size: 14px;
}

.Addnewaddress .checksRadios .form-check {
    font-size: 14px;
}

.Addnewaddress .modal-content {
    border-radius: 20px;
}

.Addnewaddress .manualSelect__control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    font-size: 14px !important;
}

.nameLabel {
    margin-right: 10px;
}

@media screen and (min-width: 0px) and (max-width: 485px) {
    .Addnewaddress .formflex {
        display: block;
    }

    .Addnewaddress .btnsec button {
        font-size: 12px !important;
        margin-top: 18px;
    }
}