.Responsibility {
    margin: 3%;
}

.Responsibility .responsibiltyTxt p {
    font-size: 16px;
    font-family: 'Gothamregular' ;
    text-align: justify;
    color: #2E2E2E;
}

.Responsibility .RepsonsibleHead h3 {
    margin-bottom: 15px;
    font-family: 'Gothammedium';
    font-weight: 300;
    color: #1e1e1e;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: 71px;
    letter-spacing: -1.32px;
    padding-bottom: 25px;
}

@media screen and (min-width: 0px) and (max-width: 768px) {

    .Responsibility .RepsonsibleHead h3 {
        font-size: 30px;
        margin-bottom: 15px;
        margin-top: 27px;
    }
    .Responsibility .responsibiltyTxt p {
        font-size: 12px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .Responsibility .RepsonsibleHead h3 {
        font-size: 40px;
        margin-bottom: 15px;
    }
    .Responsibility .responsibiltyTxt p {
        font-size: 13px;
    }
}