.cookies-offcanvas {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  border-radius: 0;
  animation: slide-up 0.3s ease-out;
  backdrop-filter: none;
}

.cookies-offcanvas .LogoutMain .yesNobtnMain {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0%;
  margin-left: 40px;
  display: flex;
}

.cookies-offcanvas .LogoutMain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cookies-offcanvas .offcanvas-body {
  background-color: #1d44ad;
  border-radius: 0px;
}

.cookies-offcanvas .LogoutMain .logoutinfo p {
  font-weight: 500;
  margin-top: 0%;
  margin-bottom: 0;
  margin-left: 8px;
  color: #fff;
  font-size: 15px;
}

.cookies-offcanvas .LogoutMain .logoutinfo span {
  font-weight: 600;
  margin-top: 0%;
  margin-bottom: 0;
  margin-left: 8px;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}

.cookies-offcanvas .LogoutMain .yesNobtnMain .yesNobtn {
  background-color: #fff;
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  margin-right: 15px;
  padding: 3px 20px;
  border: 0;
  width: max-content;
}

.cookies-offcanvas .offcanvas {
  max-width: 100%;
  width: 100%;
}

.cookies-offcanvas.offcanvas-bottom {
  height: fit-content !important ;
}

.cookies-offcanvas .offcanvas-content {
  border-radius: 0;
}


.offcanvas-backdrop{
  z-index: 1!important;
}

/* Target the specific offcanvas using your custom class */
.offcanvas-backdrop.show {
  opacity: 0 !important; 
}

.offcanvas-backdrop.fade {
  opacity: 0 !important; 
}

.offcanvas-backdrop {
  background-color: none; 
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}


@media (min-width: 0px) and (max-width: 992px) {
  .cookies-offcanvas .LogoutMain .yesNobtnMain {
    text-align: center;
    justify-content: center;
    margin-top: 20px;
}

.cookies-offcanvas .LogoutMain {
  display: block;
}

}