.order-summary .main-blue-bggg {
    background: #ECF5FF;
    border-radius: 5px;
    padding: 15px;
    margin-top: 27px;
}

.order-summary .main-blue-bggg .brown-border {
    border-bottom: 1px solid #ccc;
}

.order-summary .main-blue-bggg .txttt-order-summary {
    font-size: 14px;
    font-weight: 600;
    padding-top: 5px;
}


.order-summary .main-blue-bggg .price-order-summary {
    font-size: 14px;
    padding-top: 5px;
}

.order-summary .main-blue-bggg .at-checkout-label {
    font-size: 14px;
    padding-top: 5px;
    font-weight: 600;
}


.order-summary .proceed-btnnn {
    background: #163B85;
    color: #FFF;
    font-size: 14px;
    text-transform: uppercase;
    border: none;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
}

.order-summary .price-totaltxt {
    font-size: 18px;
    font-weight: 600;
    padding-top: 5px;
}

.order-summary .continue-shopping {
    font-size: 15px;
    text-decoration: underline;
    color: #323232;
    text-align: center;
}

.order-summary .blk-borderr {
    border-bottom: 2px solid #ccc;
    width: 100%;
    margin: auto;
}



.order-summary .title-order-sumarryy h4 {
    font-size: 23px;
    font-weight: 700;
    color: #323232;
    margin-bottom: 0px;
    text-wrap: nowrap;
    margin-right: 7px;
}

.order-summary .pad-icon {
    font-size: 30px;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .order-summary .title-order-sumarryy h4 {
        font-size: 20px;
    }

    .order-summary .pad-icon {
        font-size: 20px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .order-summary .title-order-sumarryy h4 {
        font-size: 20px;
    }

    .order-summary .pad-icon {
        font-size: 20px;
    }
}