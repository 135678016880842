.RelevantNews {
    margin: 3%;
}

.RelevantNews .articleshead h3 span:nth-of-type(1) {
    font-size: 35px;
    font-weight: 500;
    font-family: 'GothamBold';
}

.RelevantNews .articleshead h3 span:nth-of-type(2) {
    font-size: 28px;
    letter-spacing: 0px;
    color: #222222;
    text-transform: capitalize;
    margin-left: 10px;
    font-family: 'GothamBold';
}

.RelevantNews .imageinfo1 p:nth-of-type(1) {
    font-size: 13px;
}

.RelevantNews .imageinfo1 .minute-text-holder{
display: flex;
justify-content: space-between;
align-items: center;
}

.RelevantNews .nevermiss {
    font-family: 'Gothamregular' !important;
}

.RelevantNews .articleshead h3 {
    margin-bottom: 3%;
}

.RelevantNews .articlesImg1 img {
    width: 100%;
    height: 100%;
    /* margin-bottom: 18px; */
    aspect-ratio: 4/2.5;
}

.RelevantNews .articlesImg2 img {
    width: 100%;
    height: 100%;
    margin-bottom: 6px;
    aspect-ratio: 4/2.5;
}

.RelevantNews .articlesImg1,
.RelevantNews .articlesImg2 {
    position: relative;
}

.RelevantNews .imageinfo1 h3 {
    font-size: 33px;
    font-weight: bold;
    font-family: 'GothamBold';
}

.RelevantNews .imageinfo1 {
    position: absolute;
    bottom: 22px;
    left: 31px;
    padding-right: 29px;
}





.RelevantNews .imageinfo2 h3 {
    font-size: 21px;
    font-weight: bold;
    font-family: 'GothamBold';
}

.RelevantNews .imageinfo2 p:nth-of-type(1) {
    font-size: 17px;
    margin-bottom: 3px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    font-family: 'Gothamregular';
}

.RelevantNews .imageinfo2 p:nth-of-type(2) {
    font-size: 12px;
    font-family: 'Gothamregular';
}

.RelevantNews .imageinfo1 p:nth-of-type(2) {
    font-size: 12px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-height: 1.4; /* Adjust this based on your design */
    max-height: calc(1.4em * 2); /* Limits to two lines */
    margin-bottom: 7px;
    word-wrap: break-word; /* Ensures long words break */
}

.RelevantNews .imageinfo2 span {
    font-size: 12px;
    font-family: 'Gothamregular';
}

.RelevantNews .overlay-color {
    position: absolute;
    background: transparent linear-gradient(0deg, #EFEFEF 0%, #F7F9FF82 44%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
    top: 0;
    bottom: 0px;
    left: 0;
    right: 0;
}

@media screen and (min-width: 0px) and (max-width: 485px) {
    .RelevantNews .articleshead h3 span:nth-of-type(2) {
        font-size: 18px;
    }

    .RelevantNews .articleshead h3 span:nth-of-type(1) {
        font-size: 23px;
    }

    .RelevantNews .articleshead p {
        font-size: 15px;
    }

    .RelevantNews .imageinfo1 {
        bottom: 8px;
        left: 12px;
    }

    .RelevantNews .imageinfo2 {
        bottom: 24px;
        left: 18px;
    }

    .RelevantNews .imageinfo1 h3 {
        font-size: 18px;
    }

    .RelevantNews .imageinfo1 p {
        font-size: 13px;
    }

    .RelevantNews .imageinfo2 p {
        font-size: 13px;
    }

    .RelevantNews .imageinfo2 p:nth-of-type(1) {
        font-size: 16px;
    }

    .RelevantNews .imageinfo2 span {
        font-size: 11px;
    }

    .articlmainn {
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 486px) and (max-width: 767px) {
    .RelevantNews .articleshead h3 span:nth-of-type(2) {
        font-size: 23px;
    }

    .RelevantNews .articleshead h3 span:nth-of-type(1) {
        font-size: 23px;
    }

    .RelevantNews .articleshead p {
        font-size: 15px;
    }

    .RelevantNews .imageinfo1 {
        bottom: 24px;
        left: 18px;
    }

    .RelevantNews .imageinfo2 {
        bottom: 24px;
        left: 18px;
    }

    .RelevantNews .imageinfo1 h3 {
        font-size: 22px;
    }

    .RelevantNews .imageinfo1 p {
        font-size: 13px;
    }

    .RelevantNews .imageinfo2 p {
        font-size: 13px;
    }

    .RelevantNews .imageinfo2 p:nth-of-type(1) {
        font-size: 19px;
    }

    .articlmainn {
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {


    .articlmainn {
        margin-bottom: 20px;
    }
}