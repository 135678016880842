.promotional-product {
  margin: 3% 0;
}

.promotional-product .main-section {
  background: #f9f9f9;
  border-radius: 15px;
  position: relative;
  margin-top: 10px;
}

/* .promotional-product .main-section .image-box {
    height: 284px;
} */

/* .promotional-product .main-section .seller-img {
    width: 100%;
    aspect-ratio: 1 / 0.6;
} */
.promotional-product .main-section .seller-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 15px;
  /* margin-top: 9px; */
}

.promotional-product .main-section .bg-overlay {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 20%;
  background: transparent linear-gradient(180deg, #00000000 0%, #000000c4 100%)
    0% 0% no-repeat padding-box;
  border-radius: 15px;
  transition: 0.5s ease-in-out;
}

.promotional-product .main-section:hover .bg-overlay {
  background: transparent linear-gradient(180deg, #00000000 0%, #000000c4 100%)
    0% 0% no-repeat padding-box;
  height: 50%;
}

.promotional-product .main-section .bg-overlay-one {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0%;
  background: transparent linear-gradient(180deg, #00000000 0%, #0000003d 100%)
    0% 0% no-repeat padding-box;
  border-radius: 15px;
  transition: 0.5s ease-in-out;
}

.promotional-product .main-section:hover .bg-overlay-one {
  background: transparent linear-gradient(180deg, #00000000 0%, #0000003d 100%)
    0% 0% no-repeat padding-box;
  height: 50%;
}

.promotional-product .main-section .text-holder {
  position: absolute;
  bottom: 0px;
  left: 24px;
  z-index: 1;
}

.promotional-product .main-section .text-holder .local-text {
  font-size: 30px;
  font-weight: 500;
  color: #fff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.promotional-product .main-section .fa-icon {
  color: #b1b1b1;
  font-size: 20px;
  transition: 0.5s all ease-in;
}

.promotional-product .main-section:hover .fa-icon {
  color: #000;
  margin-left: 12px;
}

.promotional-product .heading-holder {
  margin-top: 3%;
  margin-bottom: 1%;
}

@media (min-width: 486px) and (max-width: 767px) {
  .promotional-product .main-section .image-box {
    height: 193px;
  }

  .promotional-product .main-section .text-holder .local-text {
    font-size: 20px;
  }
}

@media (min-width: 360px) and (max-width: 486px) {
  .promotional-product .main-section {
    margin-top: 30px;
  }

  /* .promotional-product .main-section .image-box {
        height: 186px;
    } */

  .promotional-product .main-section .text-holder .local-text {
    font-size: 20px;
  }
}

@media (min-width: 0px) and (max-width: 360px) {
  .promotional-product .main-section {
    margin-top: 30px;
  }

  /* .promotional-product .main-section .image-box {
        height: 120px;
    } */

  .promotional-product .main-section .text-holder .local-text {
    font-size: 18px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .promotional-product .main-section .text-holder .local-text {
    font-size: 20px;
  }
}
