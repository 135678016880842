.SellerStepform {
    margin: 1% 0% 10% 0%;
}

.SellerStepform h4.stepform-heading {
    font-size: 25px;
    font-weight: 600;
    text-align: left;
}

.SellerStepform .step-container.active .circle {
    color: #ffffff;
    border: 1px solid #234C9E;
    background-color: #234C9E;
}



.SellerStepform .razorpayDetails p {
    font-size: 12px;
}

.SellerStepform .ShippingMethod .form-check {
    font-size: 14px;
    margin-top: 27px;
}

.SellerStepform .ShippingMethod h3 {
    font-size: 17px;
    font-weight: 600;
}

.SellerStepform .progress-bar-container .step-row {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
}

.SellerStepform .progress-bar-container .step-container {
    width: 100%;
    padding-bottom: 9px;
}

.line-right {
    margin: 0px 5px;
    margin-bottom: 13px;
    width: 102%;
    border-bottom: 4px solid black;
}

.progress-bar-container .step-row .step-container .main-flex-div {
    display: flex;
    padding-bottom: 15px;
    margin-right: 12px;
    justify-content: center;
}

.SellerStepform .line-right.completed {
    border-bottom: 1px dashed orange;
    background-image: linear-gradient(to bottom, orange 50%, transparent 50%);
}

.SellerStepform .progress-bar-container .line-left {
    width: 50px;
    height: 5px;
    background: #dfdfdf;
    position: absolute;
    top: 50%;
    right: 101%;
}

.SellerStepform .progress-bar-container .step-text {
    font-size: 14px;
    color: #878787;
    font-weight: 500;
    margin: 0px 0px 0px 15px;
}

.SellerStepform .progress-bar-container .step-container.active .step-text {
    font-weight: 600;
    color: #000;
}

.SellerStepform .signup-form {
    margin: 10px 60px 40px 15px;
}


.SellerStepform .step2 .react-datepicker__input-container .react-datepicker__calendar-icon {
    right: 0px;
}

.SellerStepform .step2 .react-datepicker__view-calendar-icon input {
    padding: 6px 10px 5px 25px;
    width: 100%;
    border: 1px solid #707070;
    border-radius: 5px;
}

.SellerStepform label {
    font-size: 15px;
    margin-bottom: 6px;
}

.SellerStepform .step2 .react-datepicker-wrapper {
    width: 100%;
}

.SellerStepform .displaycon {
    display: none;
}

/* step1 modal css Start*/
.stepform-modal .modal-text {
    text-align: center;
    padding: 0px 20px;
}

.stepform-modal .modal-text h4 {
    font-size: 20px;
    color: #383838;
    font-weight: bold;
}

.stepform-modal .modal-text .form-container {
    display: flex;
    justify-content: center;
    text-align: center;
}

.stepform-modal .modal-text .form-container .form-control {
    border-left: none;
    border-right: none;
    border-top: navajowhite;
    border-radius: 0px;
    border-bottom: 1px solid grey;
    width: 50%;
}

.stepform-modal .modal-text p.otp-text {
    color: #000000b2;
    font-size: 14px;
    font-weight: 400;
}

.stepform-modal .modal-text .verify-btn {
    background-color: #ff860059;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    width: 60%;
    border: none;
    padding: 5px;
}

.stepform-modal .modal-header {
    border: none;
}

.stepform-modal .modal-text .wp-icon {
    width: 20px;
    height: 20px;
}

.stepform-modal .modal-text .term-text {
    color: #00499e;
    font-size: 12px;
}

.SellerStepform .circle-icon-holder .circle-icon {
    font-size: 16px;
    font-weight: 500;
    color: #ff860059;
}

.SellerStepform .circle-icon-holder .circle-check {
    position: relative;
}

.SellerStepform .circle-icon-holder .check-icon {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 86%;
}

.SellerStepform .text-holder p {
    font-size: 15px;
    font-weight: 400;
}



.SellerStepform .stepp-inner-main .upoopp {
    font-weight: normal;
    font-size: 12px;
    color: #000;
}

.SellerStepform .stepp-inner-main .form-label {
    font-weight: normal;
    font-size: 12px;
    color: #000;
}

.SellerStepform .stepp-inner-main .form-select::placeholder {
    font-weight: normal;
    font-size: 12px;
    color: #b1b1b1;
}

/* .SellerStepform .stepp-inner-main .form-control {
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    color: #b1b1b1;
    padding: 10px;
    border-radius: 0px;
    margin-bottom: 23px;
} */



.SellerStepform .stepp-inner-main .bankInput .input-group-text {
    background-color: transparent !important;
}

.SellerStepform .stepp-inner-main .form-select {
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    color: #b1b1b1;
    padding: 10px;
}

.SellerStepform .stepp-inner-main .wevvcwec {
    font-weight: 600;
    font-size: 14px;
    color: #000;
    margin-bottom: 0px;
}

.SellerStepform .stepp-inner-main .nam-add-tab .nav-pills .nav-link {
    text-decoration: none;
}

.SellerStepform .stepp-inner-main .nam-add-tab .nav-pills .nav-link {
    color: #000;
    background-color: #fff;
    border-radius: 15px;
    background: #fff;
    border: 1px solid #000;
    font-size: 12px;
    padding: 5px 20px;
    margin: 0px 5px;
}

.SellerStepform .stepp-inner-main .nam-add-tab .nav-pills .nav-link.active {
    border: 1px solid #f88d28;
}

.SellerStepform .stepp-inner-main .continue-btn {
    background: transparent;
    color: #000;
    width: max-content;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    padding: 8px 20px;
    border: 1px solid #000;
}

.SellerStepform .stepp-inner-main .continue-btn:hover {
    background: #1d44ad;
    color: #fff;
    width: max-content;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    padding: 8px 20px;
    border: 1px solid #1d44ad;
}

.SellerStepform .stepp-inner-main .save-btn-div {
    text-align: center;
}

.SellerStepform .stepp-inner-main .back-btn {
    background: transparent;
    color: #000;
    width: 150px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    padding: 8px 20px;
    border: 1px solid #ccc;
    margin-right: 10px;
}

.SellerStepform .stepp-inner-main .back-btn:hover {
    background: transparent;
    color: #000;
    background: #ccc;
    width: 150px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    padding: 8px 20px;
    border: 1px solid #ccc;
    margin-right: 10px;
}

.SellerStepform .stepp-inner-main .delevidiv .cardflex {
    display: flex;
}

.SellerStepform .stepp-inner-main .delevidiv .img-div {
    min-width: 115px;
    height: auto;
}

.SellerStepform .stepp-inner-main .delevidiv .img-div img {
    width: 112px;
    height: auto;
}

.SellerStepform .stepp-inner-main .delevidiv .cont-div .nnnn {
    font-weight: 600;
    font-size: 14px;
    color: #000;
    margin-bottom: 10px;
}

.SellerStepform .stepp-inner-main .continue-btn .next-fafa {
    margin-left: 10px;
}

.SellerStepform .stepp-inner-main .delevidiv .cardflex .mimodd {
    font-weight: normal;
    font-size: 12px;
    color: #000;
    margin-bottom: 0px;
}

.SellerStepform .stepp-inner-main .delevidiv .esttdelby {
    font-weight: normal;
    font-size: 14px;
    color: #000;
    margin-bottom: 0px;
}

.SellerStepform .stepp-inner-main .delevidiv .addnott {
    font-weight: 600;
}

.SellerStepform .stepp-inner-main .delevidiv {
    margin-bottom: 30px;
}

.SellerStepform .stepp-inner-main .piecce-box {
    background: #f2f2f2;
    border-radius: 8px;
    padding: 10px;
}

.SellerStepform .stepp-inner-main .piecce-box .colll {
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-bottom: 0px;
}

.SellerStepform .stepp-inner-main .piecce-box .minus-box {
    width: 28px;
    height: 28px;
    border: 1px solid #c7c7c7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
}

.SellerStepform .stepp-inner-main .piecce-box .countnumbb {
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin: 0px 10px;
}

.SellerStepform .stepp-inner-main .piecce-box .countt {
    margin: 10px 0px;
}

.SellerStepform .stepp-inner-main .piecce-box .totatllamt {
    font-weight: bold;
    font-size: 16px;
    color: #000;
    margin-bottom: 0px;
}

.SellerStepform .stepp-inner-main .red-text {
    color: #ff4e4e;
    font-size: 12px;
    font-weight: 400;
}

.SellerStepform .stepp-inner-main a {
    color: #234C9E;
}

.SellerStepform .signup-link a {
    color: #234C9E !important;
    margin: 5px;
}

.SellerStepform .stepp-inner-main p.term-text {
    font-size: 12px;
    color: #707070;
}

.SellerStepform .stepp-inner-main .save-btn-div .save-btn {
    border: 1px solid #000000;
    background-color: #000000;
    border-radius: 5px;
    padding: 4px 30px;
    font-size: 14px;
    font-weight: 400;
    color: #ffff;
}

.SellerStepform .stepp-inner-main .save-btn-div .save-btn:hover {
    background-color: #ffff;
    color: #000;
}

.SellerStepform .stepp-inner-main .addcard-icci {
    margin-left: 20px;
}

.SellerStepform .stepp-inner-main .addcard-icci img {
    height: 15px;
    width: auto;
    margin: 15px 15px 15px 0px;
}

.SellerStepform .nam-add-tab .nav-item {
    margin-bottom: 7px;
}

.SellerStepform .stepp-inner-main .addressHead h3 {
    font-size: 18px;
    color: #333333;
    margin-bottom: 16px;
}


.SellerStepform .stepp-inner-main .choss-add {
    font-weight: bold;
    font-size: 20px;
    color: #000;
    text-align: center;
}

.SellerStepform .stepp-inner-main .address-div .title-div {
    position: relative;
}

.SellerStepform .stepp-inner-main .address-div .title-div .dlttt-div {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: inline-end;
    position: relative;
}

.SellerStepform .stepp-inner-main .address-div .title-div .dlttt-div span {
    display: none;
}

.SellerStepform .stepp-inner-main .address-div .title-div .dlttt-div:hover span {
    position: absolute;
    font-size: 12px;
    text-wrap: nowrap;
    right: 0;
    top: -21px;
    display: block;
}

.SellerStepform .stepp-inner-main .address-div .addd-card {
    border-radius: 5px;
    background: #fff;
    border: 1px solid #707070;
    padding: 10px;
    position: relative;
    width: 100%;
    height: 100%;
}

.SellerStepform .stepp-inner-main .address-div .addd-card .card-flex {
    display: flex;
}

.SellerStepform .stepp-inner-main .address-div .addd-card .card-flex .loc-img-div {
    min-width: 80px;
    position: relative;
}

.SellerStepform .stepp-inner-main .address-div .addd-card .card-flex .loc-img-div .map-img {
    width: 76px;
    height: 76px;
    object-fit: cover;
}

.SellerStepform .stepp-inner-main .address-div .addd-card .card-flex .loc-img-div .loc-img {
    width: 38px;
    position: absolute;
    top: 20px;
    left: 17px;
}

.SellerStepform .stepp-inner-main .address-div .addd-card .card-flex .cont-div {
    padding: 0px 5px;
}

.SellerStepform .stepp-inner-main .address-div .addd-card .card-flex .cont-div .hom {
    font-weight: bold;
    font-size: 18px;
    color: #323232;
    margin-bottom: 5px;
}

.SellerStepform .stepp-inner-main .address-div .addd-card .card-flex .cont-div .name {
    font-weight: 500;
    font-size: 14px;
    color: #323232;
    margin-bottom: 5px;
}

.SellerStepform .stepp-inner-main .address-div .addd-card .card-flex .cont-div .adddd {
    font-weight: normal;
    font-size: 14px;
    color: #323232;
    margin-bottom: 0px;
}

.SellerStepform .stepp-inner-main .address-div .addd-card .form-check .form-check-input {
    float: right;
    margin-left: -1.5em;
}

.SellerStepform .stepp-inner-main .address-div .edit {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 14px;
    color: #1d44ad;
    font-weight: 500;
    text-decoration: none;
}

.SellerStepform .stepp-inner-main .addd-card-new-add {
    border-radius: 5px;
    background: #f3f3f3;
    border: 1px solid #707070;
    padding: 10px;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SellerStepform .stepp-inner-main .addd-card-new-add .addnewaddbtn {
    border-radius: 5px;
    background: #1d44ad;
    padding: 7px 20px;
    border-radius: 7px;
    border: 0px;
    color: #fff;
    font-size: 18px;
}

.SellerStepform .stepp-inner-main .payment-optdiv {
    margin-top: 40px;
}

.SellerStepform .stepp-inner-main .payment-optdiv .chjvvef {
    font-weight: normal;
    font-size: 16px;
    color: #323232;
}

.SellerStepform .stepp-inner-main .payment-optdiv .payment-card {
    width: 100%;
    height: 215px;
    background: #f0f7ff;
    border-radius: 5px;
    padding: 30px 20px;
}

.SellerStepform .stepp-inner-main .payment-optdiv .payment-card .paywithcredit {
    font-weight: 500;
    font-size: 16px;
    color: #000;
}

.SellerStepform .stepp-inner-main .payment-optdiv .payment-card .paymopttimg {
    height: 40px;
    width: auto;
}

.SellerStepform .stepp-inner-main .payment-optdiv .payment-card .form-check-input:checked {
    background-color: #000000;
    border-color: #000000;
    font-size: 18px;
}

.SellerStepform .stepp-inner-main .payment-optdiv .payment-card .form-check-input {
    font-size: 18px;
    margin-right: 13px;
}

.SellerStepform .stepp-inner-main .orde-status-dv {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    margin-top: 3%;
}

.SellerStepform .stepp-inner-main .orde-status-dv .colwidth p {
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    color: #000;
    margin-bottom: 10px !important;
}



.SellerStepform .stepp-inner-main .orderr-dettt .jhber {
    font-weight: bold;
    font-size: 14px;
    color: #000;
}

.SellerStepform .stepp-inner-main .orderr-dettt {
    margin-top: 30px;
}

.SellerStepform .stepp-inner-main .detailssss-flex {
    display: flex;
}

.SellerStepform .stepp-inner-main .detailssss-flex .userimgg {
    width: 49px;
    height: auto;
}

.SellerStepform .stepp-inner-main .detailssss-flex .img-div {
    /* min-width: 165px; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    width: 83px;
    height: 83px;
    text-align: center;
    padding-top: 12px;
}

.contdivvv {
    margin-top: auto;
    margin-bottom: auto;
}

.SellerStepform .stepp-inner-main .detailssss-flex .contdivvv .name {
    font-size: 15px;
    color: #333;
    margin-bottom: 0;
    margin-left: 25px;
}

.SellerStepform .stepp-inner-main .detailssss-flex .contdivvv .name span {
    font-size: 18px;
}

.SellerStepform .stepp-inner-main .detailssss-flex .contdivvv .prodrsvd {
    font-weight: normal;
    font-size: 14px;
    color: #333;
}

.SellerStepform .stepp-inner-main .detailssss-flex .contdivvv .price {
    font-weight: 500;
    font-size: 20px;
    color: #333;
}

.SellerStepform .stepp-inner-main .detailssss-flex .contdivvv .price span {
    font-weight: 500;
    font-size: 15px;
    color: #808080;
    text-decoration: line-through;
    padding-left: 20px;
}

.SellerStepform .stepp-inner-main .detailssss-flex .contdivvv .sizeeepp {
    font-size: 15px;
    color: #333;
    margin-bottom: 0px;
}

.SellerStepform .stepp-inner-main .detailssss-flex .contdivvv .sizenumm {
    border-radius: 3px;
    background: #1d44ad;
    color: #fff;
    padding: 6px;
    width: fit-content;
    font-size: 14px;
}

.SellerStepform .stepp-inner-main .totalprieee {
    margin-top: 50px;
}

.SellerStepform .stepp-inner-main .totalprieee h6 {
    font-weight: 500;
    font-size: 16px;
    color: #000;
    margin-bottom: 0;
}

.SellerStepform .stepp-inner-main .totalbg {
    background: #f5f5f5;
    padding: 20px;
}

.SellerStepform .stepp-inner-main .finaladddd {
    font-weight: 500;
    font-size: 14px;
    color: #000;
}


.SellerStepform .stepp-inner-main .textAreacontent {
    width: 100%;
}

.SellerStepform .stepp-inner-main .earnPoints {
    font-size: 14px;
    margin-bottom: 35px;
}

.SellerStepform .stepp-inner-main .checkboxfieldd {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 33px;
}

.SellerStepform .stepp-inner-main .progress-bar-container .step-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.SellerStepform .stepp-inner-main .progress-bar-container .step-container {
    position: relative;
    width: 100%;
    /* margin-right: 30px; */
}

.SellerStepform .stepp-inner-main .progress-bar-container .step-text {
    font-size: 14px;
    color: #B2B2B2;
    font-weight: 400;
}

.addresscheck {
    margin-right: 60px;
}

.SellerStepform .progress-bar-container .step-text.active,
.SellerStepform .progress-bar-container .step-text.completed {
    font-weight: bold;
    color: #000000;
}

.SellerStepform .progress-bar-container .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    color: #000;
    font-size: 20px;
    border: 1px solid #B2B2B2;
    width: 100%;
}

.SellerStepform .progress-bar-container .circle.completed {
    border: 1px solid #234C9E;
}

.SellerStepform .progress-bar-container .step-container .centerDivcircle {
    margin-top: auto;
    margin-bottom: auto;
}



.SellerStepform .progress-bar-container {
    margin: 30px 0px;
}

.SellerStepform .progress-bar-container .line.active {
    width: 26px;
    background: #234C9E;
    height: 26px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.SellerStepform .progress-bar-container .line.completed {
    width: 26px;
    background: transparent;
    height: 26px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: 1px solid #000;
}

/* Payment_checkout shipping css */

.Addnewaddress .form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    font-size: 14px !important;
}

.SellerStepform .progress-bar-container .line {
    width: 26px;
    background: transparent;
    height: 26px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: 1px solid #707070;
}

.Addnewaddress .form-label {
    font-size: 14px;
}

.Addnewaddress .btnsec {
    text-align: center;
}

.Addnewaddress .btnsec button {
    background: #234C9E 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: #fff;
    border: 0;
    font-size: 14px;
}

.Addnewaddress .checksRadios .form-check {
    font-size: 14px;
}

.Addnewaddress .modal-content {
    border-radius: 20px;
}

.manualSelect__control {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    font-size: 14px !important;
    background: transparent !important;
}

/* Payment_checkout shipping css */

@media screen and (min-width: 0px) and (max-width: 485px) {
    .SellerStepform .line-right {
        display: none;
    }

    .SellerStepform .progress-bar-container .step-text {
        font-size: 13px;
        color: #000000;
        font-weight: 500;
        margin: 0px 0px 0px 8px;
    }

    .SellerStepform .signup-form {
        margin: 0px;
    }

    .SellerStepform .progress-bar-container .circle-center {
        align-items: center;
    }

    .SellerStepform .progress-bar-container .circle-center {
        align-items: center;
    }


    .Product_total_seccc .proddd-nnnn .prood-nname {
        font-size: 13px;
    }

    .Product_total_seccc .protect-odd-div ul li {
        font-size: 13px;
    }

    .nam-add-tab .nav.nav-pills .nav-item {
        width: 116px;
        margin: 4px 0px;
    }

    .SellerStepform .stepp-inner-main .continue-btn {
        font-size: 13px;
        padding: 5px 11px;
    }

    .SellerStepform .progress-bar-container .line.active {
        width: 20px !important;
        height: 20px !important;
    }

    .SellerStepform .progress-bar-container .circle-center {
        align-items: center;
        margin-top: auto;
        margin-bottom: auto;
    }

    .progress-bar-container .line {
        width: 20px !important;
        height: 20px !important;
    }

    .progress-bar-container .step-container .centerDivcircle {
        margin-top: auto;
        margin-bottom: auto;
        text-wrap: nowrap;
    }

    .SellerStepform .progress-bar-container .step-row {
        overflow-x: auto;
    }

    .SellerStepform .stepp-inner-main .back-btn {
        width: 116px;
        padding: 5px 20px;
    }

    .SellerStepform .stepp-inner-main .continue-btn {
        width: 120px;
        padding: 5px 15px;
    }

    .SellerStepform .stepp-inner-main .payment-optdiv .payment-card .paymopttimg {
        width: 80px !important;
    }

    .SellerStepform .stepp-inner-main .orde-status-dv .colwidth {
        margin-bottom: 20px;
    }

    .SellerStepform .stepp-inner-main .detailssss-flex .contdivvv .name {
        font-size: 15px !important;
    }

    .SellerStepform .stepp-inner-main .detailssss-flex .contdivvv .name span {
        font-size: 14px !important;
    }

    .SellerStepform .stepp-inner-main .detailssss-flex .contdivvv .prodrsvd {
        font-size: 11px !important;
    }

    .SellerStepform .stepp-inner-main .detailssss-flex .contdivvv .price {
        font-size: 14px !important;
    }

    .SellerStepform .stepp-inner-main .detailssss-flex .contdivvv .price span {
        font-size: 11px !important;
        padding-left: 8px !important;
    }

    .SellerStepform .stepp-inner-main .addd-card-new-add .addnewaddbtn {
        font-size: 11px;
    }

    .SellerStepform .stepp-inner-main .address-div .addd-card .card-flex .cont-div .adddd {
        font-size: 12px;
    }

    .SellerStepform .stepp-inner-main .orde-status-dv .colwidth p {
        font-size: 13px;
        margin-bottom: 0 !important;
    }

    .SellerStepform .stepp-inner-main .address-div .addd-card .card-flex .cont-div .hom {
        font-size: 15px;
    }

    .SellerStepform .stepp-inner-main .address-div .addd-card .card-flex .cont-div .name {
        font-size: 13px;
    }

    .SellerStepform .stepp-inner-main .totalprieee h6 {
        font-size: 13px;
    }

    .SellerStepform .stepp-inner-main .finaladddd {
        font-size: 13px;
    }

    .SellerStepform .stepp-inner-main .payment-optdiv .payment-card .form-check-input {
        margin-right: 6px;
    }

}

@media screen and (min-width: 486px) and (max-width: 767px) {
    .SellerStepform .line-right {
        display: none;
    }

    .SellerStepform .stepp-inner-main .payment-optdiv .payment-card .form-check-input {
        margin-right: 6px;
    }

    .SellerStepform .stepp-inner-main .finaladddd {
        font-size: 13px;
    }

    .SellerStepform .stepp-inner-main .totalprieee h6 {
        font-size: 13px;
    }

    .SellerStepform .stepp-inner-main .address-div .addd-card .card-flex .cont-div .name {
        font-size: 13px;
    }

    .SellerStepform .stepp-inner-main .address-div .addd-card .card-flex .cont-div .hom {
        font-size: 15px;
    }

    .SellerStepform .stepp-inner-main .address-div .addd-card .card-flex .cont-div .adddd {
        font-size: 12px;
    }

    .progress-bar-container .line {
        width: 20px;
        height: 20px;
    }

    .progress-bar-container .line.active {
        width: 20px;
        height: 20px;
    }


    .SellerStepform .signup-form {
        margin: 0px;
    }

    .SellerStepform .progress-bar-container .step-text {
        font-size: 12px;
        margin: 0px 0px 0px 4px;
    }

    .nam-add-tab .nav.nav-pills .nav-item {
        width: 116px;
        margin: 4px 0px;
    }

    .SellerStepform .stepp-inner-main .nam-add-tab .nav-pills .nav-link {
        text-align: center;
    }

    .SellerStepform .stepp-inner-main .continue-btn {
        font-size: 14px;
        padding: 5px 11px;
    }

    .SellerStepform .stepp-inner-main .detailssss-flex .contdivvv .name {
        font-size: 15px !important;
    }

    .SellerStepform .stepp-inner-main .detailssss-flex .contdivvv .price {
        font-size: 14px !important;
    }

    .SellerStepform .stepp-inner-main .addd-card-new-add .addnewaddbtn {
        font-size: 11px;
    }

    .SellerStepform .stepp-inner-main .orde-status-dv .colwidth p {
        font-size: 13px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

    .SellerStepform .progress-bar-container .circle-center {
        align-items: center;
    }

    .SellerStepform .signup-form {
        margin: 0px;
    }

    .line-right {
        margin: 0px 11px;
        margin-bottom: 11px;
        width: 109px;
    }

    .SellerStepform .progress-bar-container .step-text {
        font-size: 14px;
        margin: 0px 0px 0px 4px;
    }

    .SellerStepform .stepp-inner-main .payment-optdiv .payment-card .paymopttimg {
        width: 98px !important;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .line-right {
        width: 64px;
    }


    /* .SellerStepform .stepp-inner-main .address-div .title-div .dlttt-div:hover span {
        left: -20px;
    } */

    .SellerStepform .signup-form {
        margin: 10px 0px 24px 15px;
    }

    .SellerStepform .progress-bar-container .step-text {
        font-size: 14px;
    }

    .SellerStepform .stepp-inner-main .nam-add-tab .nav-pills .nav-link {
        margin: 4px 5px;
    }

    .Product_total_seccc .proddd-nnnn .prood-nname {
        font-size: 13px;
        line-height: 17px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .line-right {
        width: 119px;
    }


    .SellerStepform .signup-form {
        margin: 10px 0px 24px 15px;
    }

    .SellerStepform .progress-bar-container .step-text {
        font-size: 14px;
    }

    .SellerStepform .stepp-inner-main .nam-add-tab .nav-pills .nav-link {
        margin: 4px 5px;
    }

    .Product_total_seccc .proddd-nnnn .prood-nname {
        font-size: 13px;
        line-height: 17px;
    }
}