.ProductInner {
  padding-top: 3%;
  margin-bottom: 5%;
  background: transparent linear-gradient(180deg, #f3f3f3 0%, #f5f5f5 80%, #ffffff 100%) 0% 0% no-repeat padding-box;
}

.ProductInner .card-sec {
  background-color: #ffffff;
  padding: 38px 15px;
  position: relative;
  margin-bottom: 13px;
}

.ProductInner .getUnderline {
  text-decoration: underline;
}

.ProductInner .removeUnderline {
  text-decoration: none;
}

.ProductInner .Main-div .camera-imgs img.inner-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.ProductInner .Main-div .camera-imgs {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.ProductInner .buttonsectionmain .addtocartbtn {
  background-color: #ffba00;
  border-color: #ffba00;
  /* padding: 8px 31px; */
  width: 150px;
  height: 46px;
  border-radius: 0;
  margin-right: 28px;
}

.ProductInner .buttonsectionmain .buynowbtn {
  background-color: #d42d2a;
  border-color: #d42d2a;
  width: 150px;
  height: 46px;
  border-radius: 0;
}

.ProductInner .Main-div {
  padding: 8px 10px;
  height: auto;
  background: #efefef 0% 0% no-repeat padding-box;
  border: 1px solid #a19393;
  width: 62px;
  height: 62px;
  border-radius: 6px;
}

.Productmaindesktop {
  display: block;
}

.Productmainmobile {
  display: none;
}

.ProductInner .productInfo.justify-content-center.row {
  position: sticky;
  top: 0%;
}

.ProductInner .Main-div:hover {
  background: #ccc8c8 0% 0% no-repeat padding-box;
}

.ProductInner .card-sec img.product-img {
  height: 100%;
  width: 100%;
}

.ProductInner .card-sec .product-detail {
  /* height: auto; */
  width: 100%;
  /* aspect-ratio: 1/1; */
  position: relative;
  overflow: hidden;
}

.ProductInner .mySwiper .swiper-wrapper {
  height: auto !important;
}

.ProductInner .mySwiper {
  height: 380px !important;
  overflow: scroll;
}

.ProductInner .mySwiper::-webkit-scrollbar {
  display: none;
}

.ProductInner .card-sec .product-detail .specs {
  height: auto;
  width: 100%;
  /* aspect-ratio: 1/1; */
}

.ProductInner .silder-btn .back-btn {
  top: 14px;
  position: absolute;
  background-color: black;
  height: 40px;
  width: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  left: 51px;
}

.ProductInner .silder-btn .next-btn {
  left: 0px;
  top: 14px;
  position: absolute;
  background-color: black;
  height: 40px;
  width: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ProductInner .silder-btn {
  position: relative;
}

.ProductInner .swiper-button-prev {
  display: none;
}

.ProductInner .swiper-button-next {
  display: none;
}

.ProductInner .icon-main {
  padding: 9px 13px;
  color: black;
  font-size: 23px;
}

.ProductInner .Main-Buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 28px;
}

.ProductInner .Main-Buttons button.minus.btn {
  padding: 3px 32px;
}

.ProductInner .Main-Buttons button.add.btn {
  padding: 12px 32px;
}

.ProductInner .shopping-img-align {
  margin-right: 11px;
}

.ProductInner .quantity {
  font-weight: 600;
  padding: 13px 20px;
  margin-bottom: 0px;
  font-size: 14px;
  color: black;
}

.ProductInner button.active.btn.btn-standard {
  font-weight: 600;
  border: none;
  font-size: 13px;
  border: none;
  border-radius: 0px;
  background-color: #ededed;
}

.ProductInner button.btn.btn-standard {
  font-weight: 600;
  border: none;
  font-size: 13px;
  border: none;
  border-radius: 0px;
}

/* Information css */
.ProductInner .Details-section .price {
  display: flex;
}

.ProductInner .Details-section .info {
  border-top: 1px solid #cdcdcd;
  border-bottom: 1px solid #d9d9d9;
}

.ProductInner .productInfohead h3 {
  font-size: 18px;
  letter-spacing: 0px;
  color: #222222;
}

.ProductInner .productInfo .card-text span {
  font-size: 13px;
}

.ProductInner .productInfocard {
  border: 0;
}

.ProductInner .zoomable {
  position: relative;
  overflow: hidden;
  height: auto;
  width: 100%;
  aspect-ratio: 1/1;
}

.ProductInner .zoomable__img {
  transform-origin: var(--zoom-pos-x, 0%) var(--zoom-pos-y, 0%);
  transition: transform 0.15s linear;
  width: 302px;
  height: 318px;
  object-fit: contain;
}


.ProductInner .productInfocard .card-subtitle {
  font-weight: 600;
  font-size: 15px;
  color: #222222 !important;
}

.ProductInner .zoomable--zoomed .zoomable__img {
  cursor: zoom-in;
  transform: scale(var(--zoom, 2));
}

.ProductInner .colourSection {
  display: flex;
  margin-right: 5px;
}

.ProductInner .productImgcolour img {
  width: 36px;
  height: 36px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  margin-right: 10px;
}

.ProductInner .productInfocard .productSubhead h3 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
}

.ProductInner .productInfocard .totalQuantitysec {
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
}

.ProductInner .productInfocard .totalQuantitysec p {
  font-size: 16px;
  font-weight: 600;
}

.ProductInner .productInfocard .customisebtn button {
  background: #333333 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border: 0;
  width: 100%;
  margin-bottom: 5px;
}

.ProductInner .productInfocard .quantityTable {
  border-bottom: 1px solid #ccc;
  margin-bottom: 21px;
  padding-bottom: 12px;
}

.ProductInner .productInfocard .quantityTable h3{
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
}


.ProductInner .formQuantity .form-control {
  font-size: 14px;
}

.ProductInner .productInfocard .customisebtn p {
  font-size: 14px;
  color: #707070;
  margin-top: 8px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 18px;
  margin-bottom: 27px;
}

.ProductInner .productInfocard .accordionProdInfo {
  margin-top: 20px;
  margin-bottom: 8%;
}

.ProductInner .productInfocard .accordionProdInfo .accordion-body {
  overflow: hidden;
  overflow-x: scroll;
}

.ProductInner .allreviews {
  /* overflow-y: auto;
  height: 323px; */
  margin-top: 4%;
  margin-bottom: 10%;
  /* overflow-x: hidden; */
  padding-right: 6px;
}

.ProductInner .startpointsmain .circleStar {
  width: auto;
  height: 12px;
  object-fit: contain;
  margin-right: 5px;
}

.ProductInner .productInfocard .accordion-item .accordion-button {
  font-size: 13px;
  font-weight: 600;
  padding: 15px 0px;
  box-shadow: unset !important;
}

.ProductInner .productInfocard .productSubhead .accordion-item .accordion-button {
  padding: 24px 0px;
}

.ProductInner .productInfocard .belowHEading p {
  font-size: 14px;
}

.ProductInner .swiper-backface-hidden .swiper-slide {
  height: 100% !important;
  margin-bottom: 7%;
}

/* Product Review start*/
.ProductInner .ProductReview .reviewHeading {
  font-size: 25px;
  margin-top: 5%;
}

.ProductInner .ProductReview .allreviews p {
  text-align: left;
  font-weight: 600;
}

.ProductInner .ProductReview .allreviews .no-data {
  text-align: center;
  font-weight: 600;
}

.ProductInner .ProductReview .reviewHead p {
  font-size: 14px;
  margin-bottom: 1px;
}

.ProductInner .ProductReview .reviewName {
  font-weight: 600;
  color: #323232;
  font-size: 15px;
}

.ProductInner .ProductReview .reviewHead {
  margin-bottom: 26px;
}

.ProductInner .ProductReview .helpful {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 5px;
  padding: 0 15px;
  color: #000;
  font-size: 13px;
  border: 0;
}

.ProductInner .accordion-item {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0 !important;
}

.ProductInner .ProductReview .helpReportbtnsec {
  display: flex;
  margin-top: 7px;
}

.ProductInner .ProductReview .helpReportbtnsec p {
  font-size: 12px;
  margin-left: 21px;
}

.ProductInner .horizantalSlider {
  display: none;
}

.ProductInner .quantityTable .quantitySize p {
  margin-bottom: 0;
  font-size: 13px;
}

.ProductInner .quantityTable .quantitySize {
  margin-bottom: 9px;
}

.ProductInner .quantityTable .quantitySize .productdashedline {
  border-bottom: 1px dashed #ccc;
}

.ProductInner .productSubhead .sizeChartMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-top: 20px;
}

.ProductInner .colourSubhead {
  display: flex;
  justify-content: space-between;
}

.ProductInner .colourSubhead h3 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 7px;
}

.ProductInner .colourSubhead p {
  font-size: 15px;
  font-weight: 600;
}

.ProductInner .productSubhead .sizeChartMain .chartinfo {
  width: 70px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 14px;
  padding: 7px;
}

.ProductInner .productSubhead .accordion-button:not(.collapsed) {
  color: #000;
  background-color: transparent;
}

.ProductInner .productSubhead .sizeChartMain .sizechart {
  margin-bottom: 20px;
}

.ProductInner .productSubhead .sizeChartMain .sizechart p {
  margin-bottom: 0;
  font-size: 13px;
  margin-top: 3px;
}

.ProductInner .productSubhead .accordion-body {
  padding: 14px 0px;
}

.ProductInner .reviewImgmain {
  position: relative;
}

.ProductInner .reviewImgmain .reviewImg {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 10px;
  object-fit: cover;
}

.ProductInner .reviewImgmain .playIconimg {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 34px;
  height: 34px;
  left: 50%;
}

.ProductInner .ColourMain {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}

.ProductInner .ColourMain h5 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
}

.ProductInner .startpointsmain {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}

.ProductInner .quantitymain {
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 14px;
  color: black;
}

.ProductInner .accordion-button:not(.collapsed) {
  color: #000;
  background-color: transparent;
}

.ProductInner .accordionContent p {
  font-size: 13px;
  margin-bottom: 0;
}

/* Product Review end*/

@media (min-width: 0px) and (max-width: 485px) {
  .ProductInner .ProductReview .reviewHead p {
    font-size: 12px;
  }

  .ProductInner .reviewImgmain .playIconimg {
    width: 25px;
    height: 25px;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .ProductInner .zoomable__img {
    width: 100%;
    height: 172px;
    object-fit: contain;
  }

  .ProductInner .mySwiper {
    height: auto !important;
    margin-bottom: 17px;
  }

  .ProductInner .reviewImgmain .reviewImg {
    margin-bottom: 0px;
  }

  .ProductInner .verticalSlider {
    display: none;
  }

  .ProductInner .horizantalSlider {
    display: block;
  }

  .ProductInner .swiper-backface-hidden .swiper-slide {
    margin-bottom: 0%;
  }

  .ProductInner .productSubhead .sizeChartMain {
    flex-wrap: nowrap;
    justify-content: space-between;
    overflow-x: auto;
  }

  .Productmaindesktop {
    /* display: none; */
  }

  .Productmainmobile {
    display: block;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .ProductInner .zoomable__img {
    width: 100%;
    height: 308px;
    object-fit: contain;
  }

  .ProductInner .ProductReview .reviewHead p {
    font-size: 13px;
  }

  .ProductInner .Main-div {
    width: 54px;
    height: 54px;
  }
}

@media (min-width: 0px) and (max-width: 485px) {
  .ProductInner .Main-Buttons button.minus.btn {
    padding: 3px 22px;
  }

  .ProductInner .Main-Buttons button.add.btn {
    padding: 3px 22px;
  }

  .ProductInner .Details-section .info .sub-heading {
    text-align: justify;
  }
}