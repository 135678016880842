.ReturOrder {
    margin: 3%;
}

.ReturOrder .returnproductImg {
    width: 100%;
}

.ReturOrder .returnOrderdetails .productHead h3 {
    font-size: 18px;
    font-weight: bold;

}

.ReturOrder .returnOrderdetails .return-cancel-btn button {
    width: auto;
    font-size: 14px;
    background-color: transparent;
    border-color: #000;
    color: #000;
    border-radius: 0;
}

.ReturOrder .returnOrderdetails .return-cancel-btn button:hover {
    background: #E8E8E8 0% 0% no-repeat padding-box;
    border-radius: 3px;
    border: 0;
}

.ReturOrder .returnOrderdetails .ProductTitle {
    margin-bottom: 15px;
}

.ReturOrder .returnOrderdetails .viewproductDetailsbtn button {
    background: #173F8F 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: #fff;
    width: auto;
    border: 0;
    font-size: 14px;
}

.ReturOrder .returnOrderdetails .productHead p {
    font-size: 16px;
    margin-bottom: 3px;
}

.ReturOrder .returnOrderdetails .OrderTracking {
    margin-bottom: 15px;
}

.ReturOrder .returnOrderdetails .OrderTracking h3 {
    font-size: 17px;
    font-weight: 600;
}

.ReturOrder .returnOrderdetails .TrackingProcess {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.ReturOrder .returnOrderdetails .TrackingProcess .Order {
    width: 160px;
    height: 79px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #000000;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ReturOrder .returnOrderdetails .orderplacehead h3 {
    font-size: 16px;
    margin-left: 5px;
    margin-bottom: 0;
}

.ReturOrder .returnOrderdetails .Order .orderIcon {
    background-color: #234C9E;
    border: 1px solid #234C9E;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ReturOrder .returnOrderdetails .viewproductDetailsbtn h3 {
    font-size: 18px;
    font-weight: bold;
}


.ReturOrder .returnOrderdetails .Order .orderIcon-incomplete{
    border: 1px solid #234C9E;
    background-color: #fff;
}

.ReturOrder .returnOrderdetails .Order .orderIcon-incomplete  .status-img-incompleted {
    filter: brightness(0.5);
}

.ReturOrder .returnOrderdetails .Order .orderIconImg {
    display: flex;
    justify-content: center;
    /* margin-top: 14px; */
    align-items: center;
    margin-bottom: 5px;
}

.ReturOrder .returnOrderdetails .Order .orderIcon .status-img {
    width: 100%;
    height: 14px;
    margin-left: 2px;
    object-fit: contain;
}

.ReturOrder .returnOrderdetails .Order .orderIconImg p {
    font-size: 15px;
    margin-left: 5px;
    margin-bottom: 5px;
}

.ReturOrder .returnOrderdetails .Order p {
    font-size: 14px;
    color: #808080;
    text-align: center;
    margin-bottom: 0px;
}

.ReturOrder .returnOrderdetails .OrderSummary {
    background-color: #F6F6F6;
    padding: 25px;
    margin-bottom: 25px;
}

.ReturOrder .returnOrderdetails .OrderSummary .OrderDetailshead h3 {
    font-size: 17px;
    margin-bottom: 17px;
}

.ReturOrder .returnOrderdetails .Pricedetailsmain h3 {
    font-size: 16px;
}

.ReturOrder .returnOrderdetails .Pricedetailsmain {
    display: flex;
    justify-content: space-between;
}

.ReturOrder .returnOrderdetails .summaryDetails {
    border-bottom: 1px solid #ccc;
}

.ReturOrder .returnOrderdetails .grossPrice p {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 5px;
}

.ReturOrder .returnOrderdetails .Delivery_status {
    margin-bottom: 30px;
}

.ReturOrder .returnOrderdetails .Delivery_status h3 {
    font-size: 17px;
    font-weight: 600;
}

.ReturOrder .returnOrderdetails .Delivery_status p:nth-of-type(1) {
    font-size: 14px;
    margin-bottom: 1px;
    font-weight: 600;
}

.ReturOrder .returnOrderdetails .Delivery_status p:nth-of-type(2) {
    font-size: 14px;
}

.ReturOrder .returnOrderdetails .dottedLine {
    border: 0;
    height: 1px;
    margin: auto 0;
    border-bottom: 1px dashed #000;
    width: 11%;
    position: relative;
}



.ReturOrder .returnOrderdetails .dottedLine:after {
    content: "";
    position: absolute;
    right: 0px;
    width: 8px;
    height: 8px;
    top: -3px;
    border: 1px solid #234C9E;
    background: #fff;
    border-radius: 50%;
}



.ReturOrder .returnOrderdetails .dottedLine-completed {
    border: 0;
    height: 1px;
    margin: auto 0;
    border-bottom: 1px dashed #000;
    width: 11%;
    position: relative;
}

.ReturOrder .returnOrderdetails .dottedLine-completed:before {
    content: "";
    position: absolute;
    left: 0px;
    width: 8px;
    height: 8px;
    top: -3px;
    border: 1px solid #234C9E;
    background: #234C9E;
    border-radius: 50%;
}

.ReturOrder .returnOrderdetails .dottedLine-completed:after {
    content: "";
    position: absolute;
    right: 0px;
    width: 8px;
    height: 8px;
    top: -3px;
    border: 1px solid #234C9E;
    background: #234C9E;
    border-radius: 50%;
}

.ReturOrder .returnOrderdetails .virvle {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    border: 1px solid #234C9E;
}


@media screen and (min-width: 0px) and (max-width: 767px) {
    .ReturOrder .returnOrderdetails .orderplacehead h3 {
        font-size: 11px;
        margin-left: 5px;
    }

    .ReturOrder .returnOrderdetails .Order p {
        font-size: 10px;
    }

    .ReturOrder .returnOrderdetails .Order .orderIconImg {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .ReturOrder .returnproductImg {
        width: 100%;
        object-fit: contain;
        height: 193px;
        margin-bottom: 15px;
    }

    .ReturOrder .returnOrderdetails .viewproductDetailsbtn h3 {
        font-size: 15px;
    }

    .ReturOrder .viewproductDetailsbtn {
        text-align: left;
    }

    .ReturOrder .returnOrderdetails .Pricedetailsmain h3 {
        font-size: 13px;
    }

    .ReturOrder .returnOrderdetails .Pricedetailsmain p {
        font-size: 13px;
    }

    .ReturOrder .returnOrderdetails .return-cancel-btn button {
        font-size: 11px;
    }

    .ReturOrder .returnOrderdetails .TrackingProcess {
        overflow-x: auto;
        width: 100%;
        padding-bottom: 13px;
    }

    .ReturOrder .returnOrderdetails .productHead p {
        font-size: 13px;
    }

    .ReturOrder .returnOrderdetails .Delivery_status p:nth-of-type(2) {
        font-size: 13px;
    }

    .ReturOrder .return-cancel-btn {
        margin-top: 5px;
    }

    .ReturOrder .returnOrderdetails .orderplacehead h3 {
        font-size: 11px;
        text-wrap: nowrap;
    }

    .ReturOrder .returnOrderdetails .TrackingProcess .Order {
        padding: 5px;
        /* margin-right: 10px; */
    }
/* 
    .ReturOrder .returnOrderdetails .dottedLine-completed:after {
       display: none;
    }

    .ReturOrder .returnOrderdetails .dottedLine-completed:before {
      display: none;
    }

    .ReturOrder .returnOrderdetails .dottedLine-completed {
        display: none;
    } */

    .ReturOrder .returnOrderdetails .dottedLine-completed {
        padding: 0px 30px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .ReturOrder .returnproductImg {
        object-fit: contain;
        height: 275px;
        margin-bottom: 28px;
    }

    .ReturOrder .returnOrderdetails .TrackingProcess .Order {
        /* height: 93px; */
    }

    .ReturOrder .returnOrderdetails .orderplacehead h3 {
        font-size: 13px;
        text-wrap: nowrap;
    }

    .ReturOrder .returnOrderdetails .Order p {
        font-size: 12px;
    }
}