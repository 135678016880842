.AboutUs .valueStandby .valueStandbyHead h3 {
    text-align: center;
    margin-bottom: 4%;
    font-family: 'Gothamregular';
    font-size: 33px;
    text-align: center;
    margin-bottom: 4%;
    margin: 0;
    color: #1e1e1e;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: 60.6px;
    letter-spacing: -1.38px;
    padding-bottom: 40px;
}

 .boldtext {
    font-family: 'GothamBold';
}

.AboutUs .valueStandby .valuestandCard {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 0px 6px #00000029, 0px 0px 6px #00000029;
    border-radius: 14px;
    border: 0;
    padding: 63px 23px 12px 23px;
    position: relative;
    /* margin-bottom: 5%; */
    height: 250px;
}

.AboutUs .valueStandby .valueStandby-swiper {
    padding: 45px 0px;
}

.AboutUs .valueStandby .valuestandCard .cardHead h3 {
    font-size: 25px;
    font-weight: 500;
    font-family: 'Gothammedium';
    padding-bottom: 5px;
}

.AboutUs .valueStandby .valuestandCard .cardImgsec img {
    width: 31px;
    height: 33px;
    object-fit: contain;
}

.AboutUs .valueStandby .valuestandCard .cardHead p {
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
    font-family: 'Gothamregular';
}

.AboutUs .valueStandby .valuestandCard .cardImgsec {
    width: 56px;
    border-radius: 50%;
    height: 56px;
    background: #E9F1FF 0% 0% no-repeat padding-box;
    position: absolute;
    top: -27px;
    left: 50%;
    transform: translate(-50%, 0%);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width:0px) and (max-width:485px) {

    .AboutUs .valueStandbyHead {
        padding: 20px 0px;
    }

    .AboutUs .valueStandby .valueStandbyHead h3 {
        font-size: 24px;
        margin-bottom: 12%;
    }

    .AboutUs .valueStandby .valuestandCard .cardHead h3 {
        font-size: 16px;
    }

    .AboutUs .valueStandby .valuestandCard {
        padding: 54px 23px 12px 23px;
    }

    .AboutUs .valueStandby .valuestandCard .cardHead p {
        font-size: 12px;
    }
}

@media (min-width:486px) and (max-width:767px) {
    .AboutUs .valueStandbyHead {
        padding: 20px 0px;
    }

    .AboutUs .valueStandby .valueStandbyHead h3 {
        font-size: 26px;
        margin-bottom: 12%;
    }

    .AboutUs .valueStandby .valuestandCard .cardHead h3 {
        font-size: 16px;
    }

    .AboutUs .valueStandby .valuestandCard {
        padding: 54px 23px 12px 23px;
    }

    .AboutUs .valueStandby .valuestandCard .cardHead p {
        font-size: 12px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .AboutUs .valueStandbyHead {
        padding: 20px 0px;
    }

    .AboutUs .valueStandby .valueStandbyHead h3 {
        font-size: 30px;
        margin-bottom: 6%;
    }

    .AboutUs .valueStandby .valuestandCard .cardHead h3 {
        font-size: 18px;
    }

    .AboutUs .valueStandby .valuestandCard {
        padding: 54px 23px 12px 23px;
    }

    .AboutUs .valueStandby .valuestandCard .cardHead p {
        font-size: 13px;
    }
}

@media screen and (min-width: 0px) and (max-width: 1200px) {
    .AboutUs .valueStandby .valuestandCard {
        margin-bottom: 13%;
        height: 198px;
    }

    /* .AboutUs .valueStandby .valueStandbyHead h3 {
        font-size: 20px;
        margin-bottom: 12%;
    } */
}