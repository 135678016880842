.AboutUs .AboutusMain .aboutushead h3 {
  padding-bottom: 25px;


  color: #1e1e1e;
  font-family: "Inter", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 60.6px;
  letter-spacing: -1.38px;
}

.AboutUs .AboutusMain {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px 0px 50px 50px;
  padding: 2%;
  margin-bottom: 6%;
}

.AboutUs .AboutusMain .aboutushead span {
  display: block;
  font-size: 23px;
  color: #4b4b4b;
  font-weight: 400;
  font-family: "Gothammedium";
}

/* .AboutUs .AboutusMain .aboutushead span {
  display: block;
  font-family: "Gothammedium";
  font-size: 33px;
} */

.AboutUs .AboutusMain .spanfont {
  font-family: GothamBold;
  font-size: 21px;
}

.AboutUs .AboutusMain .aboutuscard {
  background: #d9e6ff 0% 0% no-repeat padding-box;
  border-radius: 11px;
  width: 100%;
  padding: 50px 10px 35px 10px;
}

.AboutUs .AboutusMain .middleaboutuscard {
  background-color: #a2c2ff;
}

.AboutUs .AboutusMain .aboutuscard .cardContent h3 {
  font-size: 33px;
  font-weight: bold;
  font-family: "GothamBold";
  margin-bottom: 15px;
}

.AboutUs .AboutusMain .aboutuscard .cardContent h4 {
  font-size: 20px;
  font-family: "Gothammedium";
}

.AboutUs .AboutusMain .aboutuscard .cardContent p {
  font-size: 16px;
  margin-bottom: 0;
  font-family: "Gothamregular";
}

.AboutUs .AboutusMain .paraInfo p {
  font-size: 20px;
  margin-bottom: 0;
  margin-top: 25px;
  text-align: center;
  font-family: "Gothamregular";
}

.AboutUs .whoweAre {
  margin-top: 3%;
  margin-bottom: 5%;
}

.AboutUs .whoweAre .whoweareHEad h3 {
  font-size: 48px;
  font-weight: bold;
  font-family: "GothamBold";
  color: #2e2e2e;
  padding-bottom: 20px;
}

.AboutUs .whoweAre .whoweareInfo p {
  font-size: 16px !important;
  line-height: 30px;
  font-family: "Gothamregular";
  color: #222222;
}

.AboutUs .whoweAre .rightsecImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 1/0.3;
}

.AboutUs .rightsecImage {
  position: relative;
  margin-bottom: 30px;
}

.AboutUs .overlayImg {
  position: absolute;
  background: #234c9e 0% 0% no-repeat padding-box;
  mix-blend-mode: hard-light;
  top: 0px;
  right: 0px;
  width: 168px;
  height: 100%;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .AboutUs .AboutusMain .aboutuscard {
    padding: 35px 10px 21px 10px;
  }

  .AboutUs .AboutusMain .aboutuscard .cardContent p {
    font-size: 12px;
  }

  .AboutUs .AboutusMain .aboutushead h3 {
    font-size: 24px;
    margin-top: 6px;
  }

  .AboutUs .AboutusMain .aboutushead span {
    font-size: 17px;
  }

  .AboutUs .AboutusMain .aboutuscard {
    margin-bottom: 18px;
  }

  .AboutUs .AboutusMain .aboutuscard .cardContent h3 {
    font-size: 25px;
  }

  .AboutUs .AboutusMain .aboutuscard .cardContent h4 {
    font-size: 16px;
  }

  .AboutUs .AboutusMain .paraInfo p {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .AboutUs .whoweAre .whoweareHEad h3 {
    font-size: 24px;
  }

  .AboutUs .whoweAre .whoweareInfo p {
    font-size: 13px;
    line-height: 21px;
  }

  .AboutUs .whoweAre .rightsecImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .AboutUs .overlayImg {
    top: -5px;
    right: 0px;
    width: 60px;
    height: 103%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .AboutUs .AboutusMain .aboutuscard {
    padding: 35px 10px 21px 10px;
  }

  .AboutUs .AboutusMain .aboutushead h3 {
    font-size: 35px;
  }

  .AboutUs .AboutusMain .aboutuscard .cardContent p {
    font-size: 13px;
  }

  .AboutUs .overlayImg {
    top: -7px;
    right: 0px;
    width: 134px;
    height: 104%;
  }

  .AboutUs .AboutusMain .aboutushead span {
    font-size: 25px;
  }

  .AboutUs .AboutusMain .aboutuscard {
    margin-bottom: 18px;
  }

  .AboutUs .AboutusMain .aboutuscard .cardContent h3 {
    font-size: 25px;
  }

  .AboutUs .AboutusMain .aboutuscard .cardContent h4 {
    font-size: 17px;
  }

  .AboutUs .AboutusMain .paraInfo p {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .AboutUs .whoweAre .whoweareHEad h3 {
    font-size: 30px;
  }

  .AboutUs .whoweAre .whoweareInfo p {
    font-size: 14px;
    line-height: 23px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 2560px) {
  .AboutUs .AboutusMain .aboutuscard .cardContent p {
    font-size: 16px;
  }

  .AboutUs .AboutusMain .aboutushead h3 {
    font-size: 60px;
  }

  .AboutUs .AboutusMain .aboutushead span {
    font-size: 21px;
}

  .AboutUs .AboutusMain .aboutuscard {
    margin-bottom: 18px;
  }

  .AboutUs .AboutusMain .aboutuscard .cardContent h3 {
    font-size: 32px;
  }

  .AboutUs .AboutusMain .aboutuscard .cardContent h4 {
    font-size: 18px;
  }

  .AboutUs .AboutusMain .paraInfo p {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .AboutUs .whoweAre .whoweareHEad h3 {
    font-size: 48px;
  }

  .AboutUs .whoweAre .whoweareInfo p {
    font-size: 14px;
    line-height: 25px;
  }

  .AboutUs .whoweAre .rightsecImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .AboutUs .overlayImg {
    top: -7px;
    right: 0px;
    width: 134px;
    height: 104%;
  }
}
