.OurLatestReward {
    margin: 5%;
}



.OurLatestReward .RewardBackimg img {
    width: 100%;
    object-fit: contain;
}

.latestRewardHead h3 {
    font-size: 33px;
    text-align: center;
    margin-bottom: 3%;
    font-family: 'Gothamregular';

}

.OurLatestReward .RewardBackimg {
    position: relative;
    margin-bottom: 30px;
}

.RewardBackimg .rewardOntxt {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    color: #fff;
    font-weight: 600;
    text-align: center;
}

.latestRewardHead .awardd {
    font-family: 'GothamBold';
}

.OurLatestReward .rewardOntxt p:nth-of-type(1) {
    margin-bottom: 0;
}

@media screen and (min-width: 0px) and (max-width: 485px) {
    .latestRewardHead h3 {
        margin-bottom: 6%;
        font-size: 24px;
    }

    .RewardBackimg .rewardOntxt p {
        font-size: 11px;
        margin-bottom: 0;
    }

    .RewardBackimg .rewardOntxt {
        font-size: 16px;
    }

    .OurLatestReward .RewardBackimg {
        margin-bottom: 8%;
    }

}

@media screen and (min-width: 486px) and (max-width: 767px) {
    .latestRewardHead h3 {
        margin-bottom: 6%;
        font-size: 26px;
    }

    .RewardBackimg .rewardOntxt p {
        font-size: 17px;
        margin-bottom: 0;
    }


    .RewardBackimg .rewardOntxt {
        font-size: 16px;
    }

    .OurLatestReward .RewardBackimg {
        margin-bottom: 8%;
    }

}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .latestRewardHead h3 {
        margin-bottom: 6%;
        font-size: 28px;
    }

    .RewardBackimg .rewardOntxt p {
        font-size: 9px;
        margin-bottom: 0;
    }


    .RewardBackimg .rewardOntxt {
        font-size: 16px;
    }

    .OurLatestReward .RewardBackimg {
        margin-bottom: 8%;
    }

}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
    .RewardBackimg .rewardOntxt {
        font-size: 16px;
    }
}