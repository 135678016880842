.saving-offer {
  margin: 3% 0;
}

.saving-offer .main-div {
  position: relative;
}

.saving-offer .main-div .image-holder .bann-image {
  height: auto;
  width: 100%;
  aspect-ratio: 1/0.3;
}

.saving-offer .main-div .text-holder {
  position: absolute;
  right: 36px;
  top: 27%;
  z-index: 1;
}

.saving-offer .main-div .text-holder h4 {
  font-size: 35px;
  color: #fff;
}

.saving-offer .main-div .text-holder h3 {
  font-size: 55px;
  font-weight: bold;
  color: #fff;
}

.saving-offer .main-div .overlay-color {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: transparent
    linear-gradient(90deg, #00000000 0%, #14193eb5 64%, #161b44 100%) 0% 0%
    no-repeat padding-box;
}
@media (min-width: 0px) and (max-width: 486px) {
  .saving-offer .main-div .text-holder h3 {
    font-size: 15px;
  }

  .saving-offer .main-div .text-holder h4 {
    font-size: 12px;
  }
  .saving-offer .main-div .text-holder {
    position: absolute;
    right: 36px;
    top: 20%;
    z-index: 1;
  }
}
@media (min-width: 486px) and (max-width: 767px) {
  .saving-offer .main-div .text-holder h3 {
    font-size: 25px;
  }

  .saving-offer .main-div .text-holder h4 {
    font-size: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .saving-offer .main-div .text-holder h3 {
    font-size: 30px;
  }

  .saving-offer .main-div .text-holder h4 {
    font-size: 25px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .saving-offer .main-div .text-holder h3 {
    font-size: 38px;
  }

  .saving-offer .main-div .text-holder h4 {
    font-size: 28px;
  }
}
