.AllCategory {
    margin: 3%;
}

.AllCategory .categoryHead h3 {
    text-align: center;
    margin-bottom: 3%;
    font-family: "Gothammedium";
    font-weight: 300;
    color: #1e1e1e;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: 71px;
    letter-spacing: -1.32px;
    padding-bottom: 25px;
}

.AllCategory .categoryCard {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #00000029;
    border: 0;
    padding: 11px 0px 19px 33px;
    position: relative;
    margin-bottom: 3%;
}

.AllCategory .categoryCard  .card-img-top {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
}



.AllCategory .categoryCard h3 {
    font-size: 20px;
    margin-bottom: 19px;
    margin-top: 15px;
    font-family: 'Gothammedium';
}

.AllCategory .categoryCard .appreciationCard {
    background: #EEEEEE 0% 0% no-repeat padding-box;
    border-radius: 10px;
    border: 0;
}

.AllCategory .categoryCard .appreciationCard .card-title {
    font-size: 13px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 5;
    text-align: justify;
    font-family: 'Gothammedium';
}

.AllCategory .categoryCard .card-text:last-child {
    font-size: 13px;
    font-family: 'Gothamregular';
    font-weight: bold;
}

.AllCategory .categoryCard .viewMore {
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(0%, -50%);
    height: 100px;
    width: 54px;
    border-radius: 150px 0px 0px 150px;
    background-color: #2E2E2E;
    border-left: 8px solid #707070;
    padding: 0px;
    border-top: 8px solid #707070;
    border-bottom: 8px solid #707070;
}

.AllCategory .categoryCard .viewMore p {
    color: #fff;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 25px;
    margin-left: 10px;
    font-family: 'Gothammedium';
}

.AllCategory .categoryCard .appreciationMain{
    width: 92%;
}

.AllCategory .categoryDesktop {
    display: block;
}

.AllCategory .categoryMobile {
    display: none;
}

.AllCategory .categoryMobile .mobiletabs .nav-pills .nav-link.active {
    color: #fff !important;
}

.AllCategory .categoryMobile .mobiletabs .nav-pills .nav-link {
    color: #000;
    font-size: 13px;
    text-wrap: nowrap;
}

.AllCategory .categoryMobile .mobiletabs .nav-pills {
    display: flex;
    overflow: auto;
    flex-wrap: nowrap;
    margin-bottom: 6%;
    padding-bottom: 2%;
}

@media screen and (min-width: 0px) and (max-width: 768px) {

    .all-category-home-mobile .category-div .text-holder p {
        font-size: 14px;
    }

    .AllCategory .categoryHead h3 {
        font-size: 20px;
    }

    .AllCategory .categoryCard h3 {
        font-size: 15px;
        margin-bottom: 19px;
        margin-top: 15px;
    }

    .AllCategory .categoryCard {
        padding: 11px 10px 19px 10px;
        margin-bottom: 20px;
    }

    .AllCategory .categoryCard .viewMore {
        display: none;
    }

    .AllCategory .categoryCard .appreciationMain {
        width: 100%;
    }



    .AllCategory .categoryCard .appreciationCard {
        margin-bottom: 15px;
    }

    .AllCategory .categoryDesktop {
        display: none;
    }

    .AllCategory .categoryMobile {
        display: block;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {

    .AllCategory .categoryCard .appreciationMain {
        width: 100%;
    }

    .AllCategory .categoryCard .viewMore {
        display: none;
    }

    .AllCategory .categoryCard {
        padding: 11px 20px 19px 20px;
    }

    .AllCategory .categoryCard h3 {
        font-size: 18px;
    }

    .AllCategory .categoryHead h3 {
        font-size: 32px;
    }
}