.Careers {
    background: transparent linear-gradient(180deg, #4b6393 0%, #2857B7D1 28%, #5C719C59 75%, #A5B1C831 89%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
    position: relative;
    margin-bottom: 4%;
}

.Careers .noVacancyImg img {
    /* width: 79%; */
    width: 664px;
    height: 183px;
    margin-top: 6%;
    object-fit: contain;
}

.Careers .noVacancyImg {
    text-align: center;
}

.Careers .noVacancyImg p {
    color: #000000;
    text-transform: uppercase;
    opacity: 0.5;
    font-size: 15px;
}

.Careers .wearesrytxt p {
    margin-bottom: 0;
    font-size: 13px;
}

.Careers .wearesrytxt {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 11px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 11px 25px;
    position: relative;
}

.Careers .weRaesrymain {
    position: absolute;
    left: 46%;
    top: 35%;
}

.Careers .pathBelow img {
    width: 20px;
    height: 50px;
    position: absolute;
    right: 23px;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .Careers .noVacancyImg img {
        width: 100%;
    }

    .Careers .wearesrytxt {
        padding: 11px 12px;
    }

    .Careers .wearesrytxt p {
        margin-bottom: 0;
        font-size: 11px;
    }

    .Careers .pathBelow img {
        height: 33px;
    }


}

@media screen and (min-width: 0px) and (max-width: 485px) {
    .Careers .weRaesrymain {
        left: 28%;
        top: 23%;
    }

    .Careers .careerHead h3 {
        margin-bottom: 6%;
        font-size: 23px;
        margin-top: 3%;
    }
}

@media screen and (min-width: 486px) and (max-width: 768px) {
    .Careers .weRaesrymain {
        left: 54%;
        top: 27%;
    }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
    .Careers .weRaesrymain {
        left: 47%;
        top: 27%;
    }

    .Careers .careerHead h3 {
        font-size: 35px;
    }
}