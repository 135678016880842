.GiftCustomization {
  background: transparent linear-gradient(180deg, #ffffff00 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
}

.GiftCustomization .customiseBanner {
  margin-bottom: 5%;
}

.GiftCustomization .imgMain img {
  width: 100%;
  margin-bottom: 5%;
}

.GiftCustomization .imgMain {
  position: relative;
}

.GiftCustomization .breadcrumsec {
  position: absolute;
  top: 32px;
  left: 13%;
}

.GiftCustomization .customiseBanner .onbannertxt .customisetxt p {
  color: #222222;
  text-transform: capitalize;
  font-size: 45px;
  margin-bottom: 0;
}

.GiftCustomization .customiseBanner .onbannertxt p {
  text-align: center;
  font-size: 18px;
}

.GiftCustomization
  .customiseBanner
  .onbannertxt
  .customisetxt
  p:nth-of-type(2) {
  font-weight: 600;
  margin-bottom: 15px;
}

.GiftCustomization .customiseBanner .onbannertxt .customisetxt {
  text-align: center;
}

.GiftCustomization .customiseBanner .bannerImg {
  width: 100%;
  object-fit: contain;
  height: 300px;
  opacity: 0.5;
}

.GiftCustomization .customiseBanner .onbannertxt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
}

/* customise working */
.GiftCustomization .customizingWorking {
  margin-bottom: 5%;
}

.GiftCustomization .customizingWorking .customisehead h3 {
  font-size: 45px;
  margin-bottom: 25px;
}

.GiftCustomization .workingImg {
  width: 100%;
  object-fit: contain;
  height: 250px;
}

.GiftCustomization .custImgmain {
  margin-bottom: 15px;
  position: relative;
}

.GiftCustomization .custImgmain .rightSideimg {
  width: 100%;
  margin-bottom: 0;
}

.GiftCustomization .custImgmain .custnumberTxt {
  position: absolute;
  top: 35%;
  right: 0px;
  left: 0px;
  transform: translate(12%, 0%);
}


.GiftCustomization .custImgmain .custnumberTxt h3 {
  font-weight: 900;
  font-size: 49px;
  color: #000;
  width: fit-content;
}


.GiftCustomization .custImgmain .custImgTxt {
  position: absolute;
  top: 44%;
  left: 35%;
  transform: translate(0%, -50%);
}

.GiftCustomization .custImgmain .custImgTxt p {
  font-size: 18px;
  margin-bottom: 0;
}

.GiftCustomization .custImgmain .custImgTxt h3 {
  font-size: 18px;
  font-weight: 600;
}

.GiftCustomization .formMain {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 5%;
}

.GiftCustomization .formMain .uploadDiv {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 12px 6px;
  font-size: 14px;
}

.GiftCustomization .formMain .uploadDiv p {
  color: #707070;
  font-size: 13px;
}

.GiftCustomization .formMain .custoHead h3 {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
}

.GiftCustomization .formMain .Addproduct .fggggg p {
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: 600;
}

.GiftCustomization .formMain .checkLabel {
  margin-top: 2%;
}

.GiftCustomization .formMain .checkLabel p {
  font-size: 14px;
}

.GiftCustomization .formMain textarea.form-control {
  height: 10px;
}

.GiftCustomization .formMain .form-label {
  font-size: 14px;
  font-weight: 900;
  color: #000;
}

.GiftCustomization .formMain .Addproduct {
  margin-top: 15px;
}

.GiftCustomization .Samplegoods .sampleHead h3 {
  font-size: 45px;
  text-align: center;
  margin-bottom: 5%;
  margin-top: 10%;
}

.GiftCustomization .Samplegoods {
  /* margin: 3%; */
  margin-bottom: 7%;
}

.GiftCustomization .sampleHeadinginfo h3 {
  font-size: 19px;
  margin-bottom: 5px;
  font-weight: 600;
}

.GiftCustomization .sampleHeadinginfo p {
  font-size: 14px;
  margin-bottom: 13px;
}

.GiftCustomization .sampleRowMain .samplecard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 0px 6px #00000029, 0px 0px 6px #00000029;
  border-radius: 15px;
  height: 280px;
  position: relative;
  border: 0;
}

.GiftCustomization .sampleRowMain .sampleHeadinginfo {
  padding: 3% 7%;
  margin-top: 31px;
}

.Samplegoods .sampleRowMain .cardtxt h3 {
  font-size: 13px;
  margin-bottom: 0;
}

.Samplegoods .sampleRowMain .cardtxt p {
  font-size: 13px;
}

.Samplegoods .sampleRowMain .cardtxt .clockImg {
  width: 11px;
  height: 11px;
  margin-right: 8px;
  margin-top: 1px;
}

.Samplegoods .sampleRowMain .downside {
  background: #efefef 0% 0% no-repeat padding-box;
  border-radius: 15px;
  padding: 17px 15px;
  /* height: 153px; */
  position: absolute;
  bottom: 0;
  width: 100%;
}

.Samplegoods .sampleRowMain .iconCircle img {
  height: 26px;
  object-fit: contain;
}

.Samplegoods .sampleRowMain .iconCircle {
  width: 56px;
  height: 56px;
  background: #e9f1ff 0% 0% no-repeat padding-box;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -10%;
  transform: translate(-50%, 0%);
  left: 50%;
}

.GiftCustomization .form-control {
  border-left: 0 !important;
  border-right: 0;
  border-top: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: 14px;
}

.GiftCustomization .submitbtn {
  background-color: #234c9e;
  color: #fff;
  font-size: 14px;
  margin-top: 2%;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .GiftCustomization .customizingWorking .customisehead h3 {
    font-size: 20px;
  }

  .GiftCustomization .custImgmain .custnumberTxt h3 {
    font-size: 28px;
}

  .GiftCustomization .custImgmain .custImgTxt h3 {
    font-size: 14px;
    margin-bottom: 0;
  }

  .GiftCustomization .breadcrumsec {
    top: 8px;
    left: 3%;
  }

  .GiftCustomization .custImgmain .custImgTxt p {
    font-size: 14px;
  }

  .GiftCustomization .Samplegoods .sampleHead h3 {
    font-size: 20px;
    margin-bottom: 14%;
    margin-top: 8%;
  }

  .GiftCustomization .sampleRowMain .samplecard {
    margin-bottom: 12%;
  }

  .GiftCustomization .formMain .custoHead h3 {
    font-size: 20px;
  }

  .GiftCustomization .custImgmain .custImgTxt p {
    font-size: 11px;
  }

  .GiftCustomization .custImgmain .custImgTxt h3 {
    font-size: 12px;
  }

  .GiftCustomization .custImgmain .custImgTxt {
    top: 49%;
  }

  .GiftCustomization .workingImg {
    height: 150px;
    margin-bottom: 16% !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .GiftCustomization .customizingWorking .customisehead h3 {
    font-size: 20px;
  }

  .GiftCustomization .custImgmain .custnumberTxt h3 {
    font-size: 28px;
}

  .GiftCustomization .custImgmain .custImgTxt h3 {
    font-size: 14px;
    margin-bottom: 0;
  }

  .GiftCustomization .custImgmain .custImgTxt p {
    font-size: 14px;
  }

  .GiftCustomization .Samplegoods .sampleHead h3 {
    font-size: 20px;
    margin-bottom: 7%;
    margin-top: 8%;
  }

  .GiftCustomization .sampleRowMain .samplecard {
    margin-bottom: 12%;
  }

  .GiftCustomization .formMain .custoHead h3 {
    font-size: 20px;
  }

  .GiftCustomization .custImgmain .custImgTxt p {
    font-size: 11px;
  }

  .GiftCustomization .custImgmain .custImgTxt h3 {
    font-size: 12px;
  }

  .GiftCustomization .custImgmain .custImgTxt {
    top: 49%;
  }

  .GiftCustomization .workingImg {
    height: 200px;
    margin-bottom: 16% !important;
  }

  .Needhelpheading h3 {
    padding: 3% 0%;
    font-size: 25px;
  }
}
