.all-category-home {
  margin: 3% 0;
}

.all-category-home .category-div {
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  border-radius: 10px;
  background: #ccc;
}
.all-category-home .category-div .image-holder {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.all-category-home .category-div .image-holder, .all-category-home .category-div .image-holder .category-image {
  aspect-ratio: 1/1;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  background: #ccc;
}

.all-category-home .category-div .second-div {
  aspect-ratio: 1/1.4;
}

.all-category-home .category-div .second-div.image-2 {
  aspect-ratio: 1/1.3 !important;
}

.all-category-home .category-div .second-div .image-holder {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  aspect-ratio: 1/1.4;
  object-fit: contain;
}

.all-category-home .category-div .second-div .image-holder .category-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  aspect-ratio: 1/1.4;
  object-fit: contain;
}

.all-category-home .category-div:hover .image-holder .category-image {
  transform: scale(1.1);
  transition: 0.3s;
  border-radius: 10px;
}

.all-category-home .category-div .overlay-color {
  background: transparent linear-gradient(180deg, #00000000, #000000f5) 0 0 no-repeat padding-box;
  border-radius: 10px;
  bottom: 0;
  height: 36%;
  left: 0;
  position: absolute;
  right: 0;
  /* top: 0; */
  width: 100%;
}

.all-category-home .category-div .text-holder {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  text-align: center;
  width: 100%;
}

.all-category-home .category-div .text-holder p {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

.all-category-home .cat-seecc-first {
  margin-top: 80%;
}

.all-category-home .cat-seecc-second {
  margin-top: 42%;
}

.all-category-home-mobile {
  display: none;
}

/* MobileView start */
.all-category-home-mobile {
  margin: 3% 0;
}

.all-category-home-mobile .category-div {
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  border-radius: 10px;
}

.all-category-home-mobile .category-div .image-holder {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  /* aspect-ratio: 1/1; */
}

.all-category-home-mobile .category-div .image-holder .category-image {
  width: 100%;
  border-radius: 10px;
  /* aspect-ratio: 1/1; */
}

.all-category-home-mobile .category-div .image-2 {
  aspect-ratio: 1/1.3 !important;
}

.all-category-home-mobile .category-div:hover .image-holder .category-image {
  transform: scale(1.1);
  transition: 0.3s;
  border-radius: 10px;
}

.all-category-home-mobile .category-div .overlay-color {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.all-category-home-mobile .category-div .text-holder {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  text-align: center;
  width: 100%;
}

.all-category-home-mobile .category-div .text-holder p {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

.all-category-home-mobile {
  display: none;
}

/* MobileView end */

@media screen and (min-width: 0px) and (max-width: 767.98px) {
  .all-category-home {
    display: none;
  }

  .all-category-home-mobile {
    display: block;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .all-category-home .category-div .text-holder p {
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .all-category-home .category-div .text-holder p {
    font-size: 10px;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .all-category-home .cat-seecc-first {
    margin-top: 10%;
  }

  .all-category-home .cat-seecc-second {
    margin-top: 5%;
  }

  .all-category-home .category-div {
    margin-top: 13px;
  }

  .all-category-home .category-div .text-holder p {
    font-size: 13px;
  }
}
