.Madetoorder {
  background-image: url("../../../public/assest/image/Made_to_Order/madetoOrderbanner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 3%;
  height: 500px;
  margin-bottom: 21%;
  position: relative;
}

.main-form-holder {
  margin: 20px 0px;
  height: 500px;
  overflow: hidden;
  overflow-y: scroll;
}

.Madetoorder .Onbannerform {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 35px;
}

.Madetoorder .Onbannerform .sendbtn {
  background: #040404 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 0;
  font-size: 14px;
}

.Madetoorder .MadetoOrderhead h3 {
  font: normal normal normal 50px/24px Gotham;
  color: #ffffff;
  margin-bottom: 7%;
  margin-top: 3%;
  font-family: "Gothammedium";
}

.Madetoorder .Onbannerform .form-control {
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: 14px;
}

.Madetoorder .Onbannerform .uploadDiv p {
  font-size: 12px;
  margin-bottom: 0;
  color: #707070;
}

.Madetoorder .Onbannerform .uploadDiv {
  font-size: 14px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
}

.Madetoorder .requestarea {
  height: 30px;
}

.Madetoorder .Onbannerform .uploaddesign {
  font-size: 13px;
}

.Madetoorder .Onbannerform .uploaddesign p:nth-of-type(2) {
  font-size: 12px;
  color: #707070;
}

/* .Madetoorder .customCheck .uploaded-img-holder {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  width: 100%;
} */

.Madetoorder .customCheck .uploaded-img-holder .file-img-holder {
  margin-right: 5px;
  display: inline-flex;
}

.Madetoorder .madeOrderimg img {
  width: 365px;
  height: 100%;
  object-fit: contain;
}

.Madetoorder .madeOrderimg {
  text-align: end;
}

.uploadDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 0px) and (max-width: 768px) {

  .Madetoorder .Onbannerform .form-control {
    font-size: 13px;
  }

  .Madetoorder .Onbannerform .css-1jqq78o-placeholder {
    font-size: 13px;
  }

 .css-10wo9uf-option {
    font-size: 13px !important;
  }

  .css-1dimb5e-singleValue {
    font-size: 13px !important;
  }

  .css-tr4s17-option {
    font-size: 13px !important;
  }

  .Madetoorder .MadetoOrderhead h3 {
    font: normal normal normal 25px/23px Gotham;
    color: #ffffff;
    margin-bottom: 7%;
    font-size: 25px;
  }

  .Madetoorder {
    height: 100%;
    margin-bottom: 5%;
  }

  .Madetoorder .madeOrderimg img {
    display: none;
  }

  .Madetoorder .Onbannerform {
    padding: 15px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .Madetoorder .MadetoOrderhead h3 {
    font: normal normal normal 25px/23px Gotham;
    color: #ffffff;
    margin-bottom: 7%;
    font-size: 35px;
  }

  .Madetoorder .madeOrderimg img {
    width: 100%;
    height: 166px;
    margin-left: 34px;
  }

  .Madetoorder {
    margin-bottom: 35%;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1440px) {
  .Madetoorder {
    margin-bottom: 26%;
  }

  .Madetoorder .madeOrderimg img {
    margin-right: 89px;
  }

  .Madetoorder .main-form-holder {
    height: 380px;
  }
}


@media screen and (min-width: 1441px) and (max-width: 2000px) {

  .Madetoorder .main-form-holder {
    height: 380px;
  }

  .Madetoorder {
    margin-bottom: 28%;
  }
}



@media screen and (min-width: 2200px) and (max-width: 2560px) {
  .Madetoorder .madeOrderimg img {
    margin-right: 89px;
  }


}