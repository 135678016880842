/* .Profile {
    background: url("../../../public/assest/image/Profile/profilebanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 207px;
    position: relative;
    margin-bottom: 15%;
} */
.Profile .prof-top-bannimg {
    width: 100%;
}

.Profile .profilecontainer {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    margin-bottom: 24%;
}

.Profile .editsec p {
    text-align: end;
    letter-spacing: 0.38px;
    color: #1D44AD;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0;
    cursor: pointer;
}

.top-contt {
    background-color: #fff;
    top: 0px;
    width: 100%;
    position: absolute;
    top: -263px;
    overflow-wrap: break-word;
    height: 262px;
}

.Profile .profilecontainer p {
    padding: 49px 15px 0px 15px;
    margin-bottom: 0;
    font-size: 20px;
    font-style: italic;
}

.Profile .top-contt h1 {
    padding: 5px 5px 0px 12px;
    font-size: 35px;
    margin-bottom: 0;
}

.Profile .details li {
    /* margin-bottom: 13px; */
    /* padding-left: 17px; */
    font-size: 15px;
    cursor: pointer;
    padding: 6px 15px 6px 15px;
}

.Profile .details li:hover {
    background: #234C9E 0% 0% no-repeat padding-box;
    padding-left: 17px;
    color: #fff;
}

.Profile .details {
    list-style-type: none;
    margin-bottom: 15px;
    padding-left: 0;
}

.Profile .content {
    position: absolute;
    bottom: -41%;
}

.Profile .personalDetails .detailshead {
    font-weight: 600;
    font-size: 16px;
}

.Profile .editbtn {
    background: #133A88 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: #fff;
    padding: 5px 25px;
    font-size: 14px;
    border: 0;
}

.Profile .nav-pills .nav-link {
    color: #000;
    font-size: 14px;
}

.Profile .nav-pills .nav-link.active {
    background: #234C9E 0% 0% no-repeat padding-box;
    border-radius: 0;
    color: #fff !important;
}

.Profile .loyalitypoint .loyalityhead h3 {
    font-size: 25px;
    margin-top: 14px;
}

.Profile .loyalitypoint .loyalityhead p {
    font-size: 14px;
}

.Profile .loyalitypoint .loyalitycard {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000029;
    border-radius: 10px;
    border: 0;
}

.Profile .loyalitypoint .loyalitycard .mittalImage {
    width: 200px;
    height: auto;
}

.Profile .loyalitypoint .clip {
    background: #000;
    width: 100%;
    clip-path: ellipse(100% 85% at 50% 0%);
    text-align: center;
    padding: 23px;
    border-radius: 10px;
}

.Profile .progress {
    margin: 5px 15px;
}

.Profile .loyalitycard .loyalitycardbody {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.Profile .tab-content {
    margin: 19px 5px 37px 5px;
}

.Profile .ManageAddress .manageaddresscard {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    margin-left: auto;
    margin-bottom: 17px;
}

.Profile .ManageAddress .manageaddresscard .loyalimgsection {
    position: relative;
}

.Profile .ManageAddress .manageaddresscard .loyalimgsection img {
    width: 100%;
}

.Profile .ManageAddress .manageaddresscard .loyalimgsection .locationdot {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
}

.Profile .ManageAddress .manageaddresscard .loyaladdress h3 {
    font-size: 16px;
    font-weight: 600;
}

.Profile .ManageAddress .manageaddresscard .loyaladdress p {
    font-size: 13px;
    margin-bottom: 0;
}

.Profile .ManageAddress .manageaddresscard .loyaladdress span {
    font-size: 12px;
    color: #323232;
}

.Profile .ManageAddress .addAddressbtn .addNewaddress {
    background: #1D44AD 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 15px;
}

.Profile .ManageAddress .addAddressbtn .deleteaddress {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    font-size: 14px;
    margin-right: 15px;
    color: #202020;
}

.Profile .changeEmail .form-label {
    margin-bottom: .5rem;
    font-size: 14px;
}

.Profile .changeEmail .updatebtn {
    background: #234C9E 0% 0% no-repeat padding-box;
    font-size: 14px;
    border: 0;
}

.Profile .changeEmail .form-control {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    font-size: 14px;
}

.Profile .changeEmail .formfieldMain {
    position: relative;
}

.Profile .changeEmail .formfieldMain .eyeIcon {
    position: absolute;
    top: 12px;
    right: 5px;
}

.Profile .progress .progress-bar {
    background-color: #234C9E;
}

.Profile .loyalitycardmain .loyalitycard .invalidtxt {
    margin-bottom: 0;
    font-size: 11px;
    text-align: center;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
}

.Profile .loyalitycardmain .loyalitycard .shopnow:hover {
    color: #173F8F;
}

.Profile .loyalitycardmain .loyalitycard .Invalidtxt:hover {
    color: red;
}

.Profile .loyalitycardmain .loyalityrow {
    border-bottom: 1px solid #ccc;
    padding-bottom: 36px;
    margin-bottom: 26px;
}

.Profile .loyalitycardmain .recentTransaction h3 {
    font-size: 21px;
    font-weight: 600;
}

.Profile .loyalitycardmain .recentTransaction p {
    font-size: 14px;
    color: #707070;
}

.Profile .nav-link.active .personalicon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    filter: brightness(0) invert(1);
    object-fit: contain;
}

.Profile .nav-link .personalicon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    object-fit: contain;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .top-contt {
        position: unset;
        height: 170px;
    }

    .Profile .ManageAddress .manageaddresscard {
        margin-left: auto;
        margin-right: auto;
    }

    .Profile .profilecontainer p {
        padding: 20px 15px 0px 15px;
        font-size: 17px;
    }

    .Profile .profilecontainer {
        margin-bottom: 7%;
    }

    .Profile .top-contt h1 {
        padding: 5px 5px 0px 12px;
        font-size: 28px;
    }

    .Profile .loyalitypoint .loyalitycard {
        margin-bottom: 22px;
    }

    .Profile .loyalitypoint .loyalityhead h3 {
        font-size: 20px;
    }

    .Profile .loyalitypoint .loyalityhead p {
        font-size: 13px;
    }

    .Profile .addAddressbtn {
        display: flex;
        justify-content: center;
    }

    .Profile .ManageAddress .addAddressbtn .addNewaddress {
        font-size: 13px;
        margin-right: 9px;
        padding: 0 13px;
    }

    .Profile .loyalitypoint .loyalitycard .mittalImage {
        width: 167px;
        height: auto;
    }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
    .Profile .top-contt {
        position: unset;
        height: 116px;
        top: -116px;
    }

    .Profile .profilecontainer p {
        padding: 16px 15px 0px 15px;
        font-size: 15px;
    }

    .Profile .top-contt h1 {
        padding: 5px 5px 0px 12px;
        font-size: 20px;
    }

    .Profile .profilecontainer {
        margin-bottom: 6%;
    }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
    .Profile .top-contt {
        position: absolute;
        height: 133px;
        top: -133px;
    }

    .Profile .loyalitypoint .loyalitycard {
        margin-bottom: 22px;
    }

    .Profile .profilecontainer p {
        padding: 16px 15px 0px 15px;
        font-size: 15px;
    }

    .Profile .top-contt h1 {
        padding: 5px 5px 0px 12px;
        font-size: 20px;
    }

    .Profile .profilecontainer {
        margin-bottom: 6%;
    }

    .Profile .loyalitypoint .loyalitycard .mittalImage {
        width: 167px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .Profile .top-contt {
        position: absolute;
        height: 155px;
        top: -155px;
    }

    .Profile .loyalitypoint .loyalitycard .mittalImage {
        width: 167px;
    }

    .Profile .loyalitypoint .loyalitycard {
        margin-bottom: 22px;
    }

    .Profile .profilecontainer p {
        padding: 16px 15px 0px 15px;
        font-size: 15px;
    }

    .Profile .top-contt h1 {
        padding: 5px 5px 0px 12px;
        font-size: 20px;
    }

    .Profile .profilecontainer {
        margin-bottom: 6%;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
    .top-contt {
        top: -214px;
        height: 214px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .top-contt {
        top: -219px;
        height: 219px;
    }
}