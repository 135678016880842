.editproflemodal .form-control {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    font-size: 14px;
    width: 100%;
}

.editproflemodal .btnMainsec {
    text-align: center;
    margin-top: 5%;
}

.react-tel-input .flag-dropdown {
    background-color: transparent;
    border: 0;
    border-radius: 0;
}

.editproflemodal .btnMainsec .updatebtn {
    background: #234C9E 0% 0% no-repeat padding-box;
    border-radius: 3px;
    font-size: 13px;
    margin-right: 15px;
    border: 0;
}



.editproflemodal .btnMainsec .savebtn {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #000000;
    border-radius: 3px;
    color: #000;
    font-size: 13px;
}

.editproflemodal .modal-footer {
    border-top: 0;
}