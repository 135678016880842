.CustomerLove {
  background: #d3dfff59 0% 0% no-repeat padding-box;
  margin-bottom: 3%;
  position: relative;
  padding: 40px 0px 0px 0px;
}

.CustomerLove .customer-love-slider {
  position: relative;
  top: 70px;
  margin-bottom: 0%;
}

.CustomerLove .customerHead h3 {
  /* padding-top: 5%; */
  padding-bottom: 15px;
  color: #1e1e1e;
  font-family: "Inter", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 60.6px;
  letter-spacing: -1.38px;
}

.CustomerLove .customerHead p {
  font-size: 16px;
  color: #000;
  text-transform: capitalize;
  font-family: 'Gothamregular';
  margin-bottom: -30px;
}

.CustomerLove .customercard {
  border: 0;
  border-radius: 15px;
  box-shadow: 0 3px 6px #00000029;
  height: 240px;
}

.CustomerLove .customercard .cardContent h3 {
  font-size: 25px;
  font-weight: bold;
  font-family: 'GothamBold';
}

.CustomerLove .customercard .cardContent p {
  font-family: Gothamregular;
  font-size: 16px;
  font-weight: 400;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
}

.CustomerLove .customercard .imgMainsec img {
  width: 100%;
  border-radius: 50%;
}

.CustomerLove .swiper-slide {
  margin-bottom: 5%;
}

.CustomerLove .card-body {
  padding: 6% 3%;
}

@media screen and (min-width: 0px) and (max-width: 485px) {
  .CustomerLove .customerHead h3 {
    font-size: 25px;
  }

  .CustomerLove .customerHead p {
    font-size: 12px;
  }

  .CustomerLove .customercard .imgMainsec img {
    margin-bottom: 15px;
  }

  .CustomerLove .customercard .cardContent h3 {

    font-size: 20px;
  }

  .CustomerLove .customercard .cardContent p {
    font-size: 12px;
  }

  .CustomerLove .swiper-slide {
    margin-bottom: 13%;
  }

  .CustomerLove .customer-love-slider {
    top: 41px;
  }
}

@media screen and (min-width: 486px) and (max-width: 768px) {
  .CustomerLove .customerHead h3 {
    font-size: 35px;
  }

  .CustomerLove .customercard .imgMainsec img {
    margin-bottom: 15px;
  }

  .CustomerLove .customercard .cardContent h3 {

    font-size: 20px;
  }

  .CustomerLove .customercard .cardContent p {
    font-size: 13px;
  }

  .CustomerLove .swiper-slide {
    margin-bottom: 13%;
  }

  .CustomerLove .customer-love-slider {
    top: 41px;
  }

  .CustomerLove .customerHead p {
    font-size: 13px;
  }
}

/* @media screen and (min-width: 0px) and (max-width: 320px) {
  .CustomerLove .customercard .cardContent p {
    font-size: 11px;
  }
} */

@media screen and (min-width: 769px) and (max-width: 991px) {
  .CustomerLove .customer-love-slider {
    top: 63px;
  }

  .CustomerLove .customercard .cardContent h3 {
    font-size: 21px;
  }

  .CustomerLove .customercard .cardContent p {
    font-size: 12px;
  }


}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .CustomerLove .customer-love-slider {
    top: 64px;
  }

}