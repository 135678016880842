.Faq .FaqBannermain .bannerImg img {
    width: 100%;
}

.Faq .FaqBannermain .bannerImg {
    position: relative;
}

.Faq .FaqBannermain .onbannertxt p:nth-of-type(1) {
    font-size: 30px;
    font-weight: 900;
    color: #fff;
    margin-bottom: 0;
}

.Faq .FaqBannermain .onbannertxt p:nth-of-type(2) {
    font-size: 30px;
    color: #fff;
}

.Faq .FaqBannermain .bannerImg .onbannertxt {
    position: absolute;
    top: 50%;
    left: 3%;
    transform: translate(0%, -50%);
}

.Faq .faqTabs {
    margin-top: 15px;
}

.Faq .faqTabs .faqtabsNav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 18px;
}

.Faq .faqTabs .nav-pills .nav-link {
    background: transparent;
    border-radius: 3px;
    color: #000;
    border: 1px solid #000;
    font-size: 14px;
    padding: 5px 25px;
}

.Faq .faqTabs .nav-pills .nav-link.active {
    background: #234C9E 0% 0% no-repeat padding-box;
    border: 1px solid #234C9E;
    border-radius: 3px;
    color: #fff !important;
}

.Faq .faqTabs .faqtabsNav .nav-item {
    margin-right: 19px;
}

.Faq .faqTabs .accordionCard {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 0.95;
    border: 0;
    margin-bottom: 5%;
    margin-top: 3%;
}

.Faq .faqTabs .accordionCard .accordion-header .accordion-button {
    font-size: 15px;
    border-bottom: 1px solid #ccc;
    color: #000;
    font-weight: 600;
}

.Faq .faqTabs .accordionCard .accordion-item {
    border: 0;
}

.Faq .faqTabs .accordionCard .faqAccordhead p {
    font-size: 14px;
}

.Faq .faqTabs .accordionCard .accordion-button::after {
    height: 7px;
    background-image: url("../../../public/assest/image/Faq/trianfle.png") !important;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .Faq .faqTabs .faqtabsNav {
        overflow: auto;
        flex-wrap: nowrap;
        justify-content: left;
    }

    .Faq .faqTabs .nav-pills .nav-link {
        text-wrap: nowrap;
        font-size: 13px;
    }

    .Faq .faqTabs .faqtabsNav .nav-item {
        margin-bottom: 15px;
        margin-right: 11px;
    }

    .Faq .faqTabs {
        margin: 12px 0px;
    }

    .Faq .FaqBannermain .onbannertxt p:nth-of-type(1) {
        font-size: 16px;
        font-weight: 600;
    }

    .Faq .FaqBannermain .onbannertxt p:nth-of-type(2) {
        font-size: 16px;
        margin-bottom: 0;
    }
}