.we-express {
  margin: 3% 0;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  padding: 40px 0px 43px 0px;
}

.we-express .heading-holder h2 {
  /* font-size: 36px;
  font-weight: bold;
  font-family: "GothamBold"; */
}

.we-express .heading-holder p {
  font-size: 19px;
  font-family: "Gothamregular";
}

.we-express .swiper {
  padding-bottom: 0px;
}


@keyframes slide {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}


.we-express .swiper-wrapper {
  --gap: 60px;
  animation: slide 25s linear infinite;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: var(--gap, 60px);
}



.we-express .image-holder .company-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 1/1  ;
}

.we-express .main-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #eae8eb;
  padding: 10px;
  border-radius: 5px;
}

@media (min-width: 0px) and (max-width: 767px) {
  /* .we-express .heading-holder h2 {
    font-size: 18px;
  } */

  .we-express .heading-holder p {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* .we-express .heading-holder h2 {
    font-size: 20px;
  } */
}

@media (min-width: 992px) and (max-width: 1400px) {
  /* .we-express .heading-holder h2 {
    font-size: 20px;
  } */

  .we-express .swiper-wrapper {
    --gap: 60px;
    gap: var(--gap, 60px);
  
  }
}