.Returnmodal .order-return-form {
    border-radius: 0.5rem;
}

.Returnmodal .returnHead h3 {
    font: normal normal medium 23px/25px Gotham;
}

.Returnmodal .order-return-form h3 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #343a40;
    font-size: 23px;
    font-weight: bold;
}

.Returnmodal .order-return-form .form-group {
    margin-bottom: 1.5rem;
}


.Returnmodal .order-return-form .form-check {
    margin-bottom: 7px;
}


.Returnmodal .order-return-form .form-control {
    margin-bottom: 1rem;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    margin-top: 20px;
    height: 39px;
    font-size: 14px;
}

.Returnmodal .order-return-form .cancelbtn {
    background-color: transparent;
    color: #000;
    border-radius: 0;
    font-size: 14px;
}

.Returnmodal .order-return-form .submitbtn {
    background: #234C9E 0% 0% no-repeat padding-box;
    border-radius: 0;
    font-size: 14px;
}


.Returnmodal .order-return-form .btn-container {
    text-align: center;
}

.Returnmodal .order-return-form .btn-container .btn {
    margin-right: 0.5rem;
}

.Returnmodal .order-return-form .btn-container .btn:last-child {
    margin-right: 0;
}