.AddreviewModalMain .modal-header {
    background: #234C9E 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    color: #fff;
    cursor: pointer;
}

.AddreviewModalMain .modal-title {
    font-size: 18px;
}

.AddreviewModal .form-control {
    border-top: 0;
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    height: 15px;
    font-size: 14px;
}

.AddreviewModal .uploadDiv {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 12px 6px;
    font-size: 14px;
    margin-left: 20px;
}

.AddreviewModal .reviewImg {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.AddreviewModal .uploadReviewsec {
    display: flex;
    margin-top: 7%;
}

.Reviewsec .image-holder{
    display: flex;
    overflow-x: scroll;
    padding:  20px 0px;
}

.Reviewsec {
    overflow: hidden;
}


.Reviewsec .image-holder .file-img-holder {
    width: 60px;
    height: 60px;
    margin-right: 18px;
}

.AddreviewModal .uploadreviewMain .reviewVideo p {
    font-size: 13px;
    margin-top: 6px;
    margin-bottom: 0;
}

.AddreviewModal .reviewVideo p:nth-of-type(2) {
    color: #707070;
}

.AddreviewModal .btnsecmain {
    text-align: center;
}

.AddreviewModal .btnsecmain .submitbtn {
    background: #234C9E 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 4px 20px;
    border: 0;
}

.AddreviewModal .x-markMain {
    position: relative;
}

.AddreviewModal .x-markMain .crossIcon {
    position: absolute;
    right: 5px;
    cursor: pointer;
}

@media screen and (min-width: 0px) and (max-width: 767.98px) {
    .AddreviewModal .reviewImg {
        width: 70px;
        height: 70px;
    }

    .AddreviewModal .btnsecmain .submitbtn {
        margin-top: 12%;
    }
}