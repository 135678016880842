 .sortingsec .Filterdropdownmain .orderstatus {
     background: #E4E4E4 0% 0% no-repeat padding-box;
     padding: 10px 14px;
 }

 .sortingsec .Filterdropdownmain .orderstatus p {
     font-size: 13px;
 }

 .sortingsec .dropdown-menu.show {
     background: #F6F6F6 0% 0% no-repeat padding-box;
     box-shadow: 3px 3px 6px #00000029;
     border: 0;
     width: 50%;
     border-radius: 0;
     z-index: 9 !important;
 }

 .sortingsec .Filterdropdownmain .nav-pills .nav-link.active {
     background: #DCE8FF 0% 0% no-repeat padding-box;
     border: 1px solid #234C9E;
     border-radius: 22px;
 }

 .sortingsec .Filterdropdownmain .nav-pills .nav-link {
     background: #FFFFFF 0% 0% no-repeat padding-box;
     border: 1px solid #707070;
     border-radius: 22px;
     padding: 7px 23px;
     font-size: 11px;
     margin-right: 14px;
     position: relative;
     width: 100%;
     margin-bottom: 11px;
 }

 .sortingsec .x-mark {
     background: #fff;
     padding: 3px 5px;
     border-radius: 50%;
 }

 .sortingsec .nav-pills .nav-link span {
     position: absolute;
     right: 7px;
     top: 7px;
 }

 .sortingsec .Filterdropdownmain .filterDrop {
     margin-top: 5%;
     display: flex;
     justify-content: space-evenly;
 }

 .sortingsec .Filterdropdownmain .btnMainsec {
     text-align: center;
     margin-top: 5%;
 }

 .sortingsec .Filterdropdownmain .btnMainsec .cancelbtn {
     background: #FFFFFF 0% 0% no-repeat padding-box;
     border: 1px solid #000000;
     border-radius: 0px;
     color: #000;
     font-size: 13px;
     margin-right: 10px;
 }

 .sortingsec .Filterdropdownmain .clearfiltertxt {
     color: #7D7D7D;
     text-transform: capitalize;
     font-size: 12px;
     text-align: end;
     margin-bottom: 5px;
     margin-right: 7px;
 }

 .sortingsec .Filterdropdownmain .btnMainsec .savebtn {
     background: #234C9E 0% 0% no-repeat padding-box;
     border-radius: 0px;
     border: 0;
     font-size: 13px;
 }