.home-about {
  margin: 3% 0 0;
  background-color: #d3dfff59;
  padding: 40px 0px 42px 0px;
}

.home-about .text-content {
  font-size: 41px;
  font-weight: 600;
}

.home-about .read-btn {
  font-size: 15px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0%;
  border-bottom: 1px solid #000;
}

.home-about :active,
.home-about:hover,
.home-about:focus {
  outline: 0 !important;
  outline-offset: 0;
}

.home-about ::before,
.home-about ::after {
  position: absolute;
  content: "";
}

.home-about .btn {
  position: relative;
  background-color: transparent;
  cursor: pointer;
  padding: 0px;
}

.home-about .btn span {
  position: relative;
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2px 17px;
  transition: 0.3s;
}

/* 7. hover-slide-up */
.home-about .btn.hover-slide-up::before {
  bottom: 0;
  left: 0;
  right: 0;
  height: 0%;
  width: 100%;
}

.home-about .btn.hover-slide-up:hover::before {
  height: 100%;
}

.home-about .btn-2::before {
  background-color: rgb(28, 31, 30);
  transition: 0.3s ease-out;
}

.home-about .btn-2 span {
  color: rgb(28, 31, 30);
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0%;
  border-bottom: 1px solid #000;
  transition: 0.2s;
}

.home-about .btn-2 span:hover {
  color: rgb(255, 255, 255);
  transition: 0.2s 0.1s;
}

.home-about .white-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 18px 6px;
}

.home-about .white-box .total-holder p {
  font-size: 20px;
}

.home-about .white-box .total-holder h4 {
  font-size: 45px;
  color: #000;
  font-weight: bold;
}

.home-about .heading-holder {
  margin-bottom: 0px;
}

@media (min-width: 0px) and (max-width: 767px) {

  .home-about .btn span {
    font-size: 13px;
}

  .home-about .text-content {
    font-size: 17px;
    font-weight: 600;
  }

  .home-about .white-box .total-holder h4 {
    font-size: 30px;
  }

  .home-about .white-box .total-holder p {
    font-size: 16px;
  }

  .home-about .heading-holder {
    margin-bottom: 20px;
  }

  .home-about {
    padding: 50px 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .home-about .text-content {
    font-size: 32px;
    font-weight: 600;
  }

  .home-about .white-box .total-holder h4 {
    font-size: 36px;
  }

  .home-about .white-box .total-holder p {
    font-size: 16px;
  }

  .home-about .heading-holder {
    margin-bottom: 20px;
  }

  .home-about .text-content {
    font-size: 26px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .home-about .text-content {
    font-size: 32px;
    font-weight: 600;
  }

  .home-about .white-box .total-holder h4 {
    font-size: 36px;
  }

  .home-about .white-box .total-holder p {
    font-size: 16px;
  }
}
