.GiftAutomation .AboutusMain .aboutushead h3 {
    font-family: 'Gothamregular';
    font-family: "Gothammedium";
    font-weight: 300;
    color: #1e1e1e;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: 71px;
    letter-spacing: -1.32px;
    padding-bottom: 25px;
}

.GiftAutomation .AboutusMain {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 0px 50px 50px;
    padding: 2%;
    margin-bottom: 6%;
}

.GiftAutomation .AboutusMain .aboutushead span {
    display: block;
    font-size: 33px;
    font-weight: 400;
    font-family: 'Gothamregular';

}

.GiftAutomation .AboutusMain .schedull {
    font-family: 'GothamBold';
}

.GiftAutomation .AboutusMain .caretext {
    font-size: 20px;
    font-weight: 400;
    font-family: 'Gothamregular';
    color: #222222;
}

.GiftAutomation .AboutusMain .shedtext {
    font-size: 18px;
    font-weight: 400;
    font-family: 'Gothamregular';
    color: #222222;
}

.GiftAutomation .AboutusMain .aboutuscard {
    background: #D9E6FF 0% 0% no-repeat padding-box;
    border-radius: 11px;
    width: 100%;
    padding: 22px 0px;
}

.GiftAutomation .AboutusMain .aboutuscard .cardContent h3 {
    font-size: 33px;
    font-weight: bold;
}

.GiftAutomation .AboutusMain .aboutuscard .cardContent h4 {
    font-size: 28px;
}

.GiftAutomation .AboutusMain .aboutuscard .cardContent p {
    font-size: 15px;
    margin-bottom: 0;
}

.GiftAutomation .AboutusMain .paraInfo p {
    font-size: 20px;
    margin-bottom: 0;
    margin-top: 15px;
    text-align: center;
}

.GiftAutomation .AboutusMain .RightImgcare img {
    width: 100%;
    object-fit: contain;
    height: 433px;
}

.GiftAutomation .AboutusMain .productCareinfo {
    margin-top: 5%;
    font-size: 20px;
}

.GiftAutomation .workHead h3 {
    font-size: 45px;
    font-family: 'GothamBold';
}

.GiftAutomation .pathImg {
    position: relative;
}

.GiftAutomation .pathImg img {
    width: 100%;
}


.GiftAutomation .contactIcon {
    font-size: 45px;
}

.GiftAutomation .PathsectionMain {
    margin-bottom: 5%;
}

.GiftAutomation .automationtxt h3 {
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    font-family: 'Gothammedium';
}

.GiftAutomation .automationtxt p {
    text-transform: capitalize;
    font-size: 13px;
    text-align: center;
    font-family: 'Gothamregular';
}

.GiftAutomation .NeedHelp {
    background: #DFEAFF 0% 0% no-repeat padding-box;
}

.GiftAutomation .NeedHelp .Needhelpheading h3 {
    text-align: center;
    padding: 3% 0%;
    font-size: 45px;
    font-family: 'Gothamregular';
}

.GiftAutomation .NeedHelp .needhelp {
    font-family: 'GothamBold';
}

.GiftAutomation .Talktousbtn {
    text-align: center;
    padding-bottom: 3%;
}

.GiftAutomation .Talktousbtn button {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #234C9E;
    border-radius: 5px;
    width: auto;
    color: #000;
    font-family: 'Gothamregular';
}

.GiftAutomation .howtext {
    font-family: 'Gothamregular';
}

section.GiftAutomation.my-4 {
    margin-top: 0 !important;
}

@media screen and (min-width: 0px) and (max-width: 320px) {
    .GiftAutomation .workHead h3 {
        font-size: 20px;
    }

    .GiftAutomation .AboutusMain .aboutushead h3 {
        font-size: 28px;
    }

    .GiftAutomation .AboutusMain .caretext {
        font-size: 14px;
    }

    .GiftAutomation .pathImg {
        margin-bottom: 7%;
        margin-top: 3%;
    }

    .GiftAutomation .AboutusMain .RightImgcare img {
        height: 202px;
        margin-bottom: 13px;
    }

    .GiftAutomation .contactIcon {
        font-size: 18px;
    }

    .GiftAutomation .AboutusMain .aboutushead span {
        font-size: 18px;
    }

    .GiftAutomation .AboutusMain .shedtext {
        font-size: 12px;

    }

    .GiftAutomation .automationtxt p {
        font-size: 12px;
    }

    .GiftAutomation .NeedHelp .Needhelpheading h3 {
        font-size: 22px;
    }
}

@media screen and (min-width: 321px) and (max-width: 485px) {
    .GiftAutomation .AboutusMain .aboutushead span {
        font-size: 20px;
    }

    .GiftAutomation .whiteCircle {
        width: 70px;
        height: 70px;
        top: 22%;
        left: 10%;
    }

    .GiftAutomation .AboutusMain .RightImgcare img {
        height: 202px;
        margin-bottom: 13px;
    }

    .GiftAutomation .AboutusMain .caretext {
        font-size: 15px;
    }

    .GiftAutomation .AboutusMain .aboutushead h3 {
        font-size: 30px;
    }

    .GiftAutomation .AboutusMain .shedtext {
        font-size: 12px;

    }

    .GiftAutomation .workHead h3 {
        font-size: 20px;
    }


    .GiftAutomation .pathImg {
        margin-bottom: 7%;
        margin-top: 3%;
    }

    .GiftAutomation .contactIcon {
        font-size: 18px;
    }

    .GiftAutomation .automationtxt p {
        font-size: 12px;
    }

    .GiftAutomation .NeedHelp .Needhelpheading h3 {
        font-size: 23px;
    }
}

@media screen and (min-width: 486px) and (max-width: 575px) {


    .GiftAutomation .workHead h3 {
        font-size: 20px;
    }

    .GiftAutomation .AboutusMain .RightImgcare img {
        height: 202px;
        margin-bottom: 13px;
    }

    .GiftAutomation .AboutusMain .aboutushead span {
        font-size: 22px;
    }

    .GiftAutomation .AboutusMain .aboutushead h3 {
        font-size: 34px;
    }

    .GiftAutomation .AboutusMain .caretext {
        font-size: 16px;
    }

    .GiftAutomation .AboutusMain .shedtext {
        font-size: 14px;

    }

    .GiftAutomation .automationtxt p {
        font-size: 12px;
    }

    .GiftAutomation .pathImg {
        margin-bottom: 7%;
        margin-top: 3%;
    }

    .GiftAutomation .contactIcon {
        font-size: 18px;
    }

    .GiftAutomation .NeedHelp .Needhelpheading h3 {
        font-size: 25px;
    }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
    .GiftAutomation .whiteCircle {
        width: 84px;
        height: 84px;
        top: 26%;
        left: 10%;
    }

    .GiftAutomation .AboutusMain .RightImgcare img {
        height: 202px;
        margin-bottom: 13px;
    }

    .GiftAutomation .AboutusMain .aboutushead span {
        font-size: 24px;
    }

    .GiftAutomation .AboutusMain .shedtext {
        font-size: 15px;

    }

    .GiftAutomation .AboutusMain .aboutushead h3 {
        font-size: 37px;
    }

    .GiftAutomation .AboutusMain .caretext {
        font-size: 17px;
    }


    .GiftAutomation .whiteCircle2 {
        width: 84px;
        height: 84px;
        top: 26%;
        right: 11%;
    }

    .GiftAutomation .workHead h3 {
        font-size: 20px;
    }

    .GiftAutomation .whiteCircle3 {
        width: 84px;
        height: 84px;
        border-radius: 50%;
    }

    .GiftAutomation .pathImg {
        margin-bottom: 7%;
        margin-top: 3%;
    }

    .GiftAutomation .contactIcon {
        font-size: 18px;
    }

    .GiftAutomation .NeedHelp .Needhelpheading h3 {
        font-size: 30px;
    }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
    .GiftAutomation .whiteCircle {
        width: 84px;
        height: 84px;
        top: 26%;
        left: 10%;
    }

    .GiftAutomation .AboutusMain .shedtext {
        font-size: 16px;

    }

    .GiftAutomation .AboutusMain .aboutushead h3 {
        font-size: 40px;
    }

    .GiftAutomation .AboutusMain .aboutushead span {
        font-size: 27px;
    }

    .GiftAutomation .AboutusMain .caretext {
        font-size: 18px;
    }


    .GiftAutomation .whiteCircle2 {
        width: 84px;
        height: 84px;
        top: 26%;
        right: 11%;
    }

    .GiftAutomation .workHead h3 {
        font-size: 20px;
    }

    .GiftAutomation .whiteCircle3 {
        width: 84px;
        height: 84px;
        border-radius: 50%;
    }

    .GiftAutomation .pathImg {
        margin-bottom: 7%;
        margin-top: 3%;
    }

    .GiftAutomation .contactIcon {
        font-size: 18px;
    }

    .GiftAutomation .NeedHelp .Needhelpheading h3 {
        font-size: 34px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .GiftAutomation .AboutusMain .aboutushead span {
        font-size: 30px;
    }

    .GiftAutomation .AboutusMain .caretext {
        font-size: 16px;
    }

    .GiftAutomation .AboutusMain .shedtext {
        font-size: 17px;

    }

    .GiftAutomation .NeedHelp .Needhelpheading h3 {
        font-size: 38px;
    }

    .GiftAutomation .AboutusMain .aboutushead h3 {
        font-size: 35px;
    }

    .GiftAutomation .AboutusMain .aboutushead span {
        font-size: 23px;
    }

    .GiftAutomation .AboutusMain .RightImgcare img {
        height: 323px;
    }

    .GiftAutomation .automation-works .workHead h3 {
        font-size: 33px !important;
    }
}