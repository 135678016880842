.OrderTracking {
    margin: 3%;
}

.OrderTracking .trackTable tr td {
    font-size: 13px;
    padding: 17px;
}

.OrderTracking .trackTable tr th {
    font-size: 13px;
    padding: 15px;
}

.OrderTracking .showingentrytxt p {
    font-size: 13px;
    text-align: end;
}

.OrderTracking .trackTable tr td:last-child {
    cursor: pointer;
}