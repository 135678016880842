.Myorders h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-wrap: nowrap;
    margin-right: 10px;
    margin-top: 5px;
}

.Myorders {
    margin-bottom: 5%;
}

.Myorders .order-status {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.Myorders .filterImg {
    width: 14px;
    height: 9px;
    object-fit: contain;
}

.Myorders .dropdown-toggle::after {
    display: none;
}

.Myorders .order-status span {
    font-size: 0.9rem;
    color: #6c757d;
    margin-right: 18px;
    text-wrap: nowrap;
    margin-left: 10px;
}

.Myorders .filterTxt {
    text-align: end;
}

.Myorders .mainDropmenu {
    background: #F6F6F6 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
    border: 0;
    border-radius: 0;
}


.Myorders .mainDropmenu .subdropItem {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 22px;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 10px;
}

.Myorders .mainDropmenu .subdropItem p {
    margin-bottom: 0;
    font-size: 14px;
}

.Myorders .mainDropmenu .maindropdown-item p {
    background: #E4E4E4 0% 0% no-repeat padding-box;
    font-size: 13px;
    padding: 5px 7px;
}

.Myorders .sortingsec p {
    color: #000;
    font-size: 12px;
    margin-bottom: 0;
    text-align: end;
}

.Myorders .sortingsec .barImg {
    width: 14px;
    height: 9px;
}

.Myorders .sortingsec {
    display: block;
}

.Myorders .filtericonmain {
    display: none;
}


.Myorders .order-card .Orderdate {
    color: #6c757d;
    font-size: 13px;
}


.Myorders .orderedstatusMain {
    justify-content: flex-end;
    display: flex;
}

.Myorders .barsImage {
    width: 14px;
    height: 9px;
    object-fit: contain;
}

.Myorders .sortingsec {
    /* float: right; */
}

.Myorders .order-card .orderedstatusMain .orderedstatus {
    background-color: #007bff;
    color: #fff;
    font-size: 0.8rem;
    width: 65px;
    text-align: center;
}

.Myorders .nav-pills .nav-link {
    font-size: 15px;
    text-wrap: nowrap;
}

.Myorders .nav-pills .nav-link.active {
    color: #000;
    background-color: transparent;
    font-weight: 600;
}

/* .Myorders .myorderstitle {
    display: flex;
    justify-content: space-between;
} */

.Myorders .divider {
    border: 0;
    height: 8px;
    margin: 12px 0;
    border-bottom: 1px solid #000;
    width: 100%;
}

.Myorders .dropstart {
    text-align: end;
}

.Myorders .orderRow {
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}


/* Filter dropdown start */
.Myorders .sortingsec .Filterdropdownmain .orderstatus {
    background: #E4E4E4 0% 0% no-repeat padding-box;
    padding: 10px 14px;
}

.Myorders .sortingsec .Filterdropdownmain .orderstatus p {
    font-size: 13px;
    text-align: justify;
}

.Myorders .sortingsec .dropdown-menu.show {
    background: #F6F6F6 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
    border: 0;
    width: 100%;
    border-radius: 0;
    transform: translate3d(0px, 20px, 0px) !important;
    z-index: 9 !important;
}

.Myorders .sortingsec .nav-item.show.dropdown {
    width: 50%;
    float: right;
}

.Myorders .sortingsec .Filterdropdownmain .nav-pills .nav-link.active {
    background: #DCE8FF 0% 0% no-repeat padding-box;
    border: 1px solid #234C9E;
    border-radius: 22px;
}

.Myorders .sortingsec .Filterdropdownmain .nav-pills .nav-link {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 22px;
    padding: 7px 23px;
    font-size: 11px;
    margin-right: 14px;
    position: relative;
    width: 100%;
    margin-bottom: 11px;
}

.Myorders .sortingsec .x-mark {
    background: #fff;
    padding: 3px 5px;
    border-radius: 50%;
}

.Myorders .sortingsec .nav-pills .nav-link span {
    position: absolute;
    right: 7px;
    top: 7px;
}

.Myorders .sortingsec .Filterdropdownmain .filterDrop {
    margin-top: 5%;
    display: flex;
    justify-content: space-evenly;
}

.Myorders .sortingsec .Filterdropdownmain .btnMainsec {
    text-align: center;
    margin-top: 5%;
}

.Myorders .sortingsec .Filterdropdownmain .btnMainsec .cancelbtn {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #000000;
    border-radius: 0px;
    color: #000;
    font-size: 13px;
    margin-right: 10px;
}

.Myorders .sortingsec .Filterdropdownmain .clearfiltertxt {
    color: #7D7D7D;
    text-transform: capitalize;
    font-size: 12px;
    text-align: end;
    margin-bottom: 5px;
    margin-right: 7px;
}

.Myorders .sortingsec .Filterdropdownmain .btnMainsec .savebtn {
    background: #234C9E 0% 0% no-repeat padding-box;
    border-radius: 0px;
    border: 0;
    font-size: 13px;
}


/* Filter dropdown end */

@media screen and (min-width: 0px) and (max-width: 768px) {
    .Myorders .order-status {
        overflow-x: auto;
        padding-bottom: 10px;
    }

    .Myorders .filtericonmain .filter {
        font-size: 14px;
    }

    .Myorders .myorderstitle {
        display: block;
    }

    .Myorders .sortingsec {
        display: none;
    }

    .Myorders .filtericonmain {
        display: block;
    }

    .Myorders .myorderstitle h2 {
        font-size: 15px;
    }

    .Myorders .nav-pills .nav-link {
        padding: 3px 13px;
    }

    .Myorders .divider {
        border: 0;
        height: 8px;
        margin: 6px 0;
        border-bottom: 1px solid #000;
        width: 100%;
        display: none;
    }

    .Myorders .orderRow {
        overflow-x: auto;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .Myorders .myorderstitle h2 {
        font-size: 15px;
        margin-top: 11px;
    }

    .Myorders .nav-pills .nav-link {
        padding: 3px 13px;
    }

    .Myorders .sortingsec .nav-item.show.dropdown {
        width: 87%;
        float: right;
    }
}