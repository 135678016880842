.shop-by-occasion {
  margin: 3% 0;
}

/* .shop-by-occasion .heading-holder h3 {
    color: #222222;
    font-size: 45px;
    font-weight: bold;
}

.shop-by-occasion .heading-holder h2 {
    color: #222222;
    font-size: 45px;

} */

.shop-by-occasion .heading-holder span {
  font-size: 15px;
}

.shop-by-occasion .image-holder {
  background-color: #eae8eb;
  border-radius: 10px;
}



.shop-by-occasion .image-holder .birthday-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 12px;
  aspect-ratio: 1/1;
}
.shop-by-occasion .main-div {
  height: 100%;
}

.shop-by-occasion .main-div h3 {
  color: #2e2e2e;
  font-size: 21px;
  text-align: center;
  margin-top: 30px;
  font-weight: normal;
}

.heading-holder {
  margin-bottom: 40px;
}

@media (min-width: 0px) and (max-width: 992px) {
  .shop-by-occasion .main-div h3 {
    font-size: 16px;
  }
}

@media (min-width: 0px) and (max-width: 992px) {
}
