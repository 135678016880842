.Careers {
  background: transparent
    linear-gradient(
      180deg,
      #234b9e 0%,
      #2857b7d1 28%,
      #5c719c59 75%,
      #a5b1c831 89%,
      #ffffff00 100%
    )
    0% 0% no-repeat padding-box;
  padding: 3%;
}

.Careers .careerHead h3 {
  margin-bottom: 3%;
  font-size: 50px;
  color: #fff;
  font-family: "Gothamregular";
  font-weight: 700;
}

.Careers .Careerscard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 25px 50px;
}
.Careers .Careerscard .blueCard {
  background: #ecf2ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 2% 4%;
  width: 100%;
}

.Careers .Careerscard .blueCard .bluecard_head .job-description-holder {
    display: flex;
    justify-self: start;
    align-items: start;
    margin-left: 15px;
}

.Careers
  .Careerscard
  .blueCard
  .bluecard_head
  .job-description-holder
  .job-description-img-holder {
  margin-right: 10px;
}

.Careers
  .Careerscard
  .blueCard
  .bluecard_head
  .job-description-holder
  .job-description-img-holder
  .job-description-img {
  width: 20px;
  height: 30px;
}

.Careers .Careerscard .blueCard .bluecard_head p {
  font-size: 14px;
  margin-bottom: 0;
  font-family: "Gothamregular";
  color: #2e2e2e;
}

.Careers .Careerscard .blueCard .bluecard_head h3 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 14px;
    font-family: "Gothammedium";
}

.Careers .Careerscard .blueCard .viewtxt span {
  text-decoration: underline;
  font-size: 14px;
  letter-spacing: 0px;
  font-family: "GothamBold";
  color: #173f8f;
}

.Careers .Careerscard .blueCard .viewtxt span:hover {
  color: #173f8f;
}

.Careers .Careerscard .accordion-item {
  border: 0;
  margin-bottom: 25px;
}

.Careers .Careerscard .accordion-button {
  padding: 0;
}

.Careers .Careerscard .accordion-button::after {
  display: none;
}

.Careers .Careerscard .accordDetailsMain h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    font-family: "GothamBold";
}

.Careers .Careerscard .accordDetailsMain  .main-heading{
padding-left: 30px;
}

.Careers .Careerscard .JobHeading {
  margin-bottom: 15px;
  display: flex;
}

.Careers .Careerscard .JobHeading h3 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 6px;
  font-family: "Gothammedium";
}

.Careers .Careerscard .JobHeading p {
  font-size: 14px;
  font-family: "Gothamregular";
}


.Careers .Careerscard  .jobInfodetails {
    padding: 0px 35px;
}
/* Roles and Responsibility */

.Careers .RolesResponsbility {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 2% 3%;
  margin: 3% 1%;
}

.Careers .RolesResponsbility .responsibilityhead h3 {
  font-size: 18px;
  font-weight: 600;
  font-family: "GothamBold";
}

.Careers .accordion-body {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 10px;
}

.Careers .RolesResponsbility .responsibilityList ul {
  list-style-type: none;
}

.Careers .RolesResponsbility .responsibilityList ul li {
    font-size: 14px;
    font-family: "Gothamregular";
    display: flex;
    justify-content: start;
    align-items: center;
    font-weight: 500;
}

.Careers .RolesResponsbility .responsibilityList ul li p{
    margin-bottom: 0px;
}

.Careers .RolesResponsbility .responsibilityList ul .tickicon {
  width: 13px;
  height: 13px;
  margin-right: 10px;
}

/* Contact Info */

.Careers .RolesResponsbility .userName {
  margin-bottom: 3%;
}

.Careers .RolesResponsbility .userName p {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 400;
  font-family: "Gothammedium";
}

.Careers .RolesResponsbility .emailContactinfo p {
  font-size: 14px;
  margin-bottom: 0;
  color: #131313;
  font-family: "Gothamregular";
}
.requtertext {
  color: #131313;
  font-family: "Gothammedium" !important;
}
.Careers .RolesResponsbility .emailContactinfo {
  font-size: 14px;
}

.Careers .Jobdetailsicons {
    width: 15px;
    height: 15px;
    margin-top: -9px;
    margin-right: 40px;
    object-fit: contain;
}

.Careers .accordion-button:not(.collapsed) {
    color: #000 !important;
    background: none !important;
}
.Careers .Applyforjob button {
  background: #173f8f 0% 0% no-repeat padding-box;
  border: 1px solid #173F8F;
  border-radius: 5px;
  font-size: 18px;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .Careers .Careerscard {
    padding: 25px 25px;
  }
  .Careers .Careerscard .blueCard {
    padding: 4% 6%;
  }
  .Careers .careerHead h3 {
    margin-bottom: 5%;
    font-size: 27px;
  }
  .Careers .Careerscard .blueCard .bluecard_head h3 {
    font-size: 12px;
    margin-bottom: 21px;
}

  .Careers .Careerscard .blueCard .bluecard_head p {
    font-size: 12px;
    margin-bottom: 7px;
  }
  .Careers .Careerscard .accordDetailsMain h3 {
    font-size: 15px;
  }

  .Careers .Careerscard .JobHeading p {
    font-size: 12px;
  }

  .Careers {
    padding: 4% 0%;
  }

  .Careers .accordion-body {
    padding: 12px;
  }

  .Careers .RolesResponsbility .responsibilityList ul li {
    font-size: 12px;
    margin-bottom: 14px;
  }

  .Careers .RolesResponsbility .userName p {
    font-size: 12px;
  }

  .Careers .RolesResponsbility {
    margin: 6% 0%;
  }
  .Careers .Careerscard .blueCard .viewtxt span {
    font-size: 12px;
  }
  .Careers .RolesResponsbility .emailContactinfo p {
    margin-bottom: 3px;
    font-size: 12px;
  }
  .Careers .Applyforjob button {
    font-size: 13px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1199px) {
  .Careers .careerHead h3 {
    font-size: 37px;
  }
  .Careers .Careerscard .blueCard .bluecard_head h3 {
    font-size: 18px;
  }
  .Careers .Careerscard .blueCard .bluecard_head p {
    font-size: 13px;
  }
  .Careers .Careerscard .blueCard .viewtxt span {
    font-size: 13px;
  }
  .Careers .RolesResponsbility .emailContactinfo p {
    margin-bottom: 3px;
    font-size: 13px;
  }
  .Careers .Applyforjob button {
    font-size: 15px;
  }
}
