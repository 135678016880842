.scroll-to-top {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 1000;
}

.scroll-button {
    color: #fff;
    background: #0000 linear-gradient(180deg, #234C9E, #25304e);
    border: none;
    padding: 13px 20px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 20px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    animation: bounce 2s infinite;
}

.scroll-button:hover {
    opacity: 1;
}

/* Arrow bounce animation */
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}


/* .scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.scroll-button {
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    animation: bounce 2s infinite;
}

.scroll-button:hover {
    opacity: 1;
}

.scroll-button  .scroll-button-arrow{
    height: 70px;
    width: 70px;
} */

/* Arrow bounce animation */
/* @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
} */
