.contact-us {
  position: relative;
  height: 100%;
  width: 100%;
}

.contact-us .gmap_canvas .gmap_iframe {
  z-index: 0 !important;
  position: relative !important;
}

.contact-us .contact_form_holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #ffffff2e; */
  background: #ffffffa1;
}

.contactusmain .text-danger {
  line-height: 1;
}

.contact-us .contact_form_bg {
  background: none;
  width: 50%;
  right: 0px !important;
  display: flex;
  justify-content: end !important;
  left: 50%;
}

.contact-us .form-holder {
  padding: 25px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 24px #0000002b;
  border-radius: 6px;
  position: relative;
}

.contact-us .form-holder .form_container {
  height: 310px;
  overflow: hidden;
  overflow-y: scroll;
}

.contact-us .form-holder .form_container::-webkit-scrollbar {
  width: 2px;
}

.contact-us .form-holder .form_container::-webkit-scrollbar:horizontal {
  height: 8px;
}

/* Track */
.contact-us .form-holder .form_container::-webkit-scrollbar-track {
  background: #e8e8e8;
}

.contact-us .form-holder .form_container::-webkit-scrollbar-track:horizontal {
  background: #e8e8e8;
}

/* Handle */
.contact-us .form-holder .form_container::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 5px;
}

.contact-us
  .form-holder
  .form_container
  .scroller::-webkit-scrollbar-thumb:horizontal {
  background: #c7c7c7;
  border-radius: 5px;
}

.contact-us .heading-holder h2 {
  color: #000;
  font-family: "Gothammedium";
  font-weight: 300;
  color: #1e1e1e;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 71px;
  letter-spacing: -1.32px;
  padding-bottom: 25px;
}

.contact-us .icon-holder .call-icon {
  font-size: 12px;
  background: #234c9e;
  color: #fff;
  padding: 7px 1px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
}

.contact-us .icon-holder .email-icon {
  width: 23px;
  height: 18px;
}

.contact-us .icon-holder .location-icon {
  width: 18px;
  height: 26px;
}

.contact-us .address-holder p {
  font-size: 15px;
  font-weight: 700;
  color: #000;
}

.contact-us .map-text p {
  text-decoration: underline;
  cursor: pointer;
}

.contact-text-holder {
  margin-left: 30%;
}

.contact-us .form-holder .left-arrow {
  /* font-size: 13px;
  background: #df4223;
  color: #fff;
  padding: 5px 5px;
  border-radius: 50px; */
  width: 38px;
  height: 24px;
  position: absolute;
  top: -14px;
  left: 15px;
  cursor: pointer;
}

.contact-us .form-holder .form-control {
  background: transparent;
  border-radius: 0px;
  font-size: 12px;
  font-weight: 500;
  color: #7b7b7b;
  margin-bottom: 8px;
  border-top: 0;
  border-right: 0;
  border-left: 0;

}

.contact-us .form-holder .text-holder {
  height: auto;
}

.contact-us .form-holder .form-control:focus {
  box-shadow: none;
}

.contact-us .border-holder {
  border-bottom: 2px solid #70707080;
}

.contact-us .social-icon .facebook-icon {
  width: 27px;
  height: 27px;
  cursor: pointer;
}

.gmap_iframe {
  width: 100%;
  height: 79vh;
  border: 0;
}

.contact-us .btn-dark {
  background: #212121;
  font-size: 14px;
  color: #fff;
  border-radius: 5px;
  padding: 5px 20px;
}

.contact-us .sendMessage {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  padding: 6px 24px;
  background: #234c9e 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 0;
}

.contact-us .form-holder .form-check-label {
  font-size: 12px;
  margin-bottom: 16px;
}

.contact_form_none {
  display: none;
}

.contact-us textarea.form-control {
  height: 15px;
}

.contact-us .scale-down-hor-right {
  -webkit-animation: scale-down-hor-right 0.3s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-down-hor-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.contact-us .map-section {
  position: relative;
}

.contact-us  .hide-map{
  z-index: 0;
  position: relative;
}

.contact-us  .show-map{
  z-index: 1;
  position: relative;
}
.contact-us .map-section .close-map-btn {
  border: 1px solid #000;
  border-radius: 50%;
  position: absolute;
  background: #000;
  right: 10px;
  top: 10px;
  width: 30px;
  color: #fff;
  font-weight: 900;
  height: 30px;
}



@-webkit-keyframes scale-down-hor-right {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }

  100% {
    -webkit-transform: scaleX(0.6);
    transform: scaleX(0.6);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}

@keyframes scale-down-hor-right {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }

  100% {
    -webkit-transform: scaleX(0.6);
    transform: scaleX(0.6);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}

.contact-us .scale-up-hor-right {
  -webkit-animation: scale-up-hor-right 0.3s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-hor-right 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.6);
    transform: scaleX(0.6);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}

@keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.6);
    transform: scaleX(0.6);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}

.slide-out-right {
  -webkit-animation: slide-out-right 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-right 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}

@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}

.slide-out-left {
  -webkit-animation: slide-out-left 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-left 2.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
}

@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
}

@media (min-width: 0px) and (max-width: 421px) {
  .contact-text-holder {
    margin-left: 1%;
    margin-top: 7%;
  }
}

@media (min-width: 421px) and (max-width: 577px) {
  .contact-text-holder {
    margin-left: 1%;
    margin-top: 7%;
  }
}

@media (min-width: 578px) and (max-width: 767px) {
  .contact-text-holder {
    margin-left: 1%;
    margin-top: 7%;
  }
}

@media (min-width: 0px) and (max-width: 991px) {
  .contact-us .heading-holder h2 {
    font-size: 25px;
  }

  .contact-text-holder {
    margin-left: 13%;
  }

  .contact-us .social-icon {
    margin-bottom: 5%;
  }

  /* 
  .contact-us .contact_form_holder {
    height: 120vh;
  } */

  /* .map-section .gmap_iframe {
    height: 120vh !important;
  }

  .contact-us {
    height: 120vh;
  } */



  .contact-us .contact_form_bg {
    background: none;
    width: 72%;
    right: 0px !important;
    display: flex;
    justify-content: end !important;
    left: 28%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .contact-us .heading-holder h2 {
    font-size: 35px;
  }

  /* .contact-us {
    margin-bottom: 18%;
  } */
}
