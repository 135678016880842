@import url('https://fonts.googleapis.com/css2?family=Open Sans:wght@400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap');

@font-face {
  font-family: Gothamregular;
  src: url('../public/assest/image/Gotham\ Book\ Regular.otf');
}

@font-face {
  font-family: GothamBold;
  src: url('../public/assest/image/Gotham-Bold.otf');
}

@font-face {
  font-family: Gothammedium;
  src: url('../public/assest/image/Gotham-Medium.otf');
}




body {
  margin: 0px;
  padding: 0px;
  font-family:  "Gothamregular" !important;
}

a {
  text-decoration: none;
  color: #000 !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.form-control::placeholder {
  font-style: italic;
}

.footer-title {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  color: #000;
  font-family: 'GothamBold';
}

.splash-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: linear-gradient(180deg, rgb(0 0 0 / 44%) 0%, #09152a 100%);
  opacity: 0;
  animation: fadeIn 1.5s forwards;
}

.splash-screen .headlogo {
  width: 500px; /* Adjust size as needed */
  transform: scale(0.8);
  animation: scaleUp 1.5s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes scaleUp {
  to {
    transform: scale(1);
  }
}
.accordion-button {
  box-shadow: unset !important;
}

.heading-holder .second-heading {
  color: #222222;
  font-size: 45px;
  font-family: 'GothamBold';
  margin-left: 8px;
}

.heading-holder .first-heading {
  color: #222222;
  font-size: 45px;
  font-weight: 400 !important;
}

.heading-holder .text-para {
  font-size: 15px;
}

.mySwiper .swiper-pagination-bullet {
  background: #697AA7;
  opacity: 1;
  width: 20px;
  height: 5px;
  border-radius: 10px;
}

.mySwiper .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #25304E;
  width: 30px;
  border-radius: 50px;
  height: 5px;
}

.no-data-found-text{
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  color: #000;
  margin-bottom: 0px;
}

.modal {
  backdrop-filter: blur(4px);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">") !important;
}

.react-tel-input .form-control {
  width: 100% !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #C2C2C2 0% 0% no-repeat padding-box;
  border-radius: 50px;
}

.form-check-input:checked {
  box-shadow: unset !important;
  background-color: #000 !important;
  border-color: #000 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0F5998 0% 0% no-repeat padding-box;
  border-radius: 50px;
  /* display: none; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media(min-width:0px) and (max-width:767px) {
  .footer-title {
    font-size: 12px;

  }

  .heading-holder .second-heading {
    color: #222222;
    font-size: 20px;
    font-weight: bold;
}

  .heading-holder .first-heading {
    color: #222222;
    font-size: 20px;
    font-weight: 400 !important;
  }

  .heading-holder .text-para {
    font-size: 12px;
  }
}

@media(min-width:767px) and (max-width:992px) {
  .heading-holder .second-heading {
    color: #222222;
    font-size: 30px;
    font-weight: bold;
  }

  .heading-holder .first-heading {
    color: #222222;
    font-size: 30px;
    font-weight: 400 !important;
  }
}