.hearticn {
  width: 36px;
  height: 36px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 4px 4px;
  margin-left: auto;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 1;
}

.hearticn .gobiheart {
  font-size: 15px;
  color: #b4b2b2;
  position: absolute;
  top: 3px;
  right: 9px;
}

.hearticn .gobiheartss {
  font-size: 15px;
  color: #000;
  position: absolute;
  top: 3px;
  right: 9px;
}

.heart-button {
  background-color: transparent; /* Optional: Change background if needed */
  color: white; /* Sets the text color to white */
  border: none; /* Optional: Removes border */
  cursor: pointer; /* Changes cursor to pointer on hover */
  padding: 10px 20px; /* Optional: Add padding for better appearance */
  font-size: 16px; /* Optional: Adjust font size */
}

/* Optional: Add hover effect */
.heart-button:hover {
  color: #ffcc00; /* Change color on hover (example: gold) */
}