.Myorders h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-wrap: nowrap;
    margin-right: 10px;
}

.Myorders .order-status {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.Myorders .order-status span {
    font-size: 0.9rem;
    color: #6c757d;
    margin-right: 18px;
    text-wrap: nowrap;
    margin-left: 10px;
}

.Myorders .order-card {
    border: none;
    height: 100%;
    background-color: transparent;
    transition: all 0.3s ease-in-out ;
}

.Myorders .order-card .productImg {
    background-color: #fff;
    box-shadow: 0px 0px 20px #00000000;
    border-radius: 10px;
}

.Myorders .order-card .productImg img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    aspect-ratio: 1/1;
    /* margin: 22px 0px 0px 0px; */
    text-align: center;
    transition: opacity 0.3s ease-in, transform 0.3s ease-in;
    /* margin-bottom: 15px; */
}

.Myorders .order-card .productImg img:nth-of-type(1) {
    display: block;
    opacity: 1;
}

.Myorders .order-card .productImg img:nth-of-type(2) {
    display: none;
    opacity: 0;
}

.Myorders .order-card:hover .productImg img:nth-of-type(1) {
    display: none;
    opacity: 0;
}

.Myorders .order-card:hover .productImg img:nth-of-type(2) {
    display: block;
    opacity: 1;
}

.Myorders .order-card .card-title {
    font-size: 16px;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 5;
    font-weight: 600;
    /* text-align: justify; */
}


/* .Myorders .order-card:hover .card-img-top {
    margin-top: -5px;
    transition: all 0.3s ease-in-out ;
} */

.Myorders .order-card .card-text .productPrice {
    color: #6c757d;
    margin-bottom: 3px;
    font-size: 17px;
    font-weight: 600;
}

.Myorders .order-card .Orderdate {
    color: #6c757d;
    font-size: 11px;
}

.Myorders .order-card .addreview {
    font-size: 14px;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
}

.Myorders .orderedstatusMain {
    justify-content: flex-end;
    display: flex;
}

.Myorders .order-card .orderedstatusMain .orderedstatus {
    background-color: transparent;
    color: #000;
    font-size: 0.8rem;
    width: 65px;
    text-align: center;
    text-wrap: nowrap;
    font-weight: 600;
}

.Myorders .order-card .showreview {
    display: none;
}

.Myorders .order-card .removeReview {
    display: block;
}

.Myorders .ratethisproduct span {
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 3px;
    cursor: pointer;
}

.Myorders .myorderstitle {
    /* display: flex; */
    justify-content: space-between;
}

.Myorders .ratethisproduct .groupStars {
    width: 100%;
    height: 13px;
    object-fit: contain;
    margin-left: 6px;
}

.Myorders .ratethisproduct .starImg_txt {
    display: flex;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .Myorders .order-status {
        overflow-x: auto;
        padding-bottom: 10px;
    }

    .Myorders .order-card img {
        height: 134px;
        margin: 24px 0px;
    }

    .Myorders .myorderstitle {
        display: block;
    }

    .Myorders .order-card:hover .card-img-top {
        transform: scale(1.00, 1.00);
    }

    .Myorders .order-card .Orderdate {
        font-size: 12px;
    }

    .Myorders .order-card .card-title {
        margin-bottom: 2px;
    }

    .Myorders .order-card .card-text .productPrice {
        font-size: 14px;
    }


}