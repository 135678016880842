.register-container {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    background-color: #f8f9fa;
    padding: 3%;
    background: url("../../../public/assest/image/login/Loginback.png");
    background-position: center;
    background-repeat: no-repeat;
    /* height: 100vh; */
    position: relative;
}

.formfieldregister {
    position: relative;
}

.register-box .checkMain {
    justify-content: end;
    display: flex;
}

.register-box .checkMain .checkNews {
    font-size: 13px;
}

.formfieldregister .eyeIcon {
    position: absolute;
    top: 8px;
    right: 3px;
    cursor: pointer;
}

.login-container .register-box .createaccountbtn {
    background-color: transparent;
    color: #333333;
    font-size: 13px;
    border-radius: 3px;
    border: 1px solid #707070;
}

.login-container .blueOverlay {
    background: transparent linear-gradient(180deg, #234B9E 0%, #2857B7D1 28%, #5C719C59 75%, #A5B1C831 89%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.login-container .register-box .createaccountbtn:hover {
    background: #234C9E 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: #fff;
    font-size: 13px;
}

.nameLabel {
    width: 100%;
}

.login-header {
    background-color: #fff;
    margin-top: 4%;
    position: relative;
    z-index: 9;
    margin-bottom: 5%;
}


@media (min-width:0px) and (max-width:991px){
    .login-container img.logo {
        width: auto;
        height: 50px;
    }
}