.home-banner {}

.home-banner .image-holder {
    position: relative;
}

.home-banner .image-holder .ban-image {
    width: 100%;
    height: auto;

}

.home-banner .image-holder .overlay-color {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    /* background: transparent linear-gradient(90deg, #FFFFFF 0%, #ffffff 30%, #FFFFFF5E 44%, #FFFFFF00 100%) 0% 0% no-repeat padding-box; */
    /* background: transparent linear-gradient(90deg, #101010bf 0%, #262626ba 30%, #02020261 44%, #FFFFFF00 100%) 0% 0% no-repeat padding-box; */
}


.home-banner .image-holder .content-holder {
    position: absolute;
    left: 45%;
    bottom: 35px;
} 

.home-banner .image-holder .content-holder h1 {
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 80px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -1.76px;
    line-height: 1.2;
    margin: 0;
    text-align: left;
    margin-bottom: 15px;
}

.home-banner .image-holder .content-holder span {
    /* font-size: 65px;
    color: #000;
    font-family: 'GothamBold'; */
    font-weight: 900;
}

.home-banner .image-holder .content-holder p {
    font-size: 20px;
    color: #2E2E2E;
    font-weight: 600;
    margin: 30px 0px;
}

.home-banner .button-holder .talk-us {
    font-size: 15px;
    color: #2E2E2E;
    border: none;
    border-radius: 5px;
    padding: 9px 40px;
    background: #eac65e 0% 0% no-repeat padding-box;
    border-radius: 5px;
    margin-top: 66%;
    box-shadow: 0px 9px 20px 0px #3f0000;
    font-weight: 700;
}
.home-banner .button-holder .talk-us:hover {
    background: #2E2E2E 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #fff;
}



@media(min-width:0px) and (max-width:575px) {
    .home-banner .image-holder .content-holder {
        left: 34%;
        bottom: 11px;
    }
}

@media(min-width:576px) and (max-width:768px) {
    .home-banner .image-holder .content-holder {
        left: 36%;
        bottom: 11px;
    }
}




@media(min-width:0px) and (max-width:486px) {
    .home-banner .image-holder .content-holder h1 {
        font-size: 15px;
        letter-spacing: 0.1px;
    }

    .home-banner .image-holder .content-holder span {
        font-size: 20px;
    }

  

    .home-banner .button-holder .talk-us {
        font-size: 10px;
        padding: 2px 13px;
        margin-top: 60%;
    }

    .home-banner .image-holder .content-holder p {
        font-size: 13px;
        margin: 0px 0px;
    }
}

@media(min-width:486px) and (max-width:767px) {
    .home-banner .image-holder .content-holder h1 {
        font-size: 20px;
        letter-spacing: 0.1px;
    }

    .home-banner .image-holder .content-holder span {
        font-size: 25px;
    }


    .home-banner .image-holder .content-holder p {
        font-size: 15px;
        margin: 18px 0px;
    }
    .home-banner .button-holder .talk-us {
        margin-top: 40%;
        font-size: 15px;
        padding: 5px 17px;
    }

   
}

@media(min-width:768px) and (max-width:992px) {
    .home-banner .image-holder .content-holder h1 {
        font-size: 25px;
        letter-spacing: 0.1px;
    }

    .home-banner .image-holder .content-holder span {
        font-size: 35px;
    }

   

    .home-banner .button-holder .talk-us {
        font-size: 17px;
    }

    .home-banner .image-holder .content-holder p {
        font-size: 20px;
        margin: 17px 0px;
    }

    .home-banner .button-holder .talk-us {
        margin-top: 40%;
    }

    .home-banner .image-holder .content-holder {
        left: 37%;
        bottom: 22px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .home-banner .image-holder .content-holder h1 {
        font-size: 35px;
    }

    .home-banner .image-holder .content-holder span {
        font-size: 45px;
    }
    .home-banner .image-holder .content-holder {
        left: 41%;
        bottom: 22px;
    }
   
}

@media(min-width:1200px) and (max-width:1500px) {
    .home-banner .image-holder .content-holder h1 {
        font-size: 39px;
    }

    .home-banner .image-holder .content-holder span {
        font-size: 45px;
    }

    .home-banner .image-holder .content-holder {
        left: 43%;
        bottom: 22px;
    }
}