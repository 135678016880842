.my-cart-titleee h3 {
  font-size: 35px;
  color: #2e2e2e;
  text-align: start;
  margin: 2% 0;
}

.product-order {
  position: relative;
}

.my-cartmain .breadcrumb {
  margin-top: 18px;
  margin-bottom: 0;
}

.tableBody {
  position: relative;
}

.product-order .pro-img {
  width: 100%;
  object-fit: contain;
  height: 100%;
  aspect-ratio: 1/1;
}

.product-order .table th {
  background: #ececec;
  font-size: 15px;
  font-weight: 600;
  padding: 5px 30px;
  text-transform: uppercase;
}

.product-order .table td {
  font-size: 14px;
  color: #333333;
  padding-top: 46px !important;
  padding: 5px 30px;
  text-wrap: nowrap;
}

.my-cartmain .colour-name {
  margin-bottom: 3px;
  font-size: 14px;
}

.my-cartmain .size-name {
  font-size: 14px;
}

.product-order .pro-name {
  font-size: 14px;
  font-weight: bold;
}

.product-order .white-bgg {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 15px 15px;
  margin-left: 5px;
  width: 100%;
  text-align: center;
  margin-top: 7px;
}

section.product-order {
  margin-bottom: 21px;
}

.product-order .crossIcon {
  position: absolute;
  width: 15px;
  right: 7px;
  top: 50px;
  cursor: pointer;
}

.tableBody .increment-decrement-holder .quantity {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0;
}

.tableBody .increment-decrement-holder .quantity .quantity__minus,
.tableBody .increment-decrement-holder .quantity .quantity__plus {
  display: block;
  width: 22px;
  height: 23px;
  margin: 0;
  background: #dee0ee;
  text-decoration: none;
  text-align: center;
  line-height: 23px;
  cursor: pointer;
}
.tableBody .increment-decrement-holder .quantity .quantity__minus:hover,
.tableBody .increment-decrement-holder .quantity .quantity__plus:hover {
  background: #575b71;
  color: #fff;
}
.tableBody .increment-decrement-holder .quantity .quantity__minus {
  border-radius: 3px 0 0 3px;
}
.tableBody .increment-decrement-holder .quantity .quantity__plus {
  border-radius: 0 3px 3px 0;
}
.tableBody .increment-decrement-holder .quantity .quantity__input {
  width: 32px;
  height: 23px;
  margin: 0;
  padding: 0;
  text-align: center;
  border-top: 2px solid #dee0ee;
  border-bottom: 2px solid #dee0ee;
  border-left: 1px solid #dee0ee;
  border-right: 2px solid #dee0ee;
  background: #fff;
  color: #8184a1;
}
.tableBody .increment-decrement-holder .quantity .quantity__minus:link,
.tableBody .increment-decrement-holder .quantity .quantity__plus:link {
  color: #8184a1;
}
.tableBody .increment-decrement-holder .quantity .quantity__minus:visited,
.tableBody .increment-decrement-holder .quantity .quantity__plus:visited {
  color: #fff;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  /* .product-order .pro-img {
        width: 72px;
    } */

  .product-order .white-bgg {
    padding: 2px 2px;
  }

  .my-cart-titleee h3 {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 9px;
  }

  .colour-name {
    font-size: 14px;
    margin-bottom: 2px;
  }

  .size-name {
    font-size: 14px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  /* .product-order .pro-img {
        width: 72px;
    } */

  .product-order .white-bgg {
    padding: 8px 0px;
  }
  .my-cart-titleee h3 {
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 9px;
  }
}
