.productCustomise {
  margin: 3%;
}

.productCustomise .customiseMain {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.productCustomise .customiseMain .customiseLeft {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border-radius: 10px 0px 0px 10px;
  padding: 15px;
}

.productCustomise .customiseMain .addtocartsec button {
  background: #234c9e 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 0;
  font-size: 14px;
  margin-top: 2%;
  margin-bottom: 2%;
}

.productCustomise .customiseMain .productImg {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  text-align: center;
  padding: 0px 0px;
  margin-bottom: 15px;
}

.productCustomise .customiseMain .productImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 1/1;
}

.productCustomise .customiseMain .productTitle h3 {
  font-size: 18px;
  color: #2e2e2e;
  margin-bottom: 20px;
}

.productCustomise .customiseMain .productdetail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;
}

.productCustomise .customiseMain .productdetail p {
  font-size: 14px;
  margin-bottom: 0;
}

.productCustomise .customiseMain .productdetail span {
  font-size: 12px;
}

.productCustomise .customiseMain .addtocartbtn {
  width: 100%;
  background: #333333 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border: 0;
}

.productCustomise .customiseMain .customiseRight .customiseHead h3 {
  font-size: 35px;
  margin-bottom: 25px;
}

.productCustomise .customiseMain .react-tel-input .form-control {
  width: 100%;
}

.productCustomise .customiseMain .customiseRight {
  margin: 2%;
}

.productCustomise .customiseMain .form-control,
.productCustomise .customiseMain .customiseSelect__control {
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: 14px;
}

.productCustomise .customiseMain .customCheck {
  margin-top: 5%;
}

.productCustomise .customiseMain .customCheck p:nth-of-type(1) {
  font-size: 14px;
  margin-bottom: 14px;
  color: #707070;
}

.productCustomise .customiseMain .addcustomdesign {
  font-size: 14px;
  margin-bottom: 14px;
  font-weight: 600;
  color: #000 !important;
}

.productCustomise .customiseMain .specialRequest label {
  font-size: 14px;
  margin-left: 10px;
}

.productCustomise .customiseMain .customCheck p:nth-of-type(2) {
  font-size: 14px;
}

.productCustomise .customiseMain .specialRequest {
  margin-top: 5%;
}

.productCustomise .customiseMain .specialRequest textarea.form-control {
  height: 30px;
}

.productCustomise .customiseMain .formRowhead h3 {
  font-size: 20px;
  color: #2e2e2e;
}

.productCustomise .customiseMain .formRowhead p {
  font-size: 20px;
  color: #000;
}

.productCustomise .customiseMain .uploadDiv {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccc;
  border-radius: 5px;
  padding: 12px 6px;
  font-size: 14px;
}

.productCustomise .customiseMain .addtocartsec p {
  font-size: 13px;
  color: #707070;
  margin-bottom: 6px;
}

.productCustomise .customiseMain .addtocartsec {
  margin-top: 3%;
}

.productCustomise .customiseMain .customCheck .file-img-holder {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

/* .productCustomise .customiseMain .customCheck .file-img-holder  .img-logo {
    display: block;
    max-width: 100%;
    height: auto;
    
} */

.productCustomise .customiseMain .customCheck .file-img-holder .img-logo {
  height: 74px !important;
  width: 60px !important;
}

.cross-overlay {
  position: absolute;
  top: -10px; /* Adjust as needed */
  right: -10px; /* Adjust as needed */
  background: white; /* Background color if needed */
  border-radius: 50%;
  cursor: pointer;
}

.img-cross {
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
}

.size-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Optional: Add some spacing between items */
}
.uploadDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.size-item p {
  margin-right: 10px; /* Optional: Add spacing between the two <p> elements */
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .productCustomise .customiseMain .customiseRight .customiseHead h3 {
    font-size: 18px;
    margin-bottom: 25px;
  }

  .productCustomise .customiseMain .formRowhead h3 {
    font-size: 16px;
  }

  .productCustomise .customiseMain .formRowhead p {
    font-size: 14px;
  }

  .productCustomise .customiseMain .customiseLeft {
    margin-bottom: 25px;
  }

  .productCustomise .customiseMain .productTitle h3 {
    font-size: 15px;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .productCustomise .customiseMain .customiseRight .customiseHead h3 {
    font-size: 25px;
    margin-bottom: 25px;
  }

  .productCustomise .customiseMain .productTitle h3 {
    font-size: 15px;
  }

  .productCustomise .customiseMain .formRowhead h3 {
    font-size: 16px;
  }

  .productCustomise .customiseMain .formRowhead p {
    font-size: 14px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .productCustomise .customiseMain .customiseRight .customiseHead h3 {
    font-size: 25px;
    margin-bottom: 25px;
  }

  .productCustomise .customiseMain .productTitle h3 {
    font-size: 15px;
  }
}
