.logOutmodal .LogoutMain .yesNobtnMain .yesNobtn {
    background-color: #234C9E;
    color: #fff;
    font-size: 14px;
    margin-right: 15px;
    padding: 3px 20px;
    border: 0;
}

.logOutmodal .LogoutMain .logoutinfo p {
    font-weight: 600;
    margin-top: 0%;
    margin-bottom: 0;
    margin-left: 8px;
}

.logOutmodal .LogoutMain .yesNobtnMain {
    text-align: center;
    margin-top: 7%;
    margin-bottom: 2%;
}

.logOutmodal .logoutinfo {
    margin-top: auto;
    margin-bottom: auto;
}

.logOutmodal .modal-body {
    background-color: #fff;
    border-radius: 15px;
}

.logOutmodal .modal-content {
    border-radius: 15px;
}