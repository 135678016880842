.RequestQuote {
    background: transparent linear-gradient(180deg, #234B9E 0%, #2857B7D1 28%, #5C719C59 75%, #A5B1C831 89%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 3%;
    height: 500px;
    margin-bottom: 21%;
    position: relative;
}

.RequestQuote .requestarea {
    height: 30px;
}
.Madetoorder  .main-form-holder {
    margin: 20px 0px;
    height: 500px;
    overflow: hidden;
    overflow-y: scroll;
  }
.RequestQuote .form-control {
    border-left: 0 !important;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
    background-color: transparent;
    font-size: 14px;
}

.customCheck .image-holder {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: scroll;
    padding: 10px 0px;
}

.file-img-holder{
    margin-right:5px ;
    position: relative;
}

.RequestQuote .Onbannerform {
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 35px;
}

.RequestQuote .Onbannerform .sendbtn {
    background: #040404 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: 0;
    font-size: 14px;
}

.RequestQuote .RequestQuotehead h3 {
    font: normal normal normal 50px/24px Gotham;
    color: #FFFFFF;
    margin-bottom: 7%;
    margin-top: 3%;
  font-family: "Gothammedium";

}

.RequestQuote .Onbannerform .uploadDiv {
    font-size: 12px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 5px;
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
}

.RequestQuote .Onbannerform .uploaddesign {
    font-size: 13px;
}

.RequestQuote .madeOrderimg img {
    width: 365px;
    height: 100%;
    object-fit: contain;
}

.RequestQuote .madeOrderimg {
    text-align: end;
}


@media screen and (min-width: 0px) and (max-width: 767.98px) {


    .RequestQuote {
        height: 100%;
        margin-bottom: 6%;
    }

    .RequestQuote .Onbannerform .form-control {
        font-size: 13px;
      }
    
      .RequestQuote .Onbannerform .css-1jqq78o-placeholder {
        font-size: 13px;
      }
    
     .css-10wo9uf-option {
        font-size: 13px !important;
      }
    
      .css-1dimb5e-singleValue {
        font-size: 13px !important;
      }
    
      .css-tr4s17-option {
        font-size: 13px !important;
      }

    .RequestQuote .madeOrderimg img {
        display: none;
    }

    .RequestQuote .RequestQuotehead h3 {
        font: normal normal normal 21px/18px Gotham;
        color: #FFFFFF;
        margin-bottom: 7%;
    }

    .RequestQuote .Onbannerform {
        padding: 15px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .RequestQuote .RequestQuotehead h3 {
        font: normal normal normal 21px/18px Gotham;
        color: #FFFFFF;
        margin-bottom: 7%;
        font-size: 25px;
    }

    .RequestQuote {
        height: 100%;
        margin-bottom: 6%;
    }

    .RequestQuote .madeOrderimg img {
        width: 259px;
    }

    .RequestQuote .Onbannerform {
        padding: 15px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
    .RequestQuote .RequestQuotehead h3 {
        font: normal normal normal 21px/18px Gotham;
        color: #FFFFFF;
        margin-bottom: 7%;
        font-size: 35px;
    }

    .RequestQuote {
        height: 100%;
        margin-bottom: 6%;
    }

    .RequestQuote .madeOrderimg img {
        width: 325px;
    }

    .RequestQuote .Onbannerform {
        padding: 15px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .RequestQuote {
        margin-bottom: 23%;
    }
    
}