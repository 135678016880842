.Payment_checkout-section {
    margin: 1% 0% 10% 0%;
}

.Payment_checkout-section h4.stepform-heading {
    font-size: 25px;
    font-weight: 600;
    text-align: left;
}

.Payment_checkout-section .step-container.active .circle {
    color: #ffffff;
    border: 2px solid #234C9E;
    /* background-color: #ee9723; */
}



.Payment_checkout-section .razorpayDetails p {
    font-size: 12px;
    margin-right: 25px;
}

.Payment_checkout-section .ShippingMethod .form-check {
    font-size: 14px;
    margin-top: 27px;
}

.Payment_checkout-section .ShippingMethod h3 {
    font-size: 17px;
    font-weight: 600;
    margin-left: 4px;
}

.Payment_checkout-section .shippindmethodform .shippinfform {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    background-color: transparent;
    font-size: 14px;
}

.Payment_checkout-section .progress-bar-container .step-row {
    display: flex;
    align-items: center;
    width: 100%;
    overflow-x: auto;
}

.Payment_checkout-section .breadcrumb {
    margin-top: 15px;
}

.Payment_checkout-section .progress-bar-container .step-container {
    width: 100%;
}

.line-right {
    margin: 0px 5px;
    margin-bottom: 13px;
    width: 102%;
    border-bottom: 4px solid black;
}

.progress-bar-container .step-row .step-container .main-flex-div {
    display: flex;
    padding-bottom: 15px;
    margin-right: 12px;
}


.Payment_checkout-section .line-right.completed {
    border-bottom: 1px dashed orange;
    background-image: linear-gradient(to bottom, orange 50%, transparent 50%);
}

.Payment_checkout-section .progress-bar-container .line-left {
    width: 50px;
    height: 5px;
    background: #dfdfdf;
    position: absolute;
    top: 50%;
    right: 101%;
}

.Payment_checkout-section .progress-bar-container .step-text {
    font-size: 15px;
    color: #878787;
    font-weight: 500;
    margin: 0px 0px 0px 15px;
    text-wrap: nowrap !important;
}

.Payment_checkout-section .progress-bar-container .step-container.active .step-text {
    font-weight: 600;
    color: #000;
}

.Payment_checkout-section .signup-form {
    margin: 10px 60px 40px 15px;
}


.Payment_checkout-section .step2 .react-datepicker__input-container .react-datepicker__calendar-icon {
    right: 0px;
}

.Payment_checkout-section .step2 .react-datepicker__view-calendar-icon input {
    padding: 6px 10px 5px 25px;
    width: 100%;
    border: 1px solid #707070;
    border-radius: 5px;
}

.Payment_checkout-section label {
    font-size: 14px;
    margin-bottom: 6px;
    font-weight: 600;
}

.stepp-inner-main .step4check {
    /* margin-bottom: 25px; */
}

.Payment_checkout-section .step2 .react-datepicker-wrapper {
    width: 100%;
}

.Payment_checkout-section .displaycon {
    display: none;
}

/* step1 modal css Start*/
.stepform-modal .modal-text {
    text-align: center;
    padding: 0px 20px;
}

.stepform-modal .modal-text h4 {
    font-size: 20px;
    color: #383838;
    font-weight: bold;
}

.stepform-modal .modal-text .form-container {
    display: flex;
    justify-content: center;
    text-align: center;
}

.stepform-modal .modal-text .form-container .form-control {
    border-left: none;
    border-right: none;
    border-top: navajowhite;
    border-radius: 0px;
    border-bottom: 1px solid grey;
    width: 50%;
}

.stepform-modal .modal-text p.otp-text {
    color: #000000b2;
    font-size: 14px;
    font-weight: 400;
}

.stepform-modal .modal-text .verify-btn {
    background-color: #ff860059;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    width: 60%;
    border: none;
    padding: 5px;
}

.stepform-modal .modal-header {
    border: none;
}

.stepform-modal .modal-text .wp-icon {
    width: 20px;
    height: 20px;
}

.stepform-modal .modal-text .term-text {
    color: #00499e;
    font-size: 12px;
}

.Payment_checkout-section .circle-icon-holder .circle-icon {
    font-size: 16px;
    font-weight: 500;
    color: #ff860059;
}

.Payment_checkout-section .circle-icon-holder .circle-check {
    position: relative;
}

.Payment_checkout-section .circle-icon-holder .check-icon {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 86%;
}

.Payment_checkout-section .text-holder p {
    font-size: 15px;
    font-weight: 400;
}



.Payment_checkout-section .stepp-inner-main .upoopp {
    font-weight: normal;
    font-size: 12px;
    color: #000;
}

.Payment_checkout-section .stepp-inner-main .form-label {
    font-weight: normal;
    font-size: 12px;
    color: #000;
}

.Payment_checkout-section .stepp-inner-main .form-select::placeholder {
    font-weight: normal;
    font-size: 12px;
    color: #b1b1b1;
}

/* .Payment_checkout-section .stepp-inner-main .form-control {
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    color: #b1b1b1;
    padding: 10px;
    border-radius: 0px;
    margin-bottom: 23px;
} */



.Payment_checkout-section .stepp-inner-main .bankInput .input-group-text {
    background-color: transparent !important;
}

.Payment_checkout-section .stepp-inner-main .form-select {
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    color: #000;
    /* padding: 10px; */
}

.Payment_checkout-section .stepp-inner-main .wevvcwec {
    font-weight: 600;
    font-size: 14px;
    color: #000;
    margin-bottom: 0px;
}

.Payment_checkout-section .stepp-inner-main .nam-add-tab .nav-pills .nav-link {
    text-decoration: none;
}

.Payment_checkout-section .stepp-inner-main .nam-add-tab .nav-pills .nav-link {
    color: #000;
    background-color: #fff;
    border-radius: 15px;
    background: #fff;
    border: 1px solid #000;
    font-size: 12px;
    padding: 5px 20px;
    margin: 0px 5px;
}

.Payment_checkout-section .stepp-inner-main .nam-add-tab .nav-pills .nav-link.active {
    border: 1px solid #f88d28;
}

.Payment_checkout-section .stepp-inner-main .continue-btn {
    background: #1d44ad;
    color: #fff;
    width: 100px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    padding: 6px 20px;
    border: 1px solid #1d44ad;
}

.Payment_checkout-section .stepp-inner-main .placeOrderbtn {
    background: #1d44ad;
    color: #fff;
    width: fit-content;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    padding: 6px 20px;
    border: 1px solid #1d44ad;
}

.Payment_checkout-section .stepp-inner-main .save-btn-div {
    text-align: center;
}

.Payment_checkout-section .stepp-inner-main .back-btn {
    background: transparent;
    color: #000;
    width: 100px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    padding: 6px 20px;
    border: 1px solid #000;
    margin-right: 10px;
}

.Payment_checkout-section .stepp-inner-main .delevidiv .cardflex {
    display: flex;
}

.Payment_checkout-section .stepp-inner-main .delevidiv .img-div {
    min-width: 115px;
    height: auto;
}

.Payment_checkout-section .stepp-inner-main .delevidiv .img-div img {
    width: 112px;
    height: auto;
}

.Payment_checkout-section .stepp-inner-main .delevidiv .cont-div .nnnn {
    font-weight: 600;
    font-size: 14px;
    color: #000;
    margin-bottom: 10px;
}

.Payment_checkout-section .stepp-inner-main .continue-btn .next-fafa {
    margin-left: 10px;
}

.Payment_checkout-section .stepp-inner-main .delevidiv .cardflex .mimodd {
    font-weight: normal;
    font-size: 12px;
    color: #000;
    margin-bottom: 0px;
}

.Payment_checkout-section .stepp-inner-main .delevidiv .esttdelby {
    font-weight: normal;
    font-size: 14px;
    color: #000;
    margin-bottom: 0px;
}

.Payment_checkout-section .stepp-inner-main .delevidiv .addnott {
    font-weight: 600;
}

.Payment_checkout-section .stepp-inner-main .delevidiv {
    margin-bottom: 30px;
}

.Payment_checkout-section .stepp-inner-main .piecce-box {
    background: #f2f2f2;
    border-radius: 8px;
    padding: 10px;
}

.Payment_checkout-section .stepp-inner-main .piecce-box .colll {
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-bottom: 0px;
}

.Payment_checkout-section .stepp-inner-main .piecce-box .minus-box {
    width: 28px;
    height: 28px;
    border: 1px solid #c7c7c7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
}

.Payment_checkout-section .stepp-inner-main .piecce-box .countnumbb {
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin: 0px 10px;
}

.Payment_checkout-section .stepp-inner-main .piecce-box .countt {
    margin: 10px 0px;
}

.Payment_checkout-section .stepp-inner-main .piecce-box .totatllamt {
    font-weight: bold;
    font-size: 16px;
    color: #000;
    margin-bottom: 0px;
}

.Payment_checkout-section .stepp-inner-main .red-text {
    color: #ff4e4e;
    font-size: 12px;
    font-weight: 400;
}

.Payment_checkout-section .stepp-inner-main p.term-text {
    font-size: 12px;
    color: #707070;
}

.Payment_checkout-section .stepp-inner-main .save-btn-div .save-btn {
    border: 1px solid #000000;
    background-color: #000000;
    border-radius: 5px;
    padding: 4px 30px;
    font-size: 14px;
    font-weight: 400;
    color: #ffff;
}

.Payment_checkout-section .stepp-inner-main .save-btn-div .save-btn:hover {
    background-color: #ffff;
    color: #000;
}

.Payment_checkout-section .stepp-inner-main .addcard-icci {
    margin-left: 20px;
}

.Payment_checkout-section .stepp-inner-main .addcard-icci img {
    height: 15px;
    width: auto;
    margin: 15px 15px 15px 0px;
}

.Payment_checkout-section .nam-add-tab .nav-item {
    margin-bottom: 7px;
}

.Payment_checkout-section .stepp-inner-main .addressHead h3 {
    font-size: 18px;
    color: #333333;
    margin-bottom: 16px;
}


.Payment_checkout-section .stepp-inner-main .choss-add {
    font-weight: bold;
    font-size: 20px;
    color: #000;
    text-align: center;
}

.Payment_checkout-section .stepp-inner-main .address-div .title-div {
    position: relative;
}

.Payment_checkout-section .stepp-inner-main .address-div .title-div .dlttt-div {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: inline-end;
    position: relative;
}

.Payment_checkout-section .stepp-inner-main .address-div .title-div .dlttt-div span {
    display: none;
}

.Payment_checkout-section .stepp-inner-main .address-div .title-div .dlttt-div:hover span {
    position: absolute;
    font-size: 12px;
    text-wrap: nowrap;
    right: 0;
    top: -21px;
    display: block;
}

.Payment_checkout-section .stepp-inner-main .address-div .addd-card {
    border-radius: 5px;
    background: #fff;
    border: 1px solid #707070;
    padding: 10px;
    position: relative;
    width: 100%;
    height: 100%;
}

.Payment_checkout-section .stepp-inner-main .address-div .addd-card .card-flex {
    display: flex;
}

.Payment_checkout-section .stepp-inner-main .address-div .addd-card .card-flex .loc-img-div {
    min-width: 80px;
    position: relative;
}

.Payment_checkout-section .stepp-inner-main .address-div .addd-card .card-flex .loc-img-div .map-img {
    width: 76px;
    height: 76px;
    object-fit: cover;
}

.Payment_checkout-section .stepp-inner-main .address-div .addd-card .card-flex .loc-img-div .loc-img {
    width: 38px;
    position: absolute;
    top: 20px;
    left: 17px;
}

.Payment_checkout-section .stepp-inner-main .address-div .addd-card .card-flex .cont-div {
    padding: 0px 5px;
}

.Payment_checkout-section .stepp-inner-main .address-div .addd-card .card-flex .cont-div .hom {
    font-weight: bold;
    font-size: 18px;
    color: #323232;
    margin-bottom: 5px;
}

.Payment_checkout-section .stepp-inner-main .address-div .addd-card .card-flex .cont-div .name {
    font-weight: 500;
    font-size: 14px;
    color: #323232;
    margin-bottom: 5px;
}

.Payment_checkout-section .stepp-inner-main .address-div .addd-card .card-flex .cont-div .adddd {
    font-weight: normal;
    font-size: 13px;
    color: #323232;
    margin-bottom: 0px;
}

.Payment_checkout-section .stepp-inner-main .address-div .addd-card .form-check .form-check-input {
    float: right;
    margin-left: -1.5em;
    box-shadow: unset;
}

.Payment_checkout-section .stepp-inner-main .address-div .edit {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 14px;
    color: #1d44ad !important;
    font-weight: 600;
    text-decoration: none;
}

.Payment_checkout-section .stepp-inner-main .addd-card-new-add {
    border-radius: 5px;
    background: #f3f3f3;
    border: 1px solid #707070;
    padding: 10px;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Payment_checkout-section .stepp-inner-main .addd-card-new-add .addnewaddbtn {
    border-radius: 5px;
    background: #1d44ad;
    padding: 7px 20px;
    border-radius: 7px;
    border: 0px;
    color: #fff;
    font-size: 18px;
}

.Payment_checkout-section .stepp-inner-main .payment-optdiv {
    margin-top: 40px;
}

.Payment_checkout-section .stepp-inner-main .payment-optdiv .chjvvef {
    font-weight: normal;
    font-size: 16px;
    color: #323232;
}

.Payment_checkout-section .stepp-inner-main .payment-optdiv .payment-card {
    width: 100%;
    height: 215px;
    background: #f0f7ff;
    border-radius: 5px;
    padding: 30px 20px;
}

.Payment_checkout-section .stepp-inner-main .payment-optdiv .payment-card .paywithcredit {
    font-weight: 500;
    font-size: 16px;
    color: #000;
}

.Payment_checkout-section .stepp-inner-main .payment-optdiv .payment-card .paymopttimg {
    height: 40px;
    width: auto;
}

.Payment_checkout-section .stepp-inner-main .payment-optdiv .payment-card .form-check-input:checked {
    background-color: #000000;
    border-color: #000000;
    font-size: 18px;
}

.Payment_checkout-section .stepp-inner-main .totalorderPrice {
    font-weight: 600 !important;
    text-align: end;
}

.Payment_checkout-section .stepp-inner-main .payment-optdiv .payment-card .form-check-input {
    font-size: 18px;
    margin-right: 13px;
}

.Payment_checkout-section .stepp-inner-main .orde-status-dv {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 5px;
    width: auto;
    margin-top: 3%;
}

.Payment_checkout-section .stepp-inner-main .orde-status-dv .colwidth p {
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    color: #000;
    margin-bottom: 10px !important;
}



.Payment_checkout-section .stepp-inner-main .orderr-dettt .jhber {
    font-weight: bold;
    font-size: 14px;
    color: #000;
}

.Payment_checkout-section .stepp-inner-main .orderr-dettt {
    margin-top: 30px;
}

.Payment_checkout-section .stepp-inner-main .detailssss-flex {
    display: flex;
}

.Payment_checkout-section .stepp-inner-main .detailssss-flex .userimgg {
    width: 49px;
    height: auto;
}

.Payment_checkout-section .stepp-inner-main .detailssss-flex .img-div {
    /* min-width: 165px; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    width: 83px;
    height: 83px;
    text-align: center;
    padding-top: 12px;
}

.contdivvv {
    margin-top: auto;
    margin-bottom: auto;
}

.Payment_checkout-section .stepp-inner-main .detailssss-flex .contdivvv .name {
    font-size: 15px;
    color: #333;
    margin-bottom: 0;
    margin-left: 25px;
}

.Payment_checkout-section .stepp-inner-main .detailssss-flex .contdivvv .name span {
    font-size: 18px;
}

.Payment_checkout-section .stepp-inner-main .detailssss-flex .contdivvv .prodrsvd {
    font-weight: normal;
    font-size: 14px;
    color: #333;
}

.Payment_checkout-section .stepp-inner-main .detailssss-flex .contdivvv .price {
    font-weight: 500;
    font-size: 20px;
    color: #333;
}

.Payment_checkout-section .stepp-inner-main .detailssss-flex .contdivvv .price span {
    font-weight: 500;
    font-size: 15px;
    color: #808080;
    text-decoration: line-through;
    padding-left: 20px;
}

.Payment_checkout-section .stepp-inner-main .detailssss-flex .contdivvv .sizeeepp {
    font-size: 15px;
    color: #333;
    margin-bottom: 0px;
}

.Payment_checkout-section .stepp-inner-main .detailssss-flex .contdivvv .sizenumm {
    border-radius: 3px;
    background: #1d44ad;
    color: #fff;
    padding: 6px;
    width: fit-content;
    font-size: 14px;
}

.Payment_checkout-section .stepp-inner-main .totalprieee {
    margin-top: 50px;
}

.Payment_checkout-section .stepp-inner-main .totalprieee h6 {
    font-weight: 500;
    font-size: 16px;
    color: #000;
    margin-bottom: 0;
}

.Payment_checkout-section .stepp-inner-main .totalbg {
    background: #f5f5f5;
    padding: 20px;
}

.Payment_checkout-section .stepp-inner-main .finaladddd {
    font-weight: 500;
    font-size: 14px;
    color: #000;
}


.Payment_checkout-section .stepp-inner-main .textAreacontent {
    width: 100%;
}

.Payment_checkout-section .stepp-inner-main .earnPoints {
    font-size: 14px;
    margin-bottom: 35px;
}

.Payment_checkout-section .stepp-inner-main .checkboxfieldd {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 33px;
}

.Payment_checkout-section .stepp-inner-main .progress-bar-container .step-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.Payment_checkout-section .stepp-inner-main .progress-bar-container .step-container {
    position: relative;
    width: 100%;
    /* margin-right: 30px; */
}

.Payment_checkout-section .stepp-inner-main .progress-bar-container .step-text {
    font-size: 14px;
    color: #B2B2B2;
    font-weight: 400;
}

.addresscheck {
    margin-right: 60px;
}

.progress-bar-container .step-text.active,
.progress-bar-container .step-text.completed {
    font-weight: bold;
    color: #000000;
}

.progress-bar-container .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    color: #000;
    font-size: 20px;
    border: 2px solid #EBEBEB;
    width: 100%;
}

.stepp-inner-main .orde-status-dv .orderReviewmain {
    display: flex;
    justify-content: space-between;
}

.Payment_checkout-section .progress-bar-container .circle.completed {
    border: 2px solid #234C9E;
}

.progress-bar-container .step-container .centerDivcircle {
    margin-top: auto;
    margin-bottom: auto;
}



.progress-bar-container {
    margin: 30px 0px;
}

.stepp-inner-main .flatRate {
    text-align: center;
    margin-left: 117px;
}

.stepp-inner-main .razorpaytxt {
    margin-right: 38px;
    text-align: center;
}

.stepp-inner-main .itemPrice {
    text-align: end;
    margin-right: 32px;
}

.bankInput .form-check-input {
    margin-top: 10px;
    border: 1px solid #707070;
    border-radius: 0 !important;
}

.stepp-inner-main .prodImg1 {
    height: 74px;
    object-fit: contain;
}

.form-check-input:checked {
    background-color: #000;
    border-color: #000;
}

.prod-Img-main {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: max-content;
    padding: 18px;
}

.stepp-inner-main .bankInput .form-control {
    border-radius: 0;
    font-size: 14px;
    border: 1px solid #707070;
}

.stepp-inner-main .Prodprice {
    text-align: end;
    font-size: 14px;
    margin-right: 48px;
    margin-top: 12px;
}

.shippingMethodone {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 4px 23px;
    margin-bottom: 5%;
}

.progress-bar-container .line.active {
    width: 24px;
    background: #000;
    height: 24px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.progress-bar-container .line.completed {
    width: 24px;
    background: #000000;
    height: 24px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.progress-bar-container .line {
    width: 24px;
    background: #A4A4A4;
    height: 24px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

/* Payment_checkout shipping css */

.Addnewaddress .form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    font-size: 14px !important;
}

.Addnewaddress .form-label {
    font-size: 14px;
}

.Addnewaddress .btnsec {
    text-align: center;
}

.Addnewaddress .btnsec button {
    background: #234C9E 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: #fff;
    border: 0;
    font-size: 14px;
}

.Addnewaddress .checksRadios .form-check {
    font-size: 14px;
}

.Addnewaddress .modal-content {
    border-radius: 20px;
}

.Addnewaddress .manualSelect__control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    font-size: 14px !important;
}

.stepp-inner-main .stepOnecheckbox {
    margin-left: 11px;
}

.stepp-inner-main .bankInput p {
    margin-bottom: 0;
    font-size: 13px;
}

.stepp-inner-main .step5Paymentlink a span {
    color: #234C9E;
    margin-left: 3px;
    text-decoration: underline;
}

.stepp-inner-main .editforgettxt {
    font-size: 13px;
    margin-top: 10px;
}

.stepp-inner-main .shippingMethod .giftShipping h3 {
    font-size: 14px !important;
}

.flatrate {
    font-size: 18px;
    margin-bottom: 5%;
}

.secondscreen .form-check{
    margin-bottom: 3%;
}

.stepp-inner-main .shippingMethodone .giftShipping h3 {
    font-size: 14px !important;
    font-weight: 600;
}

.Payment_checkout-section .shippingMethod .giftShipping .form-check {
    font-size: 14px;
    margin-top: 0px !important;
}

.stepp-inner-main .editforgettxt span:nth-of-type(2) {
    color: #00499e;
    text-decoration: underline;
}

.stepp-inner-main .shippingMethod {
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 15px 25px;
    margin-top: 26px;
}

.stepp-inner-main .giftShipping {
    margin-top: 14px;
    margin-bottom: 25px;
}

.stepp-inner-main .bankTransfer {
    margin-bottom: 25px;
}

@media screen and (min-width: 0px) and (max-width: 485px) {
    .Addnewaddress .formflex {
        display: block;
    }

    .Addnewaddress .btnsec button {
        font-size: 12px !important;
        margin-top: 18px;
    }

    .ProductInner .ProductReview .reviewHeading {
        font-size: 20px;
    }
}

/* Payment_checkout shipping css */

@media screen and (min-width: 0px) and (max-width: 485px) {
    .Payment_checkout-section .line-right {
        display: none;
    }

    .Payment_checkout-section .progress-bar-container .step-text {
        font-size: 13px;
        color: #000000;
        font-weight: 500;
        margin: 0px 0px 0px 8px;
    }

    .Payment_checkout-section .signup-form {
        margin: 0px;
    }

    .Payment_checkout-section .stepp-inner-main .orde-status-dv .colwidth {
        margin-right: 20px;
    }

    .stepp-inner-main .orde-status-dv .orderReviewmain {
        flex-wrap: wrap;
    }

    .stepp-inner-main .shippingMethod .giftShipping h3 {
        font-size: 12px !important;
    }

    .Payment_checkout-section .progress-bar-container .circle-center {
        align-items: center;
    }

    .Payment_checkout-section .progress-bar-container .circle-center {
        align-items: center;
    }

    .stepp-inner-main .flatRate {
        text-align: start;
        margin-left: 0;
    }

    .stepp-inner-main .razorpaytxt {
        margin-right: 0;
    }


    .Product_total_seccc .proddd-nnnn .prood-nname {
        font-size: 13px;
    }

    .Product_total_seccc .protect-odd-div ul li {
        font-size: 13px;
    }

    .nam-add-tab .nav.nav-pills .nav-item {
        width: 116px;
        margin: 4px 0px;
    }

    .Payment_checkout-section .stepp-inner-main .continue-btn {
        font-size: 13px;
        padding: 5px 11px;
    }

    .progress-bar-container .line.active {
        width: 20px !important;
        height: 20px !important;
    }

    .Payment_checkout-section .progress-bar-container .circle-center {
        align-items: center;
        margin-top: auto;
        margin-bottom: auto;
    }

    .progress-bar-container .line {
        width: 20px !important;
        height: 20px !important;
    }

    .progress-bar-container .step-container .centerDivcircle {
        margin-top: auto;
        margin-bottom: auto;
        text-wrap: nowrap;
    }

    .Payment_checkout-section .progress-bar-container .step-row {
        overflow-x: auto;
        padding-bottom: 8px;
    }

    .Payment_checkout-section .stepp-inner-main .back-btn {
        width: 116px;
        padding: 5px 20px;
    }

    .Payment_checkout-section .stepp-inner-main .continue-btn {
        width: 120px;
        padding: 5px 15px;
    }

    .Payment_checkout-section .stepp-inner-main .payment-optdiv .payment-card .paymopttimg {
        width: 80px !important;
    }

    .Payment_checkout-section .stepp-inner-main .orde-status-dv .colwidth {
        margin-bottom: 20px;
    }

    .Payment_checkout-section .stepp-inner-main .detailssss-flex .contdivvv .name {
        font-size: 15px !important;
    }

    .Payment_checkout-section .stepp-inner-main .detailssss-flex .contdivvv .name span {
        font-size: 14px !important;
    }

    .Payment_checkout-section .stepp-inner-main .detailssss-flex .contdivvv .prodrsvd {
        font-size: 11px !important;
    }

    .Payment_checkout-section .stepp-inner-main .detailssss-flex .contdivvv .price {
        font-size: 14px !important;
    }

    .Payment_checkout-section .stepp-inner-main .detailssss-flex .contdivvv .price span {
        font-size: 11px !important;
        padding-left: 8px !important;
    }

    .Payment_checkout-section .stepp-inner-main .addd-card-new-add .addnewaddbtn {
        font-size: 11px;
    }

    .Payment_checkout-section .stepp-inner-main .address-div .addd-card .card-flex .cont-div .adddd {
        font-size: 12px;
    }

    .Payment_checkout-section .stepp-inner-main .orde-status-dv .colwidth p {
        font-size: 13px;
        margin-bottom: 0 !important;
    }

    .Payment_checkout-section .stepp-inner-main .address-div .addd-card .card-flex .cont-div .hom {
        font-size: 15px;
    }

    .Payment_checkout-section .stepp-inner-main .address-div .addd-card .card-flex .cont-div .name {
        font-size: 13px;
    }

    .Payment_checkout-section .stepp-inner-main .totalprieee h6 {
        font-size: 13px;
    }

    .Payment_checkout-section .stepp-inner-main .finaladddd {
        font-size: 13px;
    }

    .Payment_checkout-section .stepp-inner-main .payment-optdiv .payment-card .form-check-input {
        margin-right: 6px;
    }

    .Payment_checkout-section .stepp-inner-main .form-label {
        text-wrap: nowrap;
    }

    .Payment_checkout-section .stepp-inner-main .form-select {
        padding: 6px;
    }

    .Addnewaddress .form-control {
        font-size: 12px !important;
    }

    .Payment_checkout-section .stepp-inner-main .totalprieee {
        margin-top: 24px;
    }

    .Payment_checkout-section label {
        font-size: 12px;
    }

    .Payment_checkout-section .stepp-inner-main .flatrateMain {
        margin-top: 16px;
    }

}

@media screen and (min-width: 486px) and (max-width: 767px) {
    .Payment_checkout-section .line-right {
        display: none;
    }

    .ProductInner .ProductReview .reviewHeading {
        margin-top: 5%;
    }

    .Payment_checkout-section .stepp-inner-main .payment-optdiv .payment-card .form-check-input {
        margin-right: 6px;
    }

    .stepp-inner-main .flatRate {
        text-align: start;
        margin-left: 0px;
    }

    .Payment_checkout-section label {
        font-size: 12px;
    }

    .Payment_checkout-section .stepp-inner-main .totalprieee {
        margin-top: 24px;
    }

    .Payment_checkout-section .stepp-inner-main .finaladddd {
        font-size: 13px;
    }

    .Payment_checkout-section .stepp-inner-main .totalprieee h6 {
        font-size: 13px;
    }

    .Payment_checkout-section .stepp-inner-main .address-div .addd-card .card-flex .cont-div .name {
        font-size: 13px;
    }

    .Payment_checkout-section .stepp-inner-main .address-div .addd-card .card-flex .cont-div .hom {
        font-size: 15px;
    }

    .Payment_checkout-section .stepp-inner-main .address-div .addd-card .card-flex .cont-div .adddd {
        font-size: 12px;
    }

    .progress-bar-container .line.completed {
        width: 20px;
        height: 20px;
    }

    .progress-bar-container .line.active {
        width: 20px;
        height: 20px;
    }

    .progress-bar-container .line {
        width: 20px;
        height: 20px;
    }

    .Payment_checkout-section .signup-form {
        margin: 0px;
    }

    .Payment_checkout-section .progress-bar-container .step-text {
        font-size: 13px;
        margin: 0px 0px 0px 4px;
    }

    .nam-add-tab .nav.nav-pills .nav-item {
        width: 116px;
        margin: 4px 0px;
    }

    .stepp-inner-main .razorpaytxt {
        margin-right: 0px;
        text-align: end;
    }

    .Payment_checkout-section .stepp-inner-main .nam-add-tab .nav-pills .nav-link {
        text-align: center;
    }

    .Payment_checkout-section .stepp-inner-main .continue-btn {
        font-size: 14px;
    }

    .Payment_checkout-section .stepp-inner-main .detailssss-flex .contdivvv .name {
        font-size: 15px !important;
    }

    .Payment_checkout-section .stepp-inner-main .detailssss-flex .contdivvv .price {
        font-size: 14px !important;
    }

    .Payment_checkout-section .stepp-inner-main .addd-card-new-add .addnewaddbtn {
        font-size: 11px;
    }

    .Payment_checkout-section .stepp-inner-main .orde-status-dv .colwidth p {
        font-size: 13px;
    }

    .Payment_checkout-section .stepp-inner-main .form-label {
        text-wrap: nowrap;
    }

    .Addnewaddress .form-control {
        font-size: 14px !important;
    }

    .Payment_checkout-section .stepp-inner-main .flatrateMain {
        margin-top: 16px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

    .Payment_checkout-section .progress-bar-container .circle-center {
        align-items: center;
    }

    .Payment_checkout-section .razorpayDetails p {
        margin-right: 0px;
    }

    .Payment_checkout-section .signup-form {
        margin: 0px;
    }

    .stepp-inner-main .razorpaytxt {
        margin-right: 0px;
        text-align: end;
    }

    .Addnewaddress .formflex {
        display: block;
    }

    .Payment_checkout-section .stepp-inner-main .flatrateMain {
        margin-top: 16px;
    }

    .line-right {
        margin: 0px 11px;
        margin-bottom: 11px;
        width: 109px;
    }

    .Payment_checkout-section .progress-bar-container .step-text {
        font-size: 14px;
        margin: 0px 0px 0px 4px;
    }

    .Payment_checkout-section .stepp-inner-main .payment-optdiv .payment-card .paymopttimg {
        width: 98px !important;
    }

    .stepp-inner-main .flatRate {
        text-align: start;
        margin-left: 0px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .line-right {
        width: 64px;
    }


    .stepp-inner-main .flatRate {
        text-align: center;
        margin-left: 58px;
    }

    .Payment_checkout-section .signup-form {
        margin: 10px 0px 24px 15px;
    }

    .Payment_checkout-section .progress-bar-container .step-text {
        font-size: 14px;
    }

    .Payment_checkout-section .stepp-inner-main .nam-add-tab .nav-pills .nav-link {
        margin: 4px 5px;
    }

    .Product_total_seccc .proddd-nnnn .prood-nname {
        font-size: 13px;
        line-height: 17px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .line-right {
        width: 119px;
    }

    .stepp-inner-main .flatRate {
        text-align: center;
        margin-left: 88px;
    }

    .Payment_checkout-section .signup-form {
        margin: 10px 0px 24px 15px;
    }

    .Payment_checkout-section .progress-bar-container .step-text {
        font-size: 14px;
    }

    .Payment_checkout-section .stepp-inner-main .nam-add-tab .nav-pills .nav-link {
        margin: 4px 5px;
    }

    .Product_total_seccc .proddd-nnnn .prood-nname {
        font-size: 13px;
        line-height: 17px;
    }
}