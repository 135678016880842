/* .ButtonMain .breadcrumb-item{
font-size: 12px;
} */
.ButtonMain li.breadcrumb-item {
    font-size: 12px !important; 
    font-family: 'Gothamregular' ;
    
}
 
.ButtonMain li.breadcrumb-item:last-child {
    font-weight: bold;
    font-family: 'Gothammedium';
}