.LegalNotice {
    margin: 3%;
}

.LegalNotice .legalHead h3 {
    margin-bottom: 2%;
    font-family: 'Gothammedium';
    font-weight: 300;
    color: #1e1e1e;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: 71px;
    letter-spacing: -1.32px;
    padding-bottom: 25px;
}

.LegalNotice .legalHead p {
    font-size: 17px;
    text-align: justify;
    font-family: 'Gothamregular';
    color: #2E2E2E;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .LegalNotice .legalHead h3 {
        font-size: 24px;
        margin-top: 21px;
        margin-bottom: 5%;
    }

    .LegalNotice .legalHead p {
        font-size: 12px;

    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .LegalNotice .legalHead h3 {
        font-size: 40px;
    }

    .LegalNotice .legalHead p {
        font-size: 13px;
    }
}