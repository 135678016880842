.BestMatchedMain .BestMatched .bestMatchedhead h3 {
    font-size: 25px;
    margin-bottom: 20px;
    margin-top: 25px;
}

.BestMatchedMain .mySwiper1 .swiper-pagination-bullet-active {
    background: #25304E 0% 0% no-repeat padding-box !important;
    border-radius: 50px;
    width: 32px !important;
    height: 5px;
}

.BestMatchedMain .mySwiper1 .swiper-pagination-bullet {
    background: #697AA7 0% 0% no-repeat padding-box;
    border-radius: 50px;
    opacity: 1;
    width: 20px;
    height: 5px;

}


@media screen and (min-width: 0px) and (max-width: 768px) {
    .BestMatchedMain .BestMatched .bestMatchedhead h3 {
        font-size: 20px;
    }
}