.SubmitSuggestion {
    background: url("../../../public/assest/image/Suggestion/suggestionBanner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 309px;
    position: relative;
    margin-bottom: 20%;
}

.SubmitSuggestion .formCard {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 24px #0000002B;
    border-radius: 6px;
    padding: 15px;
}

.SubmitSuggestion .suggestionForm {
    position: absolute;
    width: 100%;
    top: 50%;
}

.SubmitSuggestion .form-control {
    font-size: 13px;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
}

.SubmitSuggestion .suggestionForm .react-tel-input .form-control {
    width: 100%;
}

.SubmitSuggestion .submitbtnsec {
    margin-bottom: 3%;
}

.SubmitSuggestion .submitbtnsec button {
    font-size: 14px;
    background: #234C9E 0% 0% no-repeat padding-box;
    border: 1px solid #234C9E;
    border-radius: 5px;
    color: #fff;
    padding: 6px 22px;
    margin-bottom: 2%;
    margin-top: 16%;
}

.SubmitSuggestion .submitbtnsec button:hover {
    background: #000 0% 0% no-repeat padding-box;
    border: 1px solid #000;
}

.SubmitSuggestion .suggestiontxt p:nth-of-type(1) {
    font: normal normal normal 50px/55px Gotham;
    color: #707070;
}

.SubmitSuggestion .suggestiontxt p:nth-of-type(2) {
    font: normal normal bold 50px/55px Gotham;
    color: #707070;
}

.SubmitSuggestion .suggestiontxt {
    margin-top: 23%;
    margin-left: 12%;
}


@media screen and (min-width: 0px) and (max-width: 767.98px) {
    .SubmitSuggestion {
        height: 100%;
        padding-top: 10%;
    }

    .SubmitSuggestion .suggestionForm {
        position: unset;
        width: 100%;
    }

    .SubmitSuggestion .suggestiontxt {
        margin-top: 4%;
        margin-left: 0%;
        text-align: center;
    }

    .SubmitSuggestion .suggestiontxt p:nth-of-type(1) {
        font: normal normal normal 28px/25px Gotham;
        margin-bottom: 0;
    }

    .SubmitSuggestion .suggestiontxt p:nth-of-type(2) {
        font: normal normal bold 28px/55px Gotham;
        color: #707070;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .SubmitSuggestion {
        height: 100%;
        padding-top: 23%;
        margin-bottom: 40%;
    }

    .SubmitSuggestion .suggestionForm {
        top: 15%;
    }

    .SubmitSuggestion .suggestiontxt {
        margin-top: 54%;
        margin-left: 0%;
    }

    .SubmitSuggestion .suggestiontxt p:nth-of-type(1) {
        font: normal normal normal 35px/35px Gotham;
        margin-bottom: 0;
    }

    .SubmitSuggestion .suggestiontxt p:nth-of-type(2) {
        font: normal normal bold 35px/55px Gotham;
        color: #707070;
    }
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
    .SubmitSuggestion {
        height: 100%;
        padding-top: 23%;
        margin-bottom: 30%;
    }

    .SubmitSuggestion .suggestionForm {
        top: 50%;
    }

    .SubmitSuggestion .suggestiontxt {
        margin-top: 24%;
        margin-left: 0%;
    }

    .SubmitSuggestion .suggestiontxt p:nth-of-type(1) {
        font: normal normal normal 35px/35px Gotham;
        margin-bottom: 0;
    }

    .SubmitSuggestion .suggestiontxt p:nth-of-type(2) {
        font: normal normal bold 35px/55px Gotham;
        color: #707070;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .SubmitSuggestion {
        margin-bottom: 23%;
    }
}

@media screen and (min-width: 2200px) and (max-width: 2560px) {
    .SubmitSuggestion {
        margin-bottom: 11%;
    }
}