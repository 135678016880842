.Resumemodal .resumeHead h3 {
    font-size: 16px;
}

.Resumemodal .resumeHead {
    margin-bottom: 5%;
}

.Resumemodal .resumeHead p {
    font-size: 14px;
    margin-bottom: 0;
}

.Resumemodal .uploadbtn {
    background: #173F8F 0% 0% no-repeat padding-box;
    border: 1px solid #173F8F;
    border-radius: 5px;
    margin-bottom: 10px;
}

.Resumemodal .uploadResumebtn p {
    font-size: 12px;
    color: #8D8D8D;
}

.Resumemodal .uploadResumebtn .uploadLink p {
    font-size: 15px;
    color: #000;
    text-align: end;
}

.Resumemodal .uploadResumebtn .uploadLink  p:hover {
    color: #1967D3;
}

.Resumemodal .resumeHead .trashIcon {
    background: #DBDBDB 0% 0% no-repeat padding-box;
    padding: 5px;
    font-size: 9px;
    border-radius: 50%;
    margin: 4px;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .Resumemodal .uploadbtn {
        font-size: 13px;
    }
}