@font-face {
  font-family: Classy Vogue, Regular;
  src: url("../../../public/assest/image/Classyvogueregular.ttf");
}

.welcome-page {
  position: relative;
  width: 100%;
  height: 100%;
}

.welcome-page .video-main .partation-holder {
  position: relative;
}

.welcome-page .video-main .partation-holder .overlay-holder {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgb(0 0 0 / 44%) 0%, #09152a 100%);
}

.welcome-page .video-main .content-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.welcome-page .video-main .video-bg {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  object-fit: fill;
  margin-bottom: -7px;
}


.welcome-page .landing_img_holder .landing_img {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  object-fit: fill;
}

.welcome-page .video-main .black-overlay {
  position: absolute;
  top: 0px;
  z-index: 0;
  width: 100%;
  height: 51%;
  background: transparent
    linear-gradient(
      180deg,
      #234b9e 0%,
      #2857b7d1 37%,
      #5c719c59 63%,
      #212d4600 100%
    )
    0% 0% no-repeat padding-box;
  opacity: 0.9;
}

.welcome-page .overflow-content {
  position: absolute;
  top: 7%;
  width: 100%;
  /* height: 90%; */
  text-align: center;
  z-index: 99;
}

.welcome-page .headlogo {
  width: 176px;
}

.welcome-page .heading-text .explore-btn {
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 6px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  color: #ffffff;
  font-size: 20px;
  padding: 7px 30px;
  position: relative;
  z-index: 1;
  /* transform: translateY(20px); */
  transition: transform 0.2s ease;
  bottom: -20px;
  transition: 0.3s;
}

.welcome-page .partation-holder:hover .explore-btn {
  /* transform: translateY(-40px); */
  transition: transform 0.2s ease;
  bottom: 20px;
  transition: 0.3s;
}

.welcome-page .heading-text1 .explore-btn {
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 6px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  color: #ffffff;
  font-size: 20px;
  padding: 7px 30px;
  position: relative;
  z-index: 1;
  transform: translateY(20px);
  transition: transform 0.2s ease;
}

.welcome-page .heading-text1:hover .explore-btn {
  transform: translateY(-40px);
  transition: transform 0.2s ease;
}

.main-row-secccc {
  position: absolute;
  width: 100%;
  /* bottom: 37%; */
  height: 100%;
  top: 0%;
}

.main-row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* .welcome-page .heading-text{
    height: 100%;
    width: 50%;
} */
.welcome-page .partation-holder:hover .overlay-text-color1 {
  position: absolute;
  top: 0px;
  z-index: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent
    linear-gradient(180deg, #09152a 0%, #0f3f75 57%, #09152a 100%) 0% 0%
    no-repeat padding-box;
  opacity: 0.95;
}

.welcome-page .heading-text1:hover .overlay-text-color2 {
  position: absolute;
  top: 0px;
  z-index: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent
    linear-gradient(180deg, #09152a80 0%, #0f3f75 57%, #09152a 100%) 0% 0%
    no-repeat padding-box;
  opacity: 0.95;
}

.welcome-page .heading-text h2 {
  color: #fff;
  font-size: 40px;
  font-family: 600;
  transition: font-size 0.2s ease, margin-bottom 0.2s ease;
  position: relative;
  z-index: 1;
  bottom: 0px;
}
.welcome-page .partation-holder:hover h2 {
  font-size: 50px;
  position: relative;
  bottom: 60px;
  transition: 0.3s;
}

.welcome-page .heading-text {
  margin-top: 15%;
}

.welcome-page .heading-text1 {
  margin-top: 15%;
}

.welcome-page .heading-text1 h2 {
  color: #fff;
  font-size: 40px;
  font-family: 600;
  transition: font-size 0.2s ease, margin-bottom 0.2s ease;
  position: relative;
  z-index: 1;
  bottom: 0px;
  transition: 0.3s;
}

.welcome-page .heading-text1:hover h2 {
  font-size: 50px;
  position: relative;
  bottom: 60px;
  transition: 0.3s;
}

.welcome-page .partation-holder:hover .text-holder p {
  color: #fff;
  text-transform: capitalize;
  font-size: 30px;
  font-family: "Classy Vogue";
  display: block;
  transition: font-size 0.2s ease, display 0.2s ease;
  position: relative;
  z-index: 1;
  width: 50%;
  margin: auto;
}

.welcome-page .heading-text .text-holder p {
  color: #fff;
  text-transform: capitalize;
  font-size: 30px;
  font-family: "Classy Vogue";
  display: none;
  transition: font-size 0.2s ease, display 0.2s ease;
  position: relative;
  z-index: 1;
  /* margin-top: 17px; */
}

.welcome-page .heading-text1:hover .text-holder p {
  display: block;
  transition: display 0.2s ease;
  position: relative;
  z-index: 1;
}

.welcome-page .heading-text1 .text-holder p {
  color: #fff;
  text-transform: capitalize;
  font-size: 30px;
  font-family: "Classy Vogue";
  display: none;
  transition: display 0.2s ease;
  position: relative;
  z-index: 1;
  margin-top: 17px;
}

.welcome-page .videodesktop {
  display: block;
}

.welcome-page .videoMobile {
  display: none;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .welcome-page .heading-text h2 {
    font-size: 30px;
  }

  .welcome-page .partation-holder:hover h2 {
    font-size: 40px;
  }

  .welcome-page .heading-text1 h2 {
    font-size: 30px;
  }

  .welcome-page .heading-text1:hover h2 {
    font-size: 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .welcome-page .heading-text h2 {
    font-size: 20px;
  }

  .welcome-page .partation-holder:hover h2 {
    font-size: 30px;
  }

  .welcome-page .heading-text1 h2 {
    font-size: 20px;
  }

  .welcome-page .heading-text1:hover h2 {
    font-size: 30px;
  }

  .welcome-page .heading-text .explore-btn {
    font-size: 16px;
  }

  .welcome-page .heading-text1 .text-holder p {
    font-size: 20px;
  }

  .welcome-page .heading-text .text-holder p {
    font-size: 20px;
  }
}

@media (min-width: 0px) and (max-width: 767.98px) {
  .welcome-page .heading-text h2 {
    font-size: 20px;
  }
  .welcome-page .headlogo {
    width: 200px;
  }

  .welcome-page .video-bg-mobile {
    height: 50%;
  }

  .welcome-page .heading-text1:hover .overlay-text-color2 {
    display: none;
  }

  .welcome-page .partation-holder:hover h2 {
    font-size: 20px;
  }

  .welcome-page .heading-text1:hover .text-holder p {
    display: block !important;
  }

  .welcome-page .heading-text1 h2 {
    font-size: 15px;
  }

  .welcome-page .heading-text1:hover h2 {
    font-size: 20px;
  }

  .welcome-page .heading-text .explore-btn {
    font-size: 14px;
    padding: 3px 15px;
    margin-bottom: 15px;
  }

  .welcome-page .heading-text1 .explore-btn {
    font-size: 14px;
    padding: 3px 15px;
    margin-bottom: 15px;
  }

  .welcome-page .partation-holder:hover .explore-btn {
    transform: translateY(0px);
  }

  .welcome-page .heading-text1:hover .explore-btn {
    transform: translateY(0px);
  }

  .welcome-page .heading-text1 .text-holder p {
    font-size: 15px;
  }

  .welcome-page .heading-text .text-holder p {
    font-size: 15px;
  }

  .welcome-page .videoMobile {
    display: block;
  }

  .welcome-page .heading-text .text-holder p {
    display: none;
    margin-top: 17px;
  }

  .welcome-page .heading-text1:hover h2 {
    font-size: 15px;
  }

  .welcome-page .partation-holder:hover h2 {
    font-size: 30px;
  }

  .welcome-page .videodesktop {
    /* display: none; */
  }

  .welcome-page .partation-holder:hover .text-holder p {
    font-size: 20px;
    margin-top: 0;
  }

  .welcome-page .partation-holder:hover h2 {
    margin-bottom: 4%;
  }

  .welcome-page .heading-text1 .text-holder p {
    display: block;
  }

  .welcome-page .heading-text {
    margin-top: 20%;
  }

  .welcome-page .partation-holder:hover .overlay-text-color1 {
    position: absolute;
    top: 0px;
    z-index: 0;
    width: 100%;
    height: 100%;
    /* background: transparent; */
    opacity: 0.95;
    /* display: none; */
  }
}
@media (min-width: 0px) and (max-width: 575px) {
  .welcome-page .video-main .video-bg {
    height: 430px;
    min-height: 100%;
  }

  .welcome-page .landing_img_holder .landing_img {
    height: 430px;
    min-height: 100%;
  }
  

  .welcome-page .video-main .content-holder {
    height: 100%;
  }
}

.welcome-page .main-heading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
