
.Sesonal_offers_swiper {
    padding-bottom: 50px;
}

.Sesonal_offers_modal .succussFlex {
    display: unset;
    justify-content: center;
}

.Sesonal_offers_modal .offerImg{
    width: 100%;
    height: 100%;
}
