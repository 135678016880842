.Main-header {
    position: sticky;
    z-index: 999;
    top: 0%;
    width: 100%;
}

.Main-header .second-header .shop-by-nav .shop-product-cat_heading {
    text-transform: uppercase;
    color: #000;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 12px;
    text-transform: capitalize;
    font-family: Lato, sans-serif
}

.Main-header .second-header .shop-by-nav .dropdown-menu .tooltip-column .shop-product-cat-item {
    font-family: Lato, sans-serif !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 27px !important;
}


.Main-header .second-header .shop-by-nav .dropdown-menu .tooltip-column .shop-product-cat-item:hover {
    opacity: 0.7 !important;
    background: none !important;
    color: #000 !important;
}

.Main-header .top-header {
    background-color: #234C9E;
    padding: 10px;
}

.Main-header .top-header span {
    color: #FFF;
    font-size: 14px;
    margin-bottom: 0%;
}

.Main-header .btn:hover {
    color: #000;
}

.Main-header .usertogggle {
    background-color: transparent !important;
    color: #000 !important;
    border: 0;
    padding: 0;
}

.Main-header .second-header .usertogggle:hover .Main-header .second-header .profile-logout-drop  {
    display: block !important;
    background-color: #234C9E;
}


/* .Main-header .second-header .profile-logout-drop {
    width: 162px !important;
    top: 77px !important;
} */

.Main-header .second-header .profile-logout-drop {
    width: 162px !important;
    top: -195% !important;
    left: 260% !important;
}

.Main-header .top-header .marquee-content {
    margin-right: 50px;
    display: inline-block;
    /* Adjust this value to control the spacing */
}

.Main-header .second-header {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #00000029;
    padding-bottom: 5px;
}

.Main-header .second-header .top-second-header {
    padding-top: 24px;
}

.Main-header .headlogo {
    width: 100%;
    height: 57px;
    object-fit: contain;
}

.Main-header .second-header .input-group-text {
    background-color: transparent;
    border-left: none;
    border-radius: 5px;
    border: none;
}

.Main-header .second-header .icon-cart {
    margin-right: 28px;
    cursor: pointer;
}

.Main-header .second-header .form-control {
    border-right: none;
    background: none;
    border-radius: 5px;
    font-size: 13px;
    color: #2E2E2E;
    border: none;
    padding: 0px 19px;
}

.Main-header .second-header .nav-link {
    font-size: 14px;
    color: #000;
}

.Main-header .second-header .nav-link:hover {
    font-family: 'GothamBold';
}

.Main-header .second-header .nav-link.active {
    font-family: 'GothamBold';
}


.Main-header .second-header .dropdown-menu {
    background-color: #FFF;
    border-radius: 0%;
    width: 300px;
    padding-top: 0%;
    padding-bottom: 0%;
    z-index: 1;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    top: 44px;
}

.Main-header .second-header .dropdown-item:hover {
    background: #2E2E2E 0% 0% no-repeat padding-box;
    color: #fff !important;
    font-size: 15px;
    padding: 10px;
}

.Main-header .second-header .dropdown-item:active {
    background: #2E2E2E 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 15px;
    padding: 10px;
}

.Main-header .second-header .dropdown-item {
    color: #000;
    font-size: 15px;
    padding: 10px;
    white-space: normal;
}

.desktop-view-search {
    display: block;
}

/* .Main-header .second-header .shop-by-nav .dropdown-menu {
    width: 500px;
} */


/* .Main-header .second-header .shop-by-nav .dropdown-menu {
    width: 0px;
} */


.Main-header .second-header .shop-by-nav .dropdown-menu .shop-by-tooltip {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
    background-color: white;
    border: 1px solid #ddd;
    position: absolute;
    max-width: 1600px;
    top: 100%;
    box-sizing: border-box;
    overflow: hidden;
    right: 50%;
    overflow-y: scroll;
    height: 500px;
    /* width: 31vw; */
    /* transform: translateX(-0%); */
}

.Main-header .second-header .shop-by-nav .dropdown-menu .shop-product-tooltip {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 20px;
    padding: 20px;
    background-color: white;
    border: 1px solid #ddd;
    position: absolute;
    max-width: 1600px;
    top: 100%;
    box-sizing: border-box;
    overflow: hidden;
    right: -32%;
    overflow-y: scroll;
    height: 500px;
    /* max-width: 1600px; */
    /* width: 149vw; */
}





/* .Main-header .second-header .shop-by-nav .dropdown-menu .tooltip-column .dropdown-item {
    font-size: 12px;
    padding: 10px;
} */




.Main-header .second-header .shop-by-nav .dropdown-menu .tooltip-column {
    width: 150px;
}

.Main-header .second-header .shop-by-nav .dropdown-menu .tooltip-column h4 {
    font-size: 15px;
    padding: 10px;
    font-weight: 600;
}

.Main-header .second-header .shop-by-nav .dropdown-menu .tooltip-column .dropdown-item {
    font-size: 12px;
    padding: 0px 7px 0px 0px;
}


.Main-header .second-header .shop-by-nav .dropdown-menu .tooltip-column .dropdown-item:hover {
    background: #2E2E2E 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 12px;
    padding: 0px 7px 0px 0px;
}


.desktop-view-search .input-group {
    border: 2px solid #ccc;
    height: 40px;
    border-radius: 50px;
    background: #f8f9fa;
}

.mobile-view-search {
    display: none;
}

.bannerOverlay {
    position: absolute;
    top: 0;
    left: 0;
    /* background: transparent linear-gradient(90deg, #FFFFFF 0%, #FFFFFFA8 30%, #FFFFFF5E 44%, #FFFFFF00 100%) 0% 0% no-repeat padding-box; */
    width: 100%;
    height: 100%;
}

.Main-header .dropdown-toggle::after {
    /* margin-top: 10px; */
}

.Main-header .icon-cart .dropdown-toggle::after {
    display: none;
}

.mobileSecond-header {
    display: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #00000029;
}

.Main-header .mobileSecond-header .headlogo {
    width: 100%;
    height: 44px;
    object-fit: contain;
    margin-top: 5px;
}

.mobileSecond-header .barsIcon {
    margin-left: 7px;
    font-size: 20px;
}

.mobileSecond-header .bageicn {
    position: absolute;
    top: -4px;
    left: 9px;
    background-color: #aa2422;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    color: #fff;
    border-radius: 50%;
    width: 13px;
    height: 13px;
}

.second-header .top-second-header .cart-logo {
    margin-top: 7px;
}

.mobileSecond-header .bageicn_mian {
    position: relative;
}

.mobileSecond-header .icon-cart {
    margin: -2px 0px 0px 21px;
}



.mobileSecond-header .cart-logo .commonIcon {
    color: #2E2E2E;
    font-size: 14px;
}

.Main-header .mobileSecond-header .dropdown-menu {
    background-color: #FFF;
    border-radius: 0%;
    width: 300px;
    padding-top: 0%;
    padding-bottom: 0%;
    z-index: 1;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
}

.commonLink {
    margin-top: 1px;
    text-wrap: nowrap;
}

.Customise-link {
    color: #234C9E !important;
    font-weight: 900 !important;
    font-size: 17px !important;
    padding: 5px 10px !important;
    font-family: 'GothamBold';
}

.offer-link {
    color: red !important;
    font-family: 'GothamBold';
}

.Customise-heartbeat {
    -webkit-animation: Customise-heartbeat 1.5s ease-in-out infinite both;
    animation: Customise-heartbeat 1.5s ease-in-out infinite both;
}



@-webkit-keyframes Customise-heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes Customise-heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}


.Main-header .mobileSecond-header .profile-logout-drop {
    width: 162px !important;
    top: 2px !important;
}

.Main-header .mobileSecond-header .dropdown-item {
    font-size: 13px;
    padding: 6px 15px;
}

/* .navbar-nav .dropdown:hover .dropdown-menu {
    display: block;
  } */

/* Ensure dropdown menu is hidden by default */
/* .dropdown-menu {
    display: none;
  } */

/* Show dropdown menu on hover */
.dropdown:hover .dropdown-menu {
    /* display: block; */
}





/* Optional: adjust the position if necessary */
/* .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
  } */


@media(min-width:0px) and (max-width:485px) {
    .Main-header .cart-logo {
        justify-content: space-around !important;
    }

    .Main-header .top-header span {
        font-size: 9px;
    }

    .Main-header .top-header {
        background-color: #234C9E;
        padding: 0px;
    }
}

@media(min-width:486px) and (max-width:575px) {
    .Main-header .cart-logo {
        justify-content: space-evenly !important;
    }

    .Main-header .top-header span {
        font-size: 9px;
    }

    .Main-header .top-header {
        background-color: #234C9E;
        padding: 0px;
    }
}

@media(min-width: 576px) and (max-width: 767px) {
    .Main-header .cart-logo {
        justify-content: end;
    }

    .Main-header .top-header span {
        font-size: 9px;
    }

    .Main-header .top-header {
        background-color: #234C9E;
        padding: 0px;
    }
}

@media(min-width:0px) and (max-width:991px) {
    .Main-header .second-header .dropdown-menu {
        height: 200px;
        overflow: hidden;
        overflow-y: scroll;
    }

    .Main-header .second-header .navbar {
        justify-content: end;
    }

    .Main-header .second-header .icon-cart {
        margin-right: 16px;
    }

    .Main-header .second-header .headlogo {
        width: 160px;
    }

    .desktop-view-search {
        display: none;
    }

    .mobile-view-search {
        display: block;
    }

    .mobileSecond-header {
        display: block;
    }

    .second-header {
        display: none;
    }

    .Main-header .cart-logo {
        justify-content: end;
    }


}

@media(min-width:0px) and (max-width:767.98px) {
    .desktop-view-search {
        display: none;
    }

    .mobile-view-search {
        display: block;
    }

    .mobileSecond-header {
        display: block;
    }

    .second-header {
        display: none;
    }

    .Main-header .second-header .icon-cart {
        margin-right: 7px;
    }

    .Main-header .second-header .headlogo {
        width: 119px;
    }

    .Main-header .second-header .input-group-box {
        /* position: inherit; */
        left: 6px;
        top: 94px;
        width: 98%;
    }

    .Main-header .second-header .form-control {
        border-right: 1px solid #dee2e6;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 5px;
        font-size: 13px;
        color: #2E2E2E;
    }

    .Main-header .second-header .top-second-header {
        padding-top: 17px;

    }

    .Main-header .second-header .nav-link {
        font-size: 14px;
        margin-left: 10px;
    }


    .Main-header .second-header .nav-link.active {
        font-size: 14px;
        font-weight: 600;
        margin-left: 10px;
    }

    .Main-header .navbar-toggler-icon {
        font-size: 15px;
    }

    .Main-header .navbar-toggler {
        margin-left: 11px;

    }


    .Main-header .second-header .profile-logout-drop {
        width: 162px !important;
        top: 66px !important;
    }
}

@media(min-width:768px) and (max-width:992px) {}

@media(min-width:992px) and (max-width:1200px) {
    .Main-header .second-header .icon-cart {
        margin-right: 23px;
    }

    .Main-header .second-header .nav-link {
        font-size: 12px;
    }

    .Main-header .second-header .nav-link.active {
        font-size: 12px;
        font-weight: 600;
    }

    .commonLink {
        margin-top: 3px;
    }

    /* .Main-header .second-header .shop-by-nav .dropdown-menu .shop-by-tooltip {
        max-width: 1600px;
        overflow-y: scroll;
        height: 500px;
        width: 50vw;
        transform: translateX(45%);
    } */

    .Main-header .second-header .shop-by-nav .dropdown-menu .shop-by-tooltip {
        overflow-y: scroll;
        height: 500px;
        width: -moz-fit-content;
        width: fit-content;
        right: -34%;
    }

    .Main-header .second-header .shop-by-nav .dropdown-menu .shop-product-tooltip {
        overflow-y: scroll;
        height: 500px;
        width: fit-content;
        grid-template-columns: repeat(4, 1fr);
        right: 0%
    }

    .Main-header .second-header .dropdown-menu {
        width: unset;
    }
}

@media(min-width:1200px) and (max-width:1400px) {
    .Main-header .second-header .nav-link {
        font-size: 14px;
    }

    .Main-header .second-header .nav-link.active {
        font-size: 14px;
        font-weight: 600;
    }

    .Main-header .second-header .shop-by-nav .dropdown-menu .shop-by-tooltip {
        overflow-y: scroll;
        height: 500px;
        width: fit-content;
    }

    .Main-header .second-header .shop-by-nav .dropdown-menu .shop-product-tooltip {
        overflow-y: scroll;
        height: 500px;
        width: fit-content;
        grid-template-columns: repeat(4, 1fr);
        right: 0%
    }

    .Main-header .second-header .dropdown-menu {
        width: unset;
    }


}

@media(min-width:992px) and (max-width:1440px) {
    .navbar-expand-lg .navbar-nav {
        flex-wrap: wrap;
    }

   
}

@media(min-width:1441px) and (max-width:1600px) {
    .Main-header .second-header .shop-by-nav .dropdown-menu .shop-product-tooltip {
        grid-template-columns: repeat(6, 1fr);
        width: fit-content;
        height: 500px;
        overflow-y: scroll;
        transform: translateX(0%);
    }
}