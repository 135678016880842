.RelevantInner .articleshead h3 span:nth-of-type(1) {
    font-size: 35px;
    font-weight: 500;
    font-family: 'GothamBold';
    margin-right: 8px;
}

.RelevantInner {
    margin: 3%;
}

.RelevantInner .articlesImg1 img {
    width: 100%;
    height: 100%;
    /* margin-bottom: 18px; */
}

.RelevantInner .articlesImg1,
.RelevantInner .articlesImg2 {
    position: relative;
}

.RelevantInner .imageinfo1 h3 {
    font-size: 24px;
    font-weight: bold;
    font-family: 'GothamBold';
    text-align: center;
}

.RelevantInner .imageinfo1 {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: #ffffff61 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 0px 0px;
    opacity: 1;
    width: 100%;
    padding: 11px 15px;
}

.RelevantInner .imageinfo1 p:nth-of-type(1) {
    font-size: 13px;
    margin-bottom: 0px;
}

.RelevantInner .articleshead h3 {
    margin-bottom: 25px;
}

.RelevantInner .articlmainn .imageinfo1 p:nth-of-type(2) {
    font-size: 13px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
    margin-bottom: 0px;
    
}

.RelevantInner .imageinfo1 .minute-text-holder{
    display: flex;
    justify-content: space-between;
    align-items: center;
    }

.RelevantInner .RelevantInninfo p {
    font-size: 15px;
    margin-top: 3%;
    text-align: justify;
}

.RelevantInner .infoMain .innerHead h3 {
    font-size: 14px;
    font-weight: 600;
}

.RelevantInner .infoMain .innerHead p {
    font-size: 15px;
}

.RelevantInner .productImg img {
    width: 100%;
}

.RelevantInner .order-card {
    border: 0;
}

.RelevantInner .order-card .cardBodymain p:nth-of-type(1) {
    font-size: 12px;
    margin-bottom: 2px;
    margin-top: 5px;
    font-family: 'Gothamregular';
}

.RelevantInner .order-card .cardBodymain p:nth-of-type(2) {
    font-size: 12px;
}


.RelevantInner .order-card .cardBodymain h3 {
    font-size: 20px;
    color: #333333;
    text-transform: capitalize;
    font-family: 'Gothamregular';
}

.RelevantInner .RelevantNewsupdates {
    margin-top: 5%;
}

.RelevantInner .RelevantNewsupdates .relevantHead h3 {
    font: normal normal normal 28px/30px Gotham;
    margin-bottom: 25px;
}

.RelevantInner .swiper-wrapper {
    margin-bottom: 5%;
}

.RelevantInner .swiper-pagination-bullet-active {
    background: #25304E 0% 0% no-repeat padding-box;
    border-radius: 50px;
    width: 32px;
    height: 4px;
}

.RelevantInner .swiper-pagination-bullet {
    border-radius: 50px;
    width: 20px;
    height: 4px;
}


@media screen and (min-width: 0px) and (max-width: 768px) {
    .RelevantInner .articleshead h3 span:nth-of-type(1) {
        font-size: 17px;
    }

    .RelevantInner .swiper-wrapper {
        margin-bottom: 9%;
    }

    .RelevantInner .imageinfo1 h3 {
        font-size: 16px;
    }

    .RelevantInner .order-card .cardBodymain h3 {
        font-size: 14px;
    }

    .RelevantInner .imageinfo1 p:nth-of-type(1) {
        font-size: 12px;
    }

    .RelevantInner .order-card .cardBodymain p:nth-of-type(1) {
        font-size: 11px;
    }

    .RelevantInner .order-card .cardBodymain p:nth-of-type(2) {
        font-size: 11px;
    }

    .RelevantInner .articleshead h3 span:nth-of-type(2) {
        font-size: 17px;
    }
}