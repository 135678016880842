.automation-works .workHead h3 {
    font-size: 45px;
    margin-bottom: 30px;
}


.automation-works .pathImg img {
    width: 100%;
}


.automation-works .contactIcon {
    font-size: 45px;
}

.automation-works .PathsectionMain {
    margin-bottom: 5%;
}

.automation-works .automationtxt h3 {
    font-size: 13px;
    font-weight: 600;
    text-align: center;
}

.automation-works .automationtxt p {
    text-transform: capitalize;
    font-size: 13px;
    text-align: center;
}

.NeedHelp {
    background: #DFEAFF 0% 0% no-repeat padding-box;
}

.Needhelpheading h3 {
    text-align: center;
    padding: 3% 0%;
    font-size: 45px;
}

.Talktousbtn {
    text-align: center;
    padding-bottom: 3%;
}

.Talktousbtn button {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #234C9E;
    border-radius: 5px;
    width: auto;
    color: #000;
    padding: 5px 35px;
}

.Talktousbtn button:hover {
    background: #2E2E2E 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #fff;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .automation-works .workHead h3 {
        font-size: 20px;
        margin-bottom: 26px;
    }

    .Needhelpheading h3 {
        text-align: center;
        padding: 4% 0%;
        font-size: 18px;
    }

    .Talktousbtn button {
        padding: 3px 10px;
        font-size: 14px;
    }
}

@media screen and (min-width: 0px) and (max-width: 485px) {
    .automation-works .automationtxt p {
        font-size: 10px;
    }

    .automation-works .automationtxt h3 {
        font-size: 11px;
    }

    .PathsectionMain .pathImg {
        text-align: center;
    }

    .automation-works .pathImg img {
        width: 93%;
    }
}

@media screen and (min-width: 0px) and (max-width: 485px) {}