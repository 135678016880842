.ArticlesInpiration {
  margin-bottom: 3%;
}

.ArticlesInpiration .articleshead h3 span:nth-of-type(2) {
  font-size: 45px;
  font-family: 'GothamBold';
  margin-bottom: 5%;
}

.ArticlesInpiration .articleshead h3 span:nth-of-type(1) {
  font-size: 45px;
  letter-spacing: 0px;
  color: #222222;
  text-transform: capitalize;
}

.ArticlesInpiration .articleshead h3 {
  margin-bottom: 2%;
}

.ArticlesInpiration .articlesImg1 img {
  width: 100%;
  height: 100%;
  aspect-ratio: 4/2.03;
}

.ArticlesInpiration .articlesImg2 img {
  width: 100%;
  height: 100%;
  aspect-ratio: 4/2;
}

.ArticlesInpiration .articlesImg1{
  position: relative;
  margin-bottom: 34px;
}

.ArticlesInpiration .articlesImg2 {
  position: relative;
  margin-bottom: 0px;
  margin-top: 20px;
}

.ArticlesInpiration .imageinfo1 h3 {
  font-size: 33px;
  font-family: 'GothamBold';
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.ArticlesInpiration .imageinfo1 p {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 5;
  font-family: 'Gothamregular';
}

.ArticlesInpiration .imageinfo1 span {
  background-color: transparent !important;
}

.ArticlesInpiration .imageinfo1 {
  /* position: absolute; */
  bottom: 4px;
  left: 22px;
  margin-top: 15px;
}

.ArticlesInpiration .imageinfo2 {
  /* position: absolute; */
  bottom: 0px;
  left: 18px;
  margin-top: 15px;
}

.ArticlesInpiration .imageinfo2 h3 {
  font-size: 21px;
  font-family: 'GothamBold';
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}


.ArticlesInpiration .imageinfo2 h3 p p {
  margin-bottom: 0px;
}

.ArticlesInpiration .imageinfo2 p {
  font-size: 14px !important;
  font-family: 'Gothamregular' !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.ArticlesInpiration .imageinfo2 p p span {
  font-size: 14px !important;
  font-family: 'Gothamregular' !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.ArticlesInpiration .imageinfo2 p:nth-of-type(2) {
  font-size: 14px;
  margin-bottom: 0px;
}

.ArticlesInpiration .overlay-color {
  /* position: absolute; */
  /* background: transparent linear-gradient(0deg, #efefef 0%, #f7f9ff82 44%, #ffffff00 100%) 0% 0% no-repeat padding-box; */
  /* top: 0; */
  bottom: 0px;
  left: 0;
  right: 0;
  height: 60%;
  transform: rotate(0deg);
}

.ArticlesInpiration .articleshead p {
  font-size: 14px;
  margin-bottom: 3%;
  font-size: 16px;
  color: #000;
  text-transform: capitalize;
  font-family: 'Gothamregular';
}

.ArticlesInpiration .swiperroww {
  margin-bottom: 3%;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .ArticlesInpiration .articleshead h3 span:nth-of-type(2) {
    font: normal normal 600 23px/30px Gotham;
  }

  .ArticlesInpiration .articleshead h3 span:nth-of-type(1) {
    font-size: 23px;
  }

  .ArticlesInpiration .articleshead p {
    font-size: 15px;
  }

  .ArticlesInpiration .imageinfo2 h3 {
    font: normal normal bold 19px/23px Gotham;
  }

  .ArticlesInpiration .imageinfo2 p:nth-of-type(1) {
    font-size: 12px;
  }

  .ArticlesInpiration .imageinfo1 {
    bottom: 24px;
    left: 18px;
  }

  .ArticlesInpiration .imageinfo2 p:nth-of-type(2) {
    font-size: 11px;
  }

  .ArticlesInpiration .imageinfo2 {
    bottom: 15px;
    left: 18px;
  }

  .ArticlesInpiration .imageinfo1 h3 {
    font-family: 'GothamBold';
    font-size: 14px;
  }

  .ArticlesInpiration .imageinfo1 p {
    font-size: 11px;
    margin-bottom: 9px;
  }

  .ArticlesInpiration .imageinfo2 p p span {
    font-size: 11px !important;
  }

  .ArticlesInpiration .imageinfo2 p {
    font-size: 11px !important;
  }

  .ArticlesInpiration .articlesImg1 img {
    height: auto;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .ArticlesInpiration .articlesImg1 img {
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
  }

  .ArticlesInpiration .imageinfo2 {
    bottom: 5px;
    left: 17px;
  }

  .ArticlesInpiration .imageinfo2 h3 {
    font-family: 'GothamBold';
    font-size: 17px;


  }

  .ArticlesInpiration .imageinfo2 p p span {
    font-size: 12px !important;
  }

  .ArticlesInpiration .imageinfo2 p {
    font-size: 12px !important;
  }

  .ArticlesInpiration .imageinfo2 p {
    font-size: 13px !important;
    margin-bottom: 6px;
  }

  .ArticlesInpiration .imageinfo2 p span {
    font-size: 13px !important;
  }

  .ArticlesInpiration .imageinfo1 h3 {
    font-family: 'GothamBold';
    font-size: 16px;
  }

  .ArticlesInpiration .imageinfo1 p {
    font-size: 12px;
    margin-bottom: 9px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .ArticlesInpiration .articleshead h3 span:nth-of-type(1) {
    font-size: 30px;
  }

  .ArticlesInpiration .articleshead h3 span:nth-of-type(2) {
    font: normal normal 900 30px/50px Gotham;
    margin-bottom: 5%;
  }
}