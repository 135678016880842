.ProductCare .AboutusMain .aboutushead h3 {
    font-family: "Gothammedium";
    font-weight: 300;
    color: #1e1e1e;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: 71px;
    letter-spacing: -1.32px;
    padding-bottom: 25px;
}

.ProductCare .AboutusMain {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 0px 50px 50px;
    padding: 2%;
    margin-bottom: 6%;
}

.ProductCare .AboutusMain .aboutushead span {
    display: block;
    font-size: 33px;
    font-weight: 800;
}

.ProductCare .AboutusMain .aboutuscard {
    background: #D9E6FF 0% 0% no-repeat padding-box;
    border-radius: 11px;
    width: 100%;
    padding: 22px 0px;
}

.ProductCare .AboutusMain .aboutuscard .cardContent h3 {
    font-size: 33px;
    font-weight: bold;
}

.ProductCare .AboutusMain .aboutuscard .cardContent h4 {
    font-size: 28px;
}

.ProductCare .AboutusMain .aboutuscard .cardContent p {
    font-size: 15px;
    margin-bottom: 0;
}

.ProductCare .AboutusMain .paraInfo p {
    font-size: 20px;
    margin-bottom: 0;
    margin-top: 15px;
    text-align: center;
}

.ProductCare .AboutusMain .RightImgcare img {
    width: 100%;
    object-fit: contain;
    /* width: 569px; */
    height: 433px;
}

.ProductCare .AboutusMain .productCareinfo {
    margin-top: 5%;
    font-size: 20px;
}

.ProductCare .feelFree .feelfreehead h3 {
    margin-bottom: 3%;
}

.ProductCare .feelFree .feelfreehead h3 span {
    display: block;
    font-size: 45px;
}

.ProductCare .feelFree .feelfreehead h3 span:nth-of-type(2) {
    font-weight: 600;
}

.ProductCare .feelFree .formMain .feelfreecontactform {
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 3% 11%;
    margin-bottom: 6%;
}

.ProductCare .feelFree .formMain .react-tel-input .form-control {
    width: 100%;
}

.ProductCare .feelFree .formMain .form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    background-color: transparent;
    font-size: 14px;
}

.ProductCare .feelFree .sendRequest .sendbtn {
    background: #040404 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: 0;
    font-size: 14px;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .ProductCare .AboutusMain .aboutushead h3 {
        font-size: 25px;
    }

    .ProductCare .AboutusMain .aboutushead span {
        font-size: 20px;
    }

    .ProductCare .AboutusMain .aboutuscard {
        margin-bottom: 18px;
    }

    .ProductCare .AboutusMain .aboutuscard .cardContent h3 {
        font-size: 25px;
    }

    .ProductCare .AboutusMain .aboutuscard .cardContent h4 {
        font-size: 18px;
    }

    .ProductCare .AboutusMain .paraInfo p {
        font-size: 14px;
        margin-bottom: 12px;
    }

    .ProductCare .AboutusMain .RightImgcare img {
        width: 100%;
        height: 176px;
        margin-bottom: 16px;
    }

    .ProductCare .AboutusMain .productCareinfo {
        font-size: 15px;
    }

    .ProductCare .feelFree .feelfreehead h3 span {
        font-size: 20px;
    }

    .ProductCare .feelFree .formMain .feelfreecontactform {
        padding: 10% 11%;
        margin-bottom: 6%;
    }

}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .ProductCare .AboutusMain .aboutushead h3 {
        font-size: 35px;
    }

    .ProductCare .AboutusMain .aboutushead span {
        font-size: 20px;
    }

    .ProductCare .AboutusMain .aboutuscard {
        margin-bottom: 18px;
    }

    .ProductCare .AboutusMain .aboutuscard .cardContent h3 {
        font-size: 25px;
    }

    .ProductCare .AboutusMain .aboutuscard .cardContent h4 {
        font-size: 18px;
    }

    .ProductCare .AboutusMain .paraInfo p {
        font-size: 14px;
        margin-bottom: 12px;
    }

    .ProductCare .AboutusMain .RightImgcare img {
        width: 100%;
        height: 269px;
    }

    .ProductCare .AboutusMain .productCareinfo {
        font-size: 15px;
    }

    .ProductCare .feelFree .feelfreehead h3 span {
        font-size: 22px;
    }

    .ProductCare .feelFree .formMain .feelfreecontactform {
        padding: 10% 11%;
        margin-bottom: 6%;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
    .ProductCare .AboutusMain .aboutushead h3 {
        font-size: 50px;
    }

    .ProductCare .AboutusMain .aboutushead span {
        font-size: 20px;
    }

    .ProductCare .AboutusMain .aboutuscard {
        margin-bottom: 18px;
    }

    .ProductCare .AboutusMain .aboutuscard .cardContent h3 {
        font-size: 25px;
    }

    .ProductCare .AboutusMain .aboutuscard .cardContent h4 {
        font-size: 18px;
    }

    .ProductCare .AboutusMain .paraInfo p {
        font-size: 14px;
        margin-bottom: 12px;
    }
}