.login-container {
    background-color: #f8f9fa;
    background: url("../../../public/assest/image/login/Loginback.png");
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    min-height: 100vh;
    position: relative;
}

.login-container .backtohome {
    font-size: 14px;
    color: #fff;
    text-decoration: underline;
    position: relative;
    z-index: 9;
}

.login-container .login-box .loginbtnsec {
    text-align: center;
}

.login-container .login-box .loginbtnsec .loginbtn {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #000000;
    border-radius: 3px;
    width: auto;
    color: #000;
    font-size: 14px;
    padding: 5px 37px;
}

.login-container .login-box .loginbtnsec .loginbtn:hover {
    background: #234C9E 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: #fff;
    border: 0;
}

.login-container .logosection {
    text-align: center;
    position: relative;
}

.login-container .logosection img {
    width: auto;
    height: 91px;
    margin-top: 5%;
}

.login-container .tab {
    flex: 1;
    padding: 10px;
    border: none;
    background: none;
    font-size: 16px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
}

.login-container .tab.active {
    border-bottom: 2px solid #007bff;
}

.login-container .form-group {
    margin-bottom: 15px;
}

.login-container .password-container {
    position: relative;
}



.login-container .password-icon {
    position: absolute;
    right: 10px;
    top: 15px;
    font-size: 13px;
    transform: translateY(-50%);
    cursor: pointer;
}

.login-container .forgot-password {
    text-align: right;
    margin-bottom: 15px;
    font-size: 13px;
}

.login-container .social-login p {
    font-size: 13px;
}

.login-container .signup-link p {
    font-size: 13px;
}

.login-container .btn-block {
    width: 100%;
}

.login-container .signup-link {
    text-align: center;
    margin: 15px 0;
}

.login-container .social-login {
    text-align: center;
}

.login-container .social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.login-container .social-icons .btn {
    padding: 5px;
    border: none;
}

.login-container .social-icons img {
    width: 20px;
    height: 20px;
}

.login-container .tablogin {
    margin-top: 14px;
    padding: 5%;
}

.login-container .nav-pills {
    display: block;
}

.login-container .nav-item {
    text-align: center;
}

.login-container .nav-pills .nav-link.active {
    background: #234C9E 0% 0% no-repeat padding-box;
    border-radius: 0;
    color: #fff !important;
}

.login-container .tablogin .form-label {
    font-size: 14px;
}

.login-container .nav-pills .nav-link {
    color: #000;
}

.login-container .submitbtnsec {
    margin-bottom: 3%;
}

.login-container .submitbtnsec button {
    font-size: 14px;
    background: #234C9E 0% 0% no-repeat padding-box;
    border-radius: 3px;
    border: 0px;
    text-align: center;
    color: #fff;
    padding: 7px 22px;
    margin-bottom: 2%;
    margin-top: 5%;
}

.login-container .login-box .resetForm {
    margin-top: 12%;
}

.login-container .canceltxtsec p {
    font-size: 13px;
    width: fit-content;
    margin: 0px auto;
    cursor: pointer;
    font-weight: 500;
}

.login-container .emailtxt {
    margin-bottom: 5px;
    font-size: 13px;
}

.login-container .form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    background-color: #fff !important;
    font-size: 13px;
    width: 100%;
}

.login-container .tablogin .firstparatxt {
    font-size: 13px;
    text-align: center;
}

.login-container img.logo {
    width: auto;
    height: 91px;
    z-index: 999;
    position: relative;
    margin-top: 36px;
}

.login-container .setNewpass h3 {
    font-size: 18px;
    text-align: center;
}

.login-container .logo-register {
    text-align: center;
}

.login-container .login-box .resetForm .verify-field-holder{
    position: relative;
}

.login-container .login-box .resetForm .get-otp-btn-holder {
    position: absolute;
    top: 0;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 12px;
    padding: 0px 12px;
    width: 20%;
    background: #000;
    color: #fff;
    border: 1px solid #000;
}

.login-container .login-box .resetForm .resend-otp-btn-holder {
    font-size: 12px;
    padding: 0px 12px;
    width: 20%;
    background: red;
    color: #fff;
    border: 1px solid red;
}

.login-container .login-box .resetForm .verify-btn-holder {
    position: absolute;
    top: 0;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 12px;
    padding: 0px 12px;
    width: 20%;
    background: green;
    color: #fff;
    border: 1px solid green;
}

.login-container .login-box .resetForm .verify-field-holder  .verify-text-holder{
    width: fit-content;
    position: absolute;
    right: 0px;
    top: 0px;
    display: flex;
    justify-self: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.login-container .login-box .resetForm .verify-field-holder .form-control {
    padding-right: 50px;
}

.login-container .login-box .resetForm  .verify-text-holder p {
    font-size: 15px;
    font-weight: 600;
    color: green;
    margin-bottom: 0px;
    margin-right: 40px;
}

.login-container .login-box .resetForm .Otp-field-holder{
    display: flex;
}

.login-container .login-box .resetForm .Otp-field-holder .otp-field {
    margin: 0px 2px;
    border: 1px solid #dee2e6;
    width: 40px;
    height: 40px;
    padding: 3px;
    text-align: center;
}


/* Chrome, Safari, Edge, Opera */
.login-container .login-box .resetForm .Otp-field-holder input::-webkit-outer-spin-button,
.login-container .login-box .resetForm .Otp-field-holder input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.login-container .login-box .resetForm .Otp-field-holder input[type=number] {
  -moz-appearance: textfield;
}


.login-container .form-control.is-invalid, .login-container .was-validated .form-control:invalid {
    background-position: right calc(30px + .1875rem) center;
}