.CustomizableProduct {
    margin: 3%;
}

.CustomizableProduct .custoizablehead h3 {
    text-transform: capitalize;
    font-size: 40px;
    color: #222222;
    margin-bottom: 40px;
    font-family: 'Gothamregular';
}

.CustomizableProduct .sidebar {
    width: 100%;
    padding: 15px;
    border-right: 1px solid #ddd;
    background: #F2F2F2 0% 0% no-repeat padding-box;
}

.CustomizableProduct .quantityform {
    position: relative;
}

.CustomizableProduct .quantityform .onfieldtxt {
    position: absolute;
    top: 5px;
    margin-bottom: 0;
    right: 5px;
    font-size: 13px;
}

.CustomizableProduct .price-range {
    margin: 20px 0px 15px 0px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 13%;
}

.CustomizableProduct .price-range  .input-range {
    height: 1rem;
    position: relative;
    width: 94%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
}

.CustomizableProduct .price-range label {
    display: block;
    margin-bottom: 10px;
}

.CustomizableProduct .accordion-button,
.CustomizableProduct .accordion-item {
    background-color: transparent;
}

.CustomizableProduct .form-control {
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-radius: 0;
    background-color: transparent !important;
    margin-bottom: 15px;
    font-size: 14px;
    font-family: 'Gothamregular';
}

.CustomizableProduct .accordion-button::after {
    background-size: 15px;
}



.CustomizableProduct .price-range .d-flex {
    font-size: 12px;
}

.CustomizableProduct label {
    font-size: 12px;
    font-family: 'Gothamregular';
}

.CustomizableProduct .categoryItem {
    display: flex;
    margin-bottom: 7px;
    cursor: pointer;
}

.CustomizableProduct .categoryItemssec {
    overflow: auto;
    height: 150px;
}

.CustomizableProduct .input-range__label-container {
    color: #000;
}

.CustomizableProduct .categoryItem:hover {
    background: transparent linear-gradient(90deg, #F2F2F2 0%, #DBDBDB 100%) 0% 0% no-repeat padding-box;
}

.CustomizableProduct .lessthanicon {
    margin-left: auto;
    margin-right: 6px;
}

.CustomizableProduct .categoryItemssec .categoryItem p {
    margin-bottom: 5px;
    font-size: 14px;
    font-family: 'Gothamregular';
    font-weight: 500;
}

.CustomizableProduct .categoryItemssec .categoryItem p.active {
    font-weight: 700;
}

.CustomizableProduct .productsBycatHead {
    font-size: 15px;
    font-weight: 600;
    margin: 16px 0px;
    font-family: 'Gothammedium';
}



.CustomizableProduct .accordion-button {
    font-size: 14px;
    padding: 10px 15px;
    font-family: 'Gothammedium';
}

.CustomizableProduct .sortingsec p {
    font-size: 12px;
    margin-bottom: 0;
    cursor: pointer;
}

.CustomizableProduct .sortingsec .barsImage {
    width: 14px;
    height: 9px;
    object-fit: contain;
}

.CustomizableProduct .dropdown-toggle::after {
    display: none;
}

.CustomizableProduct .form-check-input {
    border: 1px solid #000;
    border-radius: 0 !IMPORTANT;
}

.CustomizableProduct .accordion-button:not(.collapsed) {
    color: #000;
    background-color: transparent;
}

.CustomizableProduct .tabsbsb {
    margin-top: 15px;
}

.CustomizableProduct .tabsbsb .nav-pills .nav-link {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 22px;
    padding: 5px 7px;
    font-size: 13px;
    margin-right: 7px;
    position: relative;
    width: 82px;
    margin-bottom: 5px;
}

.CustomizableProduct .tabsbsb .nav-pills .nav-link span {
    position: absolute;
    right: 5px;
    top: 4px;
}


.CustomizableProduct .formbottomline {
    border-bottom: 1px solid #ccc;
    padding-bottom: 12px;
}


.CustomizableProduct .tabsbsb .x-mark {
    background: #fff;
    padding: 3px 5px;
    border-radius: 50%;
}

/* .CustomizableProduct .input-range__label-container {
    background: #919191 0% 0% no-repeat padding-box;
    padding: 3px 25px;
    color: #fff;
} */

.CustomizableProduct .tabsbsb .nav-pills .nav-link.active {
    background: #DCE8FF 0% 0% no-repeat padding-box;
    border: 1px solid #234C9E;
    border-radius: 22px;
}

.CustomizableProduct .accordBodycontent p {
    margin-bottom: 7px;
    font-size: 13px;
    font-family: 'Gothamregular';
    cursor: pointer;
}


.CustomizableProduct .accordBodycontent p.active {
    font-weight: 700;
 }

.CustomizableProduct .dropdown-menu {
    background-color: #FFF;
    border-radius: 0%;
    padding-top: 0%;
    padding-bottom: 0%;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    position: absolute !important;
    inset: 0px auto auto 0px !important;
    transform: translate3d(0px, 20px, 0px) !important;
    right: 0 !important;
    width: fit-content !important;
}

.CustomizableProduct .dropdown-item:hover {
    background: #2E2E2E 0% 0% no-repeat padding-box;
    color: #fff !important;
    font-size: 15px;
    padding: 10px;
}

.CustomizableProduct .accordion-item {
    border: 0;
}

.CustomizableProduct .dropdown-item:active {
    background: #2E2E2E 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 15px;
    padding: 10px;
}

.CustomizableProduct .sortingsec {
    width: fit-content;
    float: right;
}

.CustomizableProduct .dropdown-item {
    color: #000;
    font-size: 15px;
    padding: 10px;
}

.CustomizableProduct .MuiStack-root {
    justify-content: center;
    display: flex;
    align-items: center;
    margin-top: 3%;
}

.CustomizableProduct .filtericonmain {
    display: none;
}


.CustomizableProduct .sidebar form .btn_holder{
    
}

.CustomizableProduct .sidebar form .btn_holder .reset_btn {
    background: gray;
    border: 1px solid #00000000;
    width: 100%;
    margin-top: 20px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .CustomizableProduct .custoizablehead h3 {
        text-transform: capitalize;
        font-size: 16px;
        margin-bottom: 25px;
    }

    .CustomizableProduct .swiper-slide {
        margin-bottom: 13%;
    }

    .CustomizableProduct .sidebar {
        display: none;
    }

    .CustomizableProduct .filtericonmain {
        display: block;
    }

    .CustomizableProduct .dropdown-menu {
        left: -109% !important;
        /* width: auto !important; */
    }

    .CustomizableProduct .quantityform .form-control {
        font-size: 11px;

    }

    .CustomizableProduct .quantityform label {
        font-size: 10px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .CustomizableProduct .custoizablehead h3 {
        text-transform: capitalize;
        font-size: 25px;
        margin-bottom: 25px;
    }

    .CustomizableProduct .tabsbsb .nav-pills .nav-link span {
        top: 4px;
    }

    .CustomizableProduct .accordion-button {
        font-size: 13px;
    }

    .CustomizableProduct .quantityform .form-control {
        font-size: 11px;

    }

    .CustomizableProduct .quantityform label {
        font-size: 10px;
    }

    .CustomizableProduct .dropdown-menu {
        left: -109% !important;
        /* width: auto !important; */
    }
}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
    .CustomizableProduct .dropdown-menu {
        left: -109% !important;
        /* width: auto !important; */
    }
}