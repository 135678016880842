.Terms_condition {
    margin: 3%;
}

.Terms_condition .termsHeading h3 {
    font-size: 50px;
    letter-spacing: 0px;
}

.Terms_condition .termsHeading p {
    font-size: 14px;
    color: #2E2E2E;
}

.Terms_condition .termsList {
    font-size: 20px;
}

.Terms_condition .termsList .termslistItem {
    list-style-type: none;
    font-size: 14px;
}

.Terms_condition .termsList .termslistItem li {
    margin-bottom: 15px;
}

.Terms_condition .termsList {
    margin-top: 3%;
}

.Terms_condition .termsList h3 {
    font-size: 20px;
    font-weight: 600;
}

.Terms_condition .buttonsection {
    margin-top: 3%;
}

.Terms_condition .declinebtn {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #000000;
    border-radius: 3px;
    color: #000;
}

.Terms_condition .acceptbtn {
    background: #234C9E 0% 0% no-repeat padding-box;
    border-radius: 3px;
}


@media screen and (min-width: 0px) and (max-width: 768px) {
    .Terms_condition .termsHeading h3 {
        font-size: 24px;
    }

    .Terms_condition .termsHeading p {
        font-size: 13px;
    }

    .Terms_condition .termsList h3 {
        font-size: 17px;
    }

    .Terms_condition .termsList .termslistItem li {
        font-size: 13px;
    }

    .Terms_condition .termslistItem p {
        font-size: 13px;
    }
}